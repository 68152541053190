//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const SearchTypes = {
    ADD_CHILDREN_TO_TAG_QUERY: 'Search/ADD_CHILDREN_TO_TAG_QUERY',
    ADD_NEXT_CHILD:            'Search/ADD_NEXT_CHILD',
    ADD_NEXT_SIBLING:          'Search/ADD_NEXT_SIBLING',
    ADD_TAG_QUERY:             'Search/ADD_TAG_QUERY',
    CHANGE_SEARCH_MODE:        'Search/CHANGE_SEARCH_MODE',
    CHANGE_TAG:                'Search/CHANGE_TAG',
    CLEAR_ITEMS:               'Search/CLEAR_ITEMS',
    CLEAR_QUERY:               'Search/CLEAR_QUERY',
    CLEAR_SEARCH_RESULTS:      'Search/CLEAR_SEARCH_RESULTS',
    DELETE_TAG:                'Search/DELETE_TAG',
    HIDE_LOADING_INDICATOR:    'Search/HIDE_LOADING_INDICATOR',
    UPDATE_PAGING:             'Search/UPDATE_PAGING',
    SEARCH:                    'Search/SEARCH',
    SEARCH_BY_TAGS:            'Search/SEARCH_BY_TAGS',
    SEARCH_BY_TAGS_FAILED:     'Search/SEARCH_BY_TAGS_FAILED',
    SEARCH_BY_TAGS_SUCCEEDED:  'Search/SEARCH_BY_TAGS_SUCCEEDED',
    SEARCH_FAILED:             'Search/SEARCH_FAILED',
    SEARCH_SUCCEEDED:          'Search/SEARCH_SUCCEEDED',
    SHOW_LOADING_INDICATOR:    'Search/SHOW_LOADING_INDICATOR',
};

const addChildrenToTagQuery = makeActionCreator(
    SearchTypes.ADD_CHILDREN_TO_TAG_QUERY,
    {
        tag:      null,
        children: null,
    },
);
const addNextChild          = makeActionCreator(SearchTypes.ADD_NEXT_CHILD);
const addNextSibling        = makeActionCreator(SearchTypes.ADD_NEXT_SIBLING);
const clearItems            = makeActionCreator(SearchTypes.CLEAR_ITEMS);
const hideLoadingIndicator  = makeActionCreator(SearchTypes.HIDE_LOADING_INDICATOR);
const showLoadingIndicator  = makeActionCreator(SearchTypes.SHOW_LOADING_INDICATOR);

const addTagQuery = makeActionCreator(
    SearchTypes.ADD_TAG_QUERY,
    {
        tag: null,
    },
);

const changeSearchMode = makeActionCreator(
    SearchTypes.CHANGE_SEARCH_MODE,
    {
        mode: [],
    },
);

const clearQuery         = makeActionCreator(SearchTypes.CLEAR_QUERY);
const clearSearchResults = makeActionCreator(SearchTypes.CLEAR_SEARCH_RESULTS);

const search = makeActionCreator(
    SearchTypes.SEARCH,
    {
        query:       null,
        indices:     null,
        otherSearch: null,
    },
);

const searchByTags = makeActionCreator(SearchTypes.SEARCH_BY_TAGS);

const changeTag = makeActionCreator(
    SearchTypes.CHANGE_TAG,
    {
        tag:          null,
        data:         null,
        index:        null,
        level:        null,
        changedValue: null,
        action:       null,
    },
);

const deleteTag = makeActionCreator(
    SearchTypes.DELETE_TAG,
    {
        tag:   null,
        data:  null,
        index: null,
        level: null,
    },
);

const searchByTagsFailed = makeActionCreator(SearchTypes.SEARCH_BY_TAGS_FAILED);

const searchByTagsSucceeded = makeActionCreator(
    SearchTypes.SEARCH_BY_TAGS_SUCCEEDED,
    {
        resultCount: 0,
        results:     {},
    },
);

const searchFailed = makeActionCreator(SearchTypes.SEARCH_FAILED);

const searchSucceeded = makeActionCreator(
    SearchTypes.SEARCH_SUCCEEDED,
    {
        items: [],
    },
);

const updatePaging = makeActionCreator(
    SearchTypes.UPDATE_PAGING,
    {},
);

export const SearchActions = {
    addChildrenToTagQuery,
    addNextChild,
    addNextSibling,
    addTagQuery,
    changeSearchMode,
    changeTag,
    clearItems,
    clearQuery,
    clearSearchResults,
    deleteTag,
    hideLoadingIndicator,
    search,
    searchByTags,
    searchByTagsFailed,
    searchByTagsSucceeded,
    searchFailed,
    searchSucceeded,
    showLoadingIndicator,
    updatePaging,
};
