//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import '@/language/Language';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React                 from 'react';
import moment                from 'moment';
import ReactDOM              from 'react-dom';
import ReactTooltip          from 'react-tooltip';
import { ConnectedRouter }   from 'connected-react-router';
import { PersistGate }       from 'redux-persist/integration/react';
import { Provider }          from 'react-redux';
import CacheInvalidator      from '@/components/stateless/atomic/CacheInvalidator';
import colors                from '@/styles/colors.module.scss';
import createStore           from '@/store';
import LoadingOverlay        from '@/components/connected/LoadingOverlay';
import MainRouter            from '@/components/stateless/composed/MainRouter';
import Mouseflow             from '@/components/stateless/atomic/Mouseflow';
import StagingLoginOverlay   from '@/components/connected/StagingLoginOverlay';
import { history }           from '@/store';
import CookieBanner          from '@/components/connected/CookieBanner';
import GoogleAnalytics       from '@/components/connected/GoogleAnalytics';
import SentryIo              from '@/components/connected/SentryIo';
import Footer                from '@/components/connected/Footer';

import * as serviceWorker from './serviceWorker';
import styles             from './styles.module.scss';

import '@/store/miscellaneous/immutability-helper-extensions';
import '@/helper/LodashExtensions';

moment().locale('en');

const { store, persistor } = createStore();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate
            persistor={persistor}
            loading={null}
        >
            <CacheInvalidator />
            {/*
            If you don't want to automatically clear the browsers cache and update the
            application you can switch <CacheInvalidator /> by <CacheInvalidatorWithPrompt />
            to ask the user if he wants to update.
            */}
            <LoadingOverlay />
            <StagingLoginOverlay />
            <CookieBanner />
            <GoogleAnalytics />
            <SentryIo />
            <ReactTooltip
                html={true}
                border={true}
                place={'top'}
                effect={'solid'}
                className={styles.tooltip}
                textColor={colors.colorWhite}
                backgroundColor={colors.colorBlackLight}
            />
            <ConnectedRouter history={history}>
                <div className={styles.appContent}>
                    <MainRouter />
                    <Footer />
                </div>
            </ConnectedRouter>
        </PersistGate>
        <Mouseflow />
    </Provider>,
    document.getElementById('root'),
);

serviceWorker.register();
