//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                           from 'react';
import { connect }                     from 'react-redux';
import StatelessProfileCompletionBatch from '@/components/stateless/composed/ProfileCompletionBatch';
import I18n                            from 'i18next';
import State                           from '@/helper/State';
import propTypes                       from '@/components/PropTypes';

const MINIMUM_PRODUCT_IMAGES = 3;

export class Component extends React.Component {
    isAddressComplete() {
        const address = this.props.companyAddress;

        if (!address) {
            return false;
        }

        return (
            address.street &&
            address.houseNumber &&
            address.postalCode &&
            address.city &&
            address.country
        );
    }

    isFactSheetComplete() {
        const factSheet      = this.props.companyFactSheet;
        const factSheetFacts = factSheet.facts;

        if (
            !factSheet ||
            !factSheetFacts
        ) {
            return false;
        }

        return (
            factSheet.revenueDistribution?.length >= 1 &&
            factSheet.exportRatio?.length >= 1 &&
            factSheet.developmentRevenue?.length >= 1 &&
            factSheet.products?.length >= 1 &&
            factSheetFacts.employees &&
            factSheetFacts.mainCustomers &&
            factSheetFacts.commodities?.length >= 1 &&
            factSheetFacts.processes?.length >= 1 &&
            factSheetFacts.productionType?.length >= 1 &&
            factSheetFacts.certificates?.length >= 1 &&
            factSheetFacts.language?.length >= 1
        );
    }

    render() {
        const completionSteps = [
            {
                title:      I18n.t('completionCompanyDescription'),
                fullFilled: this.props.companyDescription,
            },
            {
                title:      I18n.t('completionAddress'),
                fullFilled: this.isAddressComplete(),
            },
            {
                title:      I18n.t('completionCompetences'),
                fullFilled: this.props.companyCompetences?.length,
            },
            {
                title:      I18n.t('completionInterests'),
                fullFilled: this.props.companyInterests?.length,
            },
            {
                title:      I18n.t('completionCompanyLogo'),
                fullFilled: this.props.companyLogo?.path,
            },
            {
                title:      I18n.t('completionIndustries'),
                fullFilled: this.props.companyIndustries?.length,
            },
            {
                title:      I18n.t('completionCompletedFactsheet'),
                fullFilled: this.isFactSheetComplete(),
            },
            {
                title:      I18n.t('completionProductionMachines'),
                fullFilled: this.props.companyMachines?.length >= 1,
            },
            {
                title:      I18n.t('completionMinProductImages', {
                    amount: MINIMUM_PRODUCT_IMAGES,
                }),
                fullFilled: this.props.companyPortfolioProducts?.length >= MINIMUM_PRODUCT_IMAGES,
            },
        ];

        return (
            <StatelessProfileCompletionBatch completionSteps={completionSteps} />
        );
    }
}

export const ProfileCompletionBatch = Component;

Component.propTypes = {
    companyDescription:       propTypes.string,
    companyAddress:           propTypes.object,
    companyCompetences:       propTypes.array,
    companyLogo:              propTypes.string,
    companyFactSheet:         propTypes.object,
    companyMachines:          propTypes.array,
    companyPortfolioProducts: propTypes.array,
    companyInterests:         propTypes.array,
    companyIndustries:        propTypes.array,
};

Component.defaultProps = {
    companyDescription:       '',
    companyAddress:           {},
    companyCompetences:       [],
    companyLogo:              null,
    companyFactSheet:         {},
    companyMachines:          [],
    companyPortfolioProducts: [],
    companyInterests:         [],
    companyIndustries:        [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = ['completionSteps'];

const mapStateToProps = (state) => {
    const userCompany = State.getUserCompany(state);

    return {
        companyDescription:       userCompany?.description,
        companyAddress:           userCompany?.address,
        companyCompetences:       userCompany?.competences,
        companyInterests:         userCompany?.interests,
        companyLogo:              userCompany?.logo,
        companyFactSheet:         userCompany?.factSheet,
        companyMachines:          userCompany?.machines,
        companyIndustries:        userCompany?.industries,
        companyPortfolioProducts: userCompany?.portfolioProducts,
    };
};

export default connect(
    mapStateToProps,
    null,
)(Component);
