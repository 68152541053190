//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _            from 'lodash';
import ApiUrls      from '@/constants/ApiUrls';
import MemberStatus from '@/constants/MemberStatus';

class State {
    /**
     * @param state
     * @param fallback
     */
    static getCompanyId(state, fallback = null) {
        return _.get(
            state,
            [
                'company',
                'currentCompanyId',
            ],
            fallback,
        );
    }

    /**
     * @param state
     * @param fallback
     */
    static getCurrentCompanyIri(state, fallback = null) {
        return this.getCompanyValue(
            state,
            this.getCompanyId(state),
            'iri',
            fallback,
        );
    }

    static getUserCompany(state) {
        const userCompanyId = this.getUserCompanyId(state);
        const userCompany   = _.get(state, ['company', 'companies', userCompanyId]);

        return userCompany;
    }

    static isUserCompanyMember(state) {
        return (
            this.hasUserCompanyMemberStatus(state, MemberStatus.member) ||
            this.hasUserCompanyMemberStatus(state, MemberStatus.trustedMember)
        );
    }

    static hasUserCompanyMemberStatus(state, status) {
        return this.getUserCompanyMemberStatus(state) === status;
    }

    static getUserCompanyMemberStatus(state) {
        const userCompany  = this.getUserValue(state, 'userCompany');
        const memberStatus = _.get(userCompany, 'memberStatus');

        return memberStatus;
    }

    static getUserCompanyId(state) {
        return _.get(state, 'user.company', null);
    }

    static getUserIri(state) {
        return _.get(state, 'user.userId');
    }

    static mapFavoriteCompaniesToIri(favoriteCompanies) {
        return _.map(
            favoriteCompanies,
            (favoriteCompany) => _.get(favoriteCompany, 'iri', favoriteCompany),
        );
    }

    static getUserCompanyFavoriteCompany(state) {
        const userCompany       = this.getUserCompany(state);
        const favoriteCompanies = _.get(userCompany, 'favoriteCompanies', []);

        return favoriteCompanies;
    }

    static getUserFavoriteCompany(state) {
        const favoriteCompanies = _.get(state, 'user.favoriteCompanies', []);

        return favoriteCompanies;
    }

    /**
     * @param state
     * @param justIris
     */
    static getFavoriteCompanies(state, justIris = true) {
        const userCompany       = this.getUserCompany(state);
        const favoriteCompanies = (
            userCompany ?
                this.getUserCompanyFavoriteCompany(state) :
                this.getUserFavoriteCompany(state)
        );

        if (justIris) {
            return this.mapFavoriteCompaniesToIri(favoriteCompanies);
        }

        return favoriteCompanies;
    }

    /**
     * @param state
     * @param companyIri
     */
    static isCompanyFavorite(state, companyIri) {
        const favoriteCompanies = this.getFavoriteCompanies(state);

        return _.includes(favoriteCompanies, companyIri);
    }

    /**
     * @param state
     * @param fallback
     */
    static getCurrentUserIri(state, fallback = null) {
        return this.getUserValue(
            state,
            'userId',
            fallback,
        );
    }

    static isLoggedIn(state) {
        return !!this.getCurrentUserIri(state);
    }

    /**
     * @param state
     * @param fallback
     */
    static getUserCompanyIri(state, fallback = null) {
        const companyId = _.get(state, 'user.company', fallback);

        if (!companyId) {
            return null;
        }

        return `/${ApiUrls.API_COMPANY_URL}/${companyId}`;
    }

    /**
     * @param state
     * @param companyId
     * @param field
     * @param fallback
     */
    static getCompanyValue(state, companyId, field, fallback = null) {
        return _.get(
            state,
            [
                'company',
                'companies',
                companyId,
                ...field.split('.'),
            ],
            fallback,
        );
    }

    /**
     * @param state
     * @param companyId
     */
    static getCompanyVerified(state, companyId) {
        const verified    = State.getCompanyValue(state, companyId, 'verified', false);
        const certificate = State.getCompanyValue(state, companyId, 'verifiedCertificate');

        return {
            verified,
            certificate,
        };
    }

    /**
     * @param state
     * @param companyId
     * @param fallback
     */
    static getCurrentCompany(state, companyId, fallback = null) {
        return _.get(
            state,
            [
                'company',
                'companies',
                companyId,
            ],
            fallback,
        );
    }

    /**
     * @param state
     * @param field
     * @param fallback
     */
    static getUserValue(state, field, fallback = null) {
        return _.get(
            state,
            [
                'user',
                ...field.split('.'),
            ],
            fallback,
        );
    }

    /**
     * @param state
     * @param machineId
     * @param field
     * @param fallback
     */
    static getMachineById(state, machineId, field, fallback = null) {
        const machines = _.get(state, ['machine', 'machines'], []);
        const machine  = _.find(machines, (possibleMachine) => possibleMachine.id === machineId);

        return _.get(machine, field, fallback);
    }

    /**
     * @param state
     * @param companyId
     * @param fallback
     */
    static getCompanyObject(state, companyId, fallback = null) {
        return _.get(
            state,
            [
                'company',
                'companies',
                companyId,
            ],
            fallback,
        );
    }

    /**
     * @param state
     * @param companyId
     * @returns {unknown[]}
     */
    static getMachinesByCompanyId(state, companyId) {
        const machines = _.get(
            state,
            [
                'machine',
                'machines',
            ],
            [],
        );

        return _.filter(
            machines,
            {
                companies: [
                    {
                        id: companyId,
                    },
                ],
            },
        );
    }
}

export default State;
