//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import _                      from 'lodash';
import I18n                   from 'i18next';
import AlertBoxManager        from '@/components/connected/AlertBoxManager';
import ApiMode                from '@/constants/ApiMode';
import ColorBox               from '@/components/stateless/atomic/ColorBox';
import ComponentHelper        from '@/helper/ComponentHelper';
import FormRow                from '@/components/stateless/composed/FormRow';
import HeadlineMedium         from '@/components/stateless/atomic/HeadlineMedium';
import ImageUpload            from '@/components/stateless/atomic/ImageUpload';
import ImageUploadSize        from '@/components/stateless/atomic/ImageUpload/ImageUploadSize';
import Overlay                from '@/components/connected/Overlay';
import Overlays               from '@/constants/Overlays';
import OverlayType            from '@/components/connected/Overlay/OverlayType';
import PropTypes              from '@/components/PropTypes';
import TextInput              from '@/components/stateless/atomic/TextInput';
import TextInputSize          from '@/components/stateless/atomic/TextInput/TextInputSize';
import TagEditorContext       from '@/constants/TagEditorContext';
import TagSearchEditor        from '@/components/connected/TagSearchEditor';
import AlertBoxType           from '@/components/stateless/atomic/AlertBox/AlertBoxType';
import { AlertBox }           from '@/components/stateless/atomic/AlertBox';
import { Spacer }             from '@/components/stateless/atomic/Spacer';
import MachineCapacityPerYear from '@/components/stateless/composed/MachineCapacityPerYear';
import styles                 from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const props = this.props;

        return (
            <Overlay
                id={Overlays.addNewMachine}
                okButtonPressed={props.okButtonPressed}
                okButtonText={this.props.okButtonText}
                overlayType={OverlayType.full}
                closeButtonPressed={props.closeButtonPressed}
                okButtonValidator={props.okButtonValidator}
                disableCloseOnButtonPress={true}
            >
                {this.renderHeader()}
                {this.renderEditWarning()}
                {this.renderBody()}
            </Overlay>
        );
    }

    renderEditWarning = () => {
        if (
            this.props.apiMode === ApiMode.edit &&
            this.isInputDisabled()
        ) {
            return (
                <>
                    <AlertBox
                        text={I18n.t('editMachineWarning')}
                        type={AlertBoxType.warning}
                    />
                    <Spacer height={20} />
                </>
            );
        }

        return null;
    };

    isInputDisabled = () => {
        return this.props.disabled;
    };

    renderHeader = () => {
        let title = I18n.t('editMachineTitle');

        if (this.props.apiMode === ApiMode.create) {
            title = I18n.t('createMachineTitle');
        }

        return (
            <div className={styles.addNewMachineHeaderContainer}>
                <HeadlineMedium text={title} />
            </div>
        );
    };

    isVerified = () => {
        return this.props.memberVerified;
    };

    renderMemberStatusWarning = () => {
        if (!this.isVerified()) {
            return (
                <>
                    <AlertBox
                        text={I18n.t('machineCapacityWarningNotVerified')}
                        type={AlertBoxType.warning}
                    />
                    <Spacer height={20} />
                </>
            );
        }

        return null;
    };

    renderBody = () => {
        return (
            <div className={styles.addNewMachineBodyContainer}>
                <ColorBox>
                    <div className={styles.addNewMachineBodyBaseContainer}>
                        <div className={styles.addNewMachineBodyImageContainer}>
                            <FormRow label={I18n.t('image')}>
                                <ImageUpload
                                    uploadFileCallback={this.props.onImageChanged}
                                    deleteFileCallback={this.props.onImageChanged}
                                    images={[this.props.machineImage]}
                                    size={ImageUploadSize.large}
                                    disabled={this.isInputDisabled()}
                                />
                            </FormRow>
                        </div>
                        <div className={styles.addNewMachineBodyTextContainer}>
                            <FormRow label={I18n.t('title')}>
                                <TextInput
                                    value={this.props.machineName}
                                    onChange={this.props.onMachineNameChange}
                                    disabled={this.isInputDisabled()}
                                />
                            </FormRow>
                            <div className={styles.addNewMachineBodyDescriptionContainer}>
                                <FormRow label={I18n.t('description')}>
                                    <TextInput
                                        value={this.props.machineDescription}
                                        onChange={this.props.onMachineDescriptionChange}
                                        size={TextInputSize.multiline}
                                        disabled={this.isInputDisabled()}
                                    />
                                </FormRow>
                            </div>
                            <div className={styles.addNewMachineBodyDescriptionContainer}>
                                <FormRow label={I18n.t('category')}>
                                    <TextInput
                                        value={this.props.machineCategory}
                                        onChange={this.props.onMachineCategoryChange}
                                    />
                                    <p>
                                        {I18n.t('categoryMachineHelpText')}
                                    </p>
                                </FormRow>
                            </div>
                        </div>
                    </div>
                </ColorBox>
                <Spacer height={20} />
                <ColorBox>
                    {this.renderMemberStatusWarning()}
                    <FormRow label={I18n.t('machineCapacity')}>
                        <MachineCapacityPerYear
                            machineCapacities={this.props.machineCapacities}
                            onChange={this.props.onMachineCapacityChange}
                        />
                    </FormRow>
                </ColorBox>
                <div className={styles.addNewMachineBodyContainer}>
                    <TagSearchEditor
                        tagContext={TagEditorContext.newEditMachine}
                        disabled={this.isInputDisabled()}
                    />
                </div>
                <Spacer height={20} />
                <AlertBoxManager />
            </div>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const AddNewMachineOverlay = Component;

Component.propTypes = {
    addMachineParameterClicked:     PropTypes.func,
    apiMode:                        PropTypes.apiMode,
    closeButtonPressed:             PropTypes.func,
    deleteMachineParameterClicked:  PropTypes.func,
    disabled:                       PropTypes.bool,
    isLoading:                      PropTypes.bool,
    machineCapacities:              PropTypes.array,
    machineCategory:                PropTypes.string,
    machineDescription:             PropTypes.string,
    machineImage:                   PropTypes.string,
    machineName:                    PropTypes.string,
    machineParameters:              PropTypes.array,
    memberVerified:                 PropTypes.bool,
    okButtonPressed:                PropTypes.func,
    okButtonText:                   PropTypes.string,
    okButtonValidator:              PropTypes.func,
    onImageChanged:                 PropTypes.func,
    onMachineCapacityChange:        PropTypes.func,
    onMachineCategoryChange:        PropTypes.func,
    onMachineDescriptionChange:     PropTypes.func,
    onMachineNameChange:            PropTypes.func,
    onMachineParameterNameChanged:  PropTypes.func,
    onMachineParameterValueChanged: PropTypes.func,
};

Component.defaultProps = {
    addMachineParameterClicked:     _.noop,
    apiMode:                        null,
    closeButtonPressed:             _.noop,
    deleteMachineParameterClicked:  _.noop,
    disabled:                       null,
    isLoading:                      false,
    machineCapacities:              [],
    machineCategory:                null,
    machineDescription:             null,
    machineImage:                   null,
    machineName:                    null,
    machineParameters:              [],
    memberVerified:                 false,
    okButtonPressed:                _.noop,
    okButtonText:                   null,
    okButtonValidator:              _.noop,
    onImageChanged:                 _.noop,
    onMachineCapacityChange:        _.noop,
    onMachineCategoryChange:        _.noop,
    onMachineDescriptionChange:     _.noop,
    onMachineNameChange:            _.noop,
    onMachineParameterNameChanged:  _.noop,
    onMachineParameterValueChanged: _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
