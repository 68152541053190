//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { connect }            from 'react-redux';
import className              from 'classnames';
import I18n                   from 'i18next';

import AlertBoxManager       from '@/components/connected/AlertBoxManager';
import CompanyFavorite       from '@/components/stateless/composed/CompanyFavorite';
import ComponentHelper       from '@/helper/ComponentHelper';
import Ids                   from '@/constants/Ids';
import PropTypes             from '@/components/PropTypes';
import { CompanyActions }    from '@/store/actions/company';
import { MachineActions }    from '@/store/actions/machine';
import appStyles             from '@/styles.module.scss';
import { InformationBox }    from '@/components/stateless/composed/InformationBox';
import Routes                from '@/constants/Routes';
import { NavigationActions } from '@/store/actions/navigation';

import State  from '@/helper/State';
import styles from './styles.module.scss';

class Screen extends React.Component {
    renderFavorite = (favoriteCompany) => {
        const { companyVerified } = this.props;

        return (
            <CompanyFavorite
                key={favoriteCompany.id}
                company={favoriteCompany}
                companyVerified={companyVerified}
            />
        );
    };

    renderFavorites = () => {
        return _.map(
            this.props.favorites,
            this.renderFavorite,
        );
    };

    searchButtonClicked = () => {
        this.props.openUrl({
            url: Routes.home,
        });
    };

    renderInformation = () => {
        if (this.props.favorites.length === 0) {
            return (
                <InformationBox
                    headline={I18n.t('noFavorites')}
                    subline={I18n.t('noFavoritesSubline')}
                    buttonText={I18n.t('noFavoritesButtonText')}
                    onClick={this.searchButtonClicked}
                />
            );
        }

        return null;
    };

    render() {
        return (
            <div className={appStyles.defaultContainer}>
                <div
                    className={appStyles.defaultInnerContainer}
                    id={Ids.contentContainer}
                >
                    <div className={className(appStyles.defaultContentContainer, styles.contentContainer)}>
                        <AlertBoxManager />
                        <div className={styles.favorites}>
                            {this.renderFavorites()}
                            {this.renderInformation()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Screen.propTypes = {
    companyVerified: PropTypes.object,
    favorites:       PropTypes.array,
    openUrl:         PropTypes.func,
};

Screen.defaultProps = {
    companyVerified: {},
    favorites:       [],
    openUrl:         _.noop,
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...CompanyActions,
        ...MachineActions,
        ...NavigationActions,
    },
    dispatch,
);

const mapStateToProps = (state) => {
    const favorites = State.getFavoriteCompanies(state, false);
    const companyId = state.company.currentCompanyId;

    return {
        favorites,
        companyVerified: State.getCompanyVerified(state, companyId),
    };
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Screen);
