//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                      from 'lodash';
import React                  from 'react';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import AlertBoxWrapper        from '@/components/stateless/composed/AlertBoxWrapper';
import ComponentHelper        from '@/helper/ComponentHelper';
import { AlertBoxActions }    from '@/store/actions/alertBox';
import AlertBoxType           from '@/components/stateless/atomic/AlertBox/AlertBoxType';
import PropTypes              from '@/components/PropTypes';

export class Component extends React.Component {
    getAlertBoxes = () => {
        if (this.props.filterByType) {
            return _.filter(
                this.props.alertBoxes,
                {
                    type: this.props.filterByType,
                },
            );
        }

        return this.props.alertBoxes;
    };

    hideButtonPressed = (index) => {
        this.props.clearSingleAlert({
            index,
        });
    };

    render() {
        const alertBoxes = this.getAlertBoxes();

        if (alertBoxes.length === 0) {
            return null;
        }

        return (
            <AlertBoxWrapper
                alertBoxes={this.getAlertBoxes()}
                hideButtonPressed={this.hideButtonPressed}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Component.propTypes = {
    alertBoxes:       PropTypes.array,
    clearSingleAlert: PropTypes.func,
    filterByType:     PropTypes.oneOfObjectKeys(AlertBoxType),
};

Component.defaultProps = {
    alertBoxes:       [],
    clearSingleAlert: null,
    filterByType:     null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = (state) => (
    {
        alertBoxes: _.get(state, 'alertBox.alertBoxes', []),
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(
    AlertBoxActions,
    dispatch,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
