//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import I18n            from 'i18next';
import ComponentHelper from '@/helper/ComponentHelper';
import Link            from '@/components/stateless/atomic/Link';
import SocialMediaList from '@/components/stateless/atomic/SocialMediaList';
import Pages           from '@/constants/Pages';

import { VersionNumber } from '@/components/stateless/atomic/VersionNumber';
import styles            from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <footer className={styles.footerWrapper}>
                <div className={styles.footerContent}>
                    {this.renderMenu()}
                    {this.renderSocialMediaList()}
                </div>
                <div className={styles.footerVersion}>
                    <VersionNumber />
                </div>
            </footer>
        );
    }

    renderMenu = () => {
        return (
            <ul className={styles.footerMenu}>
                <li>
                    <Link
                        to={Pages.contact}
                        target={'_blank'}
                    >
                        {I18n.t('contact')}
                    </Link>
                </li>
                <li>
                    <Link
                        to={Pages.about}
                        target={'_blank'}
                    >
                        {I18n.t('about')}
                    </Link>
                </li>
                <li>
                    <Link
                        to={Pages.faq}
                        target={'_blank'}
                    >
                        {I18n.t('faq')}
                    </Link>
                </li>
                <li>
                    <Link
                        to={Pages.privacy}
                        target={'_blank'}
                    >
                        {I18n.t('dataProtection')}
                    </Link>
                </li>
                <li>
                    <Link
                        to={Pages.imprint}
                        target={'_blank'}
                    >
                        {I18n.t('imprint')}
                    </Link>
                </li>
                <li>
                    <Link
                        to={Pages.businessServices}
                        target={'_blank'}
                    >
                        {I18n.t('businessServices')}
                    </Link>
                </li>
                <li>
                    <Link
                        to={Pages.supportUs}
                        target={'_blank'}
                    >
                        {I18n.t('supportUs')}
                    </Link>
                </li>
                <li>
                    <Link
                        to={Pages.termsOfUse}
                        target={'_blank'}
                    >
                        {I18n.t('termsOfUse')}
                    </Link>
                </li>
                <li>
                    <Link
                        to={Pages.termsAndConditions}
                        target={'_blank'}
                    >
                        {I18n.t('termsAndConditions')}
                    </Link>
                </li>
            </ul>
        );
    };

    renderSocialMediaList = () => {
        return (
            <div className={styles.socialMediaList}>
                <SocialMediaList />
            </div>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const Footer = Component;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = [];

Component.renderAffectingStates = [];

export default Component;
