//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const baseUrl = 'https://welcome.supplydu.com';

export default Object.freeze({
    about:              `${baseUrl}/about`,
    businessServices:   `${baseUrl}`,
    contact:            `${baseUrl}#contact`,
    faq:                `${baseUrl}/faq`,
    imprint:            `${baseUrl}/imprint`,
    privacy:            `${baseUrl}/privacy`,
    supportUs:          `${baseUrl}/support-us`,
    termsAndConditions: `${baseUrl}/terms-and-conditions`,
    termsOfUse:         `${baseUrl}/terms-of-use`,
});
