//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import I18n            from 'i18next';
import _               from 'lodash';
import classNames      from 'classnames';
import PropTypes       from '@/components/PropTypes';
import IconType        from '@/components/stateless/atomic/Icon/IconType';
import IconButton      from '@/components/stateless/atomic/IconButton';
import IconButtonTheme from '@/components/stateless/atomic/IconButton/IconButtonTheme';
import RoundDropDown   from '@/components/stateless/atomic/RoundDropDown';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    getDropDownOptionsForTagList = (currentTag) => {
        const possibleTags              = [currentTag.tag, ...currentTag.possibleChildren];
        const alreadySelectedSiblingIds = [currentTag.tag.id, ...currentTag.alreadySelectedSiblingIds];
        const currentTagId              = currentTag.id;
        const dropDownOptions           = [];

        if (alreadySelectedSiblingIds) {
            for (const tagIndex in possibleTags) {
                const tag      = possibleTags[tagIndex];
                const tagId    = tag.id;
                const disabled = alreadySelectedSiblingIds.indexOf(tagId) >= 0 && currentTagId !== tagId;

                if (!disabled) {
                    dropDownOptions.push({
                        label: tag.title,
                        value: tagId,
                    });
                }
            }
        }

        return dropDownOptions;
    };

    getRootDropDownOptionsForTagList = (rootTags, tags) => {
        const possibleTags              = rootTags || [];
        const alreadySelectedSiblingIds = _.map(tags, 'id');
        const dropDownOptions           = [];

        if (alreadySelectedSiblingIds) {
            for (const tag of possibleTags) {
                const tagId    = tag.id;
                const disabled = alreadySelectedSiblingIds.indexOf(tagId) >= 0;

                if (!disabled) {
                    dropDownOptions.push({
                        label: tag.title,
                        value: tagId,
                        disabled,
                    });
                }
            }
        }

        return dropDownOptions;
    };

    renderSearchTip() {
        const { showReducedTags, tags } = this.props;

        if (
            tags.length === 0 ||
            !this.props.searchTipTranslationKey
        ) {
            return null;
        }

        return (
            <p
                className={classNames(
                    styles.tip,
                    {
                        [styles.tipHidden]: showReducedTags,
                    },
                )}
            >
                {I18n.t(this.props.searchTipTranslationKey)}
            </p>
        );
    }

    showPlaceholder = () => {
        if (this.props.tags.length === 0) {
            return (
                <p
                    className={styles.placeholder}
                >
                    {I18n.t(this.props.placeholder)}
                </p>
            );
        }

        return null;
    };

    render() {
        const { showReducedTags, tags, onClick, disabled } = this.props;

        return (
            <div
                onClick={onClick}
                className={classNames(
                    styles.tagSelectorWrapper,
                    this.props.className,
                    {
                        [styles.clickable]: !!onClick,
                        [styles.disabled]:  disabled,
                    },
                )}
            >
                {this.renderSearchTip()}
                {this.renderTags(tags, 0)}
                {this.showPlaceholder()}
                <div
                    className={classNames(
                        styles.tagListWrapper,
                        {
                            [styles.tagListWrapperLevel0]:  true,
                            [styles.tagListWrapperReduced]: showReducedTags,
                        },
                    )}
                >
                    {this.renderRootTagSelection(0)}
                </div>
            </div>
        );
    }

    renderStepDown = (level) => {
        if (level > 0 && !this.props.showReducedTags) {
            return (
                <div className={styles.stepDown} />
            );
        }

        return null;
    };

    addChild = (data) => (tag) => {
        this.props.focusResults();
        this.props.addTagQuery({
            tag: _.find(data.possibleChildren, {
                id: tag.value,
            }),
        });
    };

    addRootTag = (data) => (tag) => {
        this.props.focusResults();
        this.props.addTagQuery({
            tag: _.find(data, {
                id: tag.value,
            }),
        });
    };

    onTagDelete = (tag, data, index, level) => () => {
        this.props.onTagDelete({
            tag,
            data,
            index,
            level,
        });
    };

    /* eslint-disable react/display-name */
    renderTag = (level) => (data, index) => {
        const options         = this.getDropDownOptionsForTagList(data);
        const selectedOption  = options[data.selectedTagIndex];
        const disableDropdown = options.length <= 1;
        const tagBelowText    = this.getTagBelowText(data, level);

        return (
            <div
                className={classNames(
                    styles.tagWrapper,
                    {
                        [styles.tagWrapperHidden]: this.props.showReducedTags && data.children.length,
                    },
                )}
                key={`tag-selector-${index}-${level}-${data.id}`}
            >
                <div className={styles.tagContent}>
                    {this.renderStepDown(level)}
                    <div
                        className={classNames(
                            styles.dropDownWrapper,
                            {
                                [styles.dropDownWrapperNotClickable]: disableDropdown,
                            },
                        )}
                    >
                        <RoundDropDown
                            textBelow={tagBelowText}
                            options={options}
                            selectedOption={selectedOption}
                            isReduced={this.props.showReducedTags}
                            onChange={this.addChild(data)}
                            optionIsLocked={true}
                            selectionNotChangeable={disableDropdown}
                            selectIsFocused={this.props.selectIsFocused}
                            disabled={this.props.disabled}
                        />
                    </div>
                    <div className={styles.tagHoverContent}>
                        <IconButton
                            iconType={IconType.x}
                            onClick={this.onTagDelete(selectedOption, data, index, level)}
                            theme={IconButtonTheme.grayRound}
                            disabled={this.props.disabled}
                        />
                    </div>
                </div>
                {this.renderChildTags(selectedOption, data, index, level)}
            </div>
        );
    };
    /* eslint-enable react/display-name */

    renderRootTagSelection = (level) => {
        const options = this.getRootDropDownOptionsForTagList(this.props.rootTags, this.props.tags);

        if (options.length === 0) {
            return null;
        }

        return (
            <div
                className={classNames(
                    styles.tagWrapper,
                    {
                        [styles.tagWrapperHidden]: this.props.showReducedTags,
                    },
                )}
                key={`tag-selector-${level}`}
            >
                <div className={styles.tagContent}>
                    {this.renderStepDown(level)}
                    <div className={classNames(styles.dropDownWrapper)}>
                        <RoundDropDown
                            options={options}
                            isReduced={this.props.showReducedTags}
                            onChange={this.addRootTag(this.props.rootTags)}
                            optionIsLocked={true}
                            selectIsFocused={this.props.selectIsFocused}
                            initialOpened={this.props.initialOpened}
                        />
                    </div>
                </div>
            </div>
        );
    };

    renderChildTags = (selectedOption, data, index, level) => {
        return (
            <>
                {this.renderTags(data.children, level + 1)}
            </>
        );
    };

    getTagBelowText = (data, level) => {
        const { standaloneTagSearch, children } = data;

        if (
            level !== 0 ||
            standaloneTagSearch ||
            children.length
        ) {
            return null;
        }

        return I18n.t(
            'belowTagText',
            {
                tag: data.tag.title,
            },
        );
    };

    renderTags = (tags, level) => {
        const marginLeft = !this.props.showReducedTags ? level * 20 : 0;

        return (
            <div
                className={classNames(
                    styles.tagListWrapper,
                    {
                        [styles.tagListWrapperLevel0]:  level === 0,
                        [styles.tagListWrapperReduced]: this.props.showReducedTags,
                    },
                )}
                style={{
                    marginLeft,
                }}
            >
                {tags.map(this.renderTag(level))}
            </div>
        );
    };
}

export const TagSelector = Component;

Component.propTypes = {
    addTagQuery:             PropTypes.func,
    className:               PropTypes.string,
    disabled:                PropTypes.bool,
    focusResults:            PropTypes.func,
    initialOpened:           PropTypes.bool,
    index:                   PropTypes.number,
    onAddNextChild:          PropTypes.func,
    onAddSibling:            PropTypes.func,
    onClick:                 PropTypes.func,
    onTagChange:             PropTypes.func,
    onTagDelete:             PropTypes.func,
    placeholder:             PropTypes.any,
    rootTags:                PropTypes.arrayOf(PropTypes.tagTree),
    searchTipTranslationKey: PropTypes.string,
    selectIsFocused:         PropTypes.func,
    showReducedTags:         PropTypes.bool,
    tags:                    PropTypes.arrayOf(PropTypes.tagTree),
};

Component.defaultProps = {
    addTagQuery:             _.noop,
    className:               '',
    disabled:                false,
    focusResults:            _.noop,
    initialOpened:           false,
    index:                   null,
    onAddNextChild:          _.noop,
    onAddSibling:            _.noop,
    onClick:                 null,
    onTagChange:             _.noop,
    onTagDelete:             _.noop,
    placeholder:             '',
    rootTags:                [],
    searchTipTranslationKey: null,
    selectIsFocused:         _.noop,
    showReducedTags:         false,
    tags:                    [],
};

export default Component;
