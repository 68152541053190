//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                from 'react';
import PropTypes            from '@/components/PropTypes';
import { MembershipOption } from '@/components/stateless/atomic/MembershipOption';

import styles from './styles.module.scss';

export class Component extends React.Component {
    renderMembershipOption = (membershipOption) => {
        const { history } = this.props;

        return (
            <MembershipOption
                option={membershipOption}
                history={history}
            />
        );
    };

    render() {
        const { options } = this.props;

        return (
            <div className={styles.membershipOptions}>
                {options.map(this.renderMembershipOption)}
            </div>
        );
    }
}

export const MembershipOptions = Component;

Component.propTypes = {
    history: PropTypes.object,
    options: PropTypes.array,
};

Component.defaultProps = {
    history: null,
    options: [],
};

export default Component;
