//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { connect }            from 'react-redux';
import I18n                   from 'i18next';
import moment                 from 'moment';

import ComponentHelper                   from '@/helper/ComponentHelper';
import Ids                               from '@/constants/Ids';
import Navigation                        from '@/helper/Navigation';
import PropTypes                         from '@/components/PropTypes';
import Routes                            from '@/constants/Routes';
import State                             from '@/helper/State';
import StateHelper                       from '@/helper/State';
import { CompanyActions }                from '@/store/actions/company';
import { MachineActions }                from '@/store/actions/machine';
import { OverlayActions }                from '@/store/actions/ovleray';
import { MessageRequestActions }         from '@/store/actions/messageRequest';
import { NavigationActions }             from '@/store/actions/navigation';
import ProjectStatusConstant             from '@/constants/ProjectStatus';
import { ColorBox }                      from '@/components/stateless/atomic/ColorBox';
import { Flex }                          from '@/components/stateless/atomic/Flex';
import FlexDirection                     from '@/components/stateless/atomic/Flex/FlexDirection';
import InfoText                          from '@/components/stateless/atomic/InfoText';
import IconType                          from '@/components/stateless/atomic/Icon/IconType';
import ColorBoxColor                     from '@/components/stateless/atomic/ColorBox/ColorBoxColor';
import Match                             from '@/components/stateless/composed/Match';
import ProjectStatus                     from '@/components/stateless/atomic/ProjectStatus';
import ListWithHeader                    from '@/components/stateless/composed/ListWithHeader';
import { IconTextButton }                from '@/components/stateless/atomic/IconTextButton';
import Facts                             from '@/components/stateless/atomic/Facts';
import EntityLabelSize                   from '@/components/stateless/composed/EntityLabel/EntityLabelSize';
import { EntityLabel }                   from '@/components/stateless/composed/EntityLabel';
import { HeadlineSmall }                 from '@/components/stateless/atomic/HeadlineSmall';
import { CollapsableTagList }            from '@/components/stateless/composed/CollapsableTagList';
import { TagList }                       from '@/components/stateless/composed/TagList';
import JustifyContent                    from '@/components/stateless/atomic/Flex/JustifyContent';
import { ProjectsActions }               from '@/store/actions/projects';
import { HeadlineLarge }                 from '@/components/stateless/atomic/HeadlineLarge';
import { ColorButton }                   from '@/components/stateless/atomic/ColorButton';
import ColorButtonTheme                  from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import StringUtils                       from '@/helper/String';
import * as Api                          from '@/api';
import { Icon }                          from '@/components/stateless/atomic/Icon';
import AlertBoxManager                   from '@/components/connected/AlertBoxManager';
import OverlayType                       from '@/components/connected/Overlay/OverlayType';
import InterestedInProjectMessageContent from '@/components/stateless/composed/InterestedInProjectMessageContent';
import appStyles                         from '@/styles.module.scss';
import { BoldTranslation }               from '@/components/stateless/composed/BoldTranslation';
import Route                             from '@/helper/Route';
import CompanyTagFields                  from '@/constants/CompanyTagFields';
import SideMenu                          from '@/components/connected/SideMenu';
import ViewingContext                    from '@/constants/ViewingContext';
import Company                           from '@/helper/Company';

import styles from './styles.module.scss';

class Screen extends React.Component {
    constructor(props) {
        super(props);

        const { selectedProject, fetchProjectAndDependencies, projectId } = props;

        if (_.isEmpty(selectedProject)) {
            fetchProjectAndDependencies({
                projectId,
            });
        }
    }

    render() {
        return (
            <div className={appStyles.defaultContainer}>
                <div
                    className={appStyles.defaultInnerContainer}
                    id={Ids.contentContainer}
                >
                    <SideMenu
                        route={this.getSideMenuRoute()}
                    />
                    <div className={appStyles.defaultContentContainer}>
                        <div className={styles.projectsContainer}>
                            <div className={styles.projectsContent}>
                                <ColorBox color={ColorBoxColor.white}>
                                    <Flex
                                        flexDirection={FlexDirection.row}
                                        gap={30}
                                    >
                                        <Flex flexDirection={FlexDirection.column}>
                                            <AlertBoxManager />
                                            {this.renderProject()}
                                        </Flex>
                                        {this.renderRightSidebar()}
                                    </Flex>
                                </ColorBox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderProjectHeader = () => {
        const { selectedProject } = this.props;

        return (
            <div>
                <HeadlineLarge text={selectedProject.name} />
                <InfoText>
                    {selectedProject.comment}
                </InfoText>
            </div>
        );
    };

    renderProject = () => {
        return (
            <Flex
                gap={30}
                flexDirection={FlexDirection.column}
            >
                {this.renderProjectHeader()}
                {this.renderProjectMatches()}
                {this.renderProjectStatus()}
                {this.renderProjectsTags()}
            </Flex>
        );
    };

    closeProject = () => {
        const { selectedProject } = this.props;
        const message             = (
            <BoldTranslation
                translationKey={'projectsCloseProjectText'}
                values={{
                    projectTitle: selectedProject.name,
                }}
            />
        );

        this.props.openConfirmDialog({
            confirmAction:     [
                ProjectsActions.closeProject({
                    projectIri: selectedProject.iri,
                }),
            ],
            title:             I18n.t('projectsCloseProjectTitle'),
            message,
            confirmButtonText: I18n.t('closeProject'),
            cancelButtonText:  I18n.t('cancel'),
        });
    };

    renderCloseProjectButton = () => {
        const { isClosed, isOnOwnCompany } = this.props;

        if (!isOnOwnCompany) {
            return null;
        }

        if (isClosed) {
            return (
                <ColorButton
                    theme={ColorButtonTheme.orange}
                    disabled={true}
                    text={I18n.t('closed')}
                />
            );
        }

        return (
            <ColorButton
                theme={ColorButtonTheme.orange}
                onClick={this.closeProject}
                text={I18n.t('closeProject')}
            />
        );
    };

    renderRightSidebar = () => {
        return (
            <div className={styles.sidebar}>
                <Flex
                    gap={50}
                    flexDirection={FlexDirection.column}
                >
                    {this.renderFacts()}
                    {this.renderRequestingCompany()}
                    {this.renderAttachments()}
                    {this.renderCloseProjectButton()}
                </Flex>
            </div>
        );
    };

    renderFacts = () => {
        const { selectedProject } = this.props;

        if (
            !selectedProject ||
            !selectedProject.startDate ||
            !selectedProject.endDate
        ) {
            return null;
        }

        const facts = [
            {
                label: 'start',
                value: moment(selectedProject.startDate).format(I18n.t('dateFormat')),
            },
            {
                label: 'end',
                value: moment(selectedProject.endDate).format(I18n.t('dateFormat')),
            },
            {
                label: 'totalVolume',
                value: selectedProject.totalVolume,
            },
            {
                label: 'peakYearVolume',
                value: selectedProject.peakYearVolume,
            },
        ];

        if (selectedProject.countryOfDelivery) {
            facts.push({
                label: 'countryOfDelivery',
                value: selectedProject.countryOfDelivery,
            });
        }

        return (
            <Facts
                headline={I18n.t('facts')}
                facts={facts}
            />
        );
    };

    renderRequestingCompany = () => {
        const { selectedProject } = this.props;
        const { company }         = selectedProject;

        if (!company) {
            return null;
        }

        return (
            <Flex
                gap={20}
                flexDirection={FlexDirection.column}
            >
                <HeadlineSmall text={I18n.t('requestingCompany')} />
                <EntityLabel
                    className={styles.requestingCompany}
                    title={company.name}
                    avatar={Api.getImagePath(company.logo)}
                    subtitle={Company.getCompanyTypesString(company.types)}
                    size={EntityLabelSize.small}
                    iso31661Alpha2CountryCode={'de'}
                />
            </Flex>
        );
    };

    renderAttachment = (attachment) => {
        const trimmedName = StringUtils.truncate(attachment.name, 24, '...', true);

        return (
            <>
                <div
                    className={styles.attachment}
                    key={attachment.name}
                >
                    {trimmedName}
                </div>
                <a
                    href={Api.getFilePath(attachment.path)}
                    className={styles.link}
                >
                    <div
                        className={styles.iconWrapper}
                    >
                        <Icon iconType={IconType.download} />
                    </div>
                </a>
            </>
        );
    };

    renderAttachments = () => {
        const { selectedProject } = this.props;
        const { attachments }     = selectedProject;

        if (
            !attachments ||
            attachments.length === 0
        ) {
            return null;
        }

        return (
            <div>
                <HeadlineSmall text={I18n.t('attachments')} />
                <div className={styles.attachments}>
                    {attachments.map(this.renderAttachment)}
                </div>
            </div>
        );
    };

    renderProjectMatches = () => {
        const { selectedProject }   = this.props;
        const { interestedMatches } = selectedProject;

        if (
            !interestedMatches ||
            interestedMatches.length === 0 ||
            !this.props.isOnOwnCompany
        ) {
            return null;
        }

        return (
            <ListWithHeader
                headline={I18n.t('matches')}
                count={interestedMatches.length}
            >
                {interestedMatches.map(this.renderMatch)}
            </ListWithHeader>
        );
    };

    openChat = (match) => {
        return () => {
            const { openUrl } = this.props;
            const url         = Route.buildPathForCompanyMessageRequest(match.matchingCompany, match.messageRequest);

            openUrl({
                url,
            });
        };
    };

    declineMatch = (match, selectedProject) => {
        return () => {
            const message = (
                <BoldTranslation
                    translationKey={'projectsDeclineMatchText'}
                    values={{
                        projectTitle: selectedProject.name,
                        companyName:  match.matchingCompany.name,
                    }}
                />
            );

            this.props.openConfirmDialog({
                confirmAction:     [
                    ProjectsActions.rejectProjectMatch({
                        matchIri:  match.iri,
                        projectId: selectedProject.id,
                    }),
                ],
                title:             I18n.t('projectsDeclineMatchTitle'),
                message,
                confirmButtonText: I18n.t('rejectCompany'),
                cancelButtonText:  I18n.t('cancel'),
            });
        };
    };

    renderMatch = (match) => {
        const { selectedProject } = this.props;
        const { matchingCompany } = match;

        return (
            <Match
                status={match.status}
                name={matchingCompany?.name}
                image={Api.getImagePath(matchingCompany?.logo)}
                subtitle={I18n.t('companyTypeServiceProvider')}
                onChatClicked={this.openChat(match, selectedProject)}
                onDeclineProjectClicked={this.declineMatch(match, selectedProject)}
            />
        );
    };

    onMessageChanged = (message) => {
        this.props.changeInterestedMessage({
            message,
        });
    };

    onAcceptProject = () => {
        const { selectedProject, interestedMessage } = this.props;
        const message                                = (
            <InterestedInProjectMessageContent
                company={selectedProject.company}
                project={selectedProject}
                onMessageChanged={this.onMessageChanged}
                message={interestedMessage}
            />
        );

        this.props.openConfirmDialog({
            confirmAction:     [
                ProjectsActions.acceptProject({
                    project: selectedProject,
                }),
            ],
            title:             I18n.t('projectsAcceptProjectTitle'),
            message,
            overlayType:       OverlayType.widePrompt,
            confirmButtonText: I18n.t('send'),
            cancelButtonText:  I18n.t('cancel'),
        });
    };

    onDeclineProject = () => {
        const { selectedProject } = this.props;
        const message             = (
            <BoldTranslation
                translationKey={'projectsDeclineProjectText'}
                values={{
                    projectTitle: selectedProject.name,
                }}
            />
        );

        this.props.openConfirmDialog({
            confirmAction:     [
                ProjectsActions.declineProject({
                    projectId: selectedProject.id,
                }),
            ],
            title:             I18n.t('projectsDeclineProjectTitle'),
            message,
            confirmButtonText: I18n.t('notInterested'),
            cancelButtonText:  I18n.t('cancel'),
        });
    };

    renderProjectStatus = () => {
        const { selectedProject, isOnOwnCompany, isCurrentCompanyRejected } = this.props;

        if (
            isOnOwnCompany ||
            !selectedProject ||
            !selectedProject.status ||
            isCurrentCompanyRejected
        ) {
            return null;
        }

        return (
            <ColorBox>
                <Flex
                    flexDirection={FlexDirection.row}
                    justifyContent={JustifyContent.spaceBetween}
                >
                    <Flex
                        flexDirection={FlexDirection.column}
                        flex={0}
                    >
                        <Flex>
                            <ProjectStatus status={selectedProject.status} />
                        </Flex>
                        <Flex
                        >
                            <InfoText>Are you interested in this Project?</InfoText>
                        </Flex>
                    </Flex>
                    <Flex
                        flexDirection={FlexDirection.row}
                        gap={10}
                        flex={0}
                    >
                        {this.renderProjectStatusButtons()}
                    </Flex>
                </Flex>
            </ColorBox>
        );
    };

    renderProjectStatusButtons = () => {
        const { isCurrentCompanyInterested, isCurrentCompanyRejected } = this.props;
        let firstButton                                                = (
            <IconTextButton
                iconType={IconType.check}
                text={I18n.t('accept')}
                onClick={this.onAcceptProject}
            />
        );

        if (isCurrentCompanyInterested) {
            firstButton = (
                <IconTextButton
                    iconType={IconType.check}
                    text={I18n.t('interested')}
                    disabled={true}
                    onClick={_.noop}
                />
            );
        }

        if (isCurrentCompanyRejected) {
            return null;
        }

        return (
            <>
                {firstButton}
                <IconTextButton
                    iconType={IconType.cross}
                    text={I18n.t('decline')}
                    onClick={this.onDeclineProject}
                />
            </>
        );
    };

    renderCollapsableTagList = (titleKey, tags) => {
        if (!tags || tags.length === 0) {
            return null;
        }

        return (
            <CollapsableTagList title={I18n.t(titleKey)}>
                <TagList
                    tags={tags}
                />
            </CollapsableTagList>
        );
    };

    renderProjectsTags = () => {
        return (
            <Flex
                gap={30}
                flexDirection={FlexDirection.column}
            >
                {this.renderCollapsableTagList('technologies', this.props.technologyTags)}
                {this.renderCollapsableTagList('industries', this.props.industries)}
                {this.renderCollapsableTagList('materials', this.props.materialTags)}
                {this.renderCollapsableTagList('inspection', this.props.inspectionTags)}
                {this.renderCollapsableTagList('mandatory', this.props.mandatoryTags)}
                {this.renderCollapsableTagList('partFamily', this.props.partFamilyTags)}
            </Flex>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }

    getSideMenuRoute = () => {
        switch (this.props.viewingContext) {
            case ViewingContext.OWN_PROFILE:
                return Routes.myProfileProjectsProjectDetail;
            case ViewingContext.OWN_COMPANY:
                return Routes.projectDetail;
        }

        return null;
    };
}

Screen.propTypes = {
    acceptProject:               PropTypes.func,
    changeInterestedMessage:     PropTypes.func,
    closeProject:                PropTypes.func,
    company:                     PropTypes.object,
    companyAddress:              PropTypes.object,
    companyIri:                  PropTypes.string,
    companyName:                 PropTypes.string,
    companyTypes:                PropTypes.array,
    currentCompanyId:            PropTypes.string,
    currentCompanyUrl:           PropTypes.string,
    declineProject:              PropTypes.func,
    fetchCompany:                PropTypes.func,
    fetchProject:                PropTypes.func,
    fetchProjectAndDependencies: PropTypes.func,
    fetchMessageRequests:        PropTypes.func,
    industries:                  PropTypes.array,
    inspectionTags:              PropTypes.array,
    interestedMessage:           PropTypes.string,
    isClosed:                    PropTypes.bool,
    isCurrentCompanyInterested:  PropTypes.bool,
    isCurrentCompanyRejected:    PropTypes.bool,
    isOnOwnCompany:              PropTypes.bool,
    mandatoryTags:               PropTypes.array,
    materialTags:                PropTypes.array,
    messageRequests:             PropTypes.array,
    openConfirmDialog:           PropTypes.func,
    openEditOverlay:             PropTypes.func,
    openUrl:                     PropTypes.func,
    partFamilyTags:              PropTypes.array,
    projectId:                   PropTypes.string,
    requestingCompanyIri:        PropTypes.string,
    selectedProject:             PropTypes.object,
    setCurrentCompany:           PropTypes.func,
    setMessageRequestState:      PropTypes.func,
    competences:                 PropTypes.array,
    technologyTags:              PropTypes.array,
    viewingContext:              PropTypes.oneOfObjectValues(ViewingContext),
};

Screen.defaultProps = {
    acceptProject:               _.noop,
    changeInterestedMessage:     _.noop,
    closeProject:                _.noop,
    company:                     null,
    companyAddress:              {},
    companyIri:                  null,
    companyName:                 null,
    companyTypes:                [],
    currentCompanyId:            null,
    currentCompanyUrl:           null,
    declineProject:              _.noop,
    fetchCompany:                _.noop,
    fetchProject:                _.noop,
    fetchProjectAndDependencies: _.noop,
    fetchMessageRequests:        _.noop,
    industries:                  [],
    inspectionTags:              [],
    interestedMessage:           null,
    isClosed:                    false,
    isCurrentCompanyInterested:  false,
    isCurrentCompanyRejected:    false,
    isOnOwnCompany:              false,
    mandatoryTags:               [],
    materialTags:                [],
    messageRequests:             [],
    openConfirmDialog:           _.noop,
    openEditOverlay:             _.noop,
    openUrl:                     _.noop,
    partFamilyTags:              [],
    projectId:                   null,
    requestingCompanyIri:        null,
    selectedProject:             null,
    setCurrentCompany:           _.noop,
    setMessageRequestState:      _.noop,
    competences:                 [],
    technologyTags:              [],
    viewingContext:              ViewingContext.UNDEFINED,
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...CompanyActions,
        ...ProjectsActions,
        ...MachineActions,
        ...OverlayActions,
        ...MessageRequestActions,
        ...NavigationActions,
    },
    dispatch,
);

const simplifyTagList = (complexTagList) => {
    return _.map(complexTagList, (tag) => {
        const simpleHierarchy = _.map(tag.hierarchy, (hierarchy) => {
            return hierarchy.title;
        });

        return simpleHierarchy;
    });
};

const mapStateToProps = (state, ownProps) => {
    const companyId                  = Navigation.getCompanyIdFromUrl(ownProps);
    const projectId                  = Navigation.getProjectIdFromUrl(ownProps);
    const currentCompanyId           = StateHelper.getCompanyId(state);
    const selectedProject            = _.get(state, 'projects.selectedProject', null);
    const interestedMessage          = _.get(state, 'projects.interestedMessage', '');
    const requestingCompanyId        = _.get(selectedProject, 'company.id', null);
    const company                    = StateHelper.getCompanyObject(state, companyId);
    const isOnOwnCompany             = _.eq(requestingCompanyId, currentCompanyId);
    const matches                    = _.get(selectedProject, 'matches', []);
    const interestedMatches          = _.get(selectedProject, 'interestedMatches', []);
    const isCurrentCompanyInterested = _.find(interestedMatches, (match) => {
        return _.eq(match.matchingCompany.id, currentCompanyId);
    });
    const isCurrentCompanyRejected   = _.find(matches, (match) => {
        return (
            _.eq(match.matchingCompany.id, currentCompanyId) &&
            _.eq(match.status, ProjectStatusConstant.REJECTED)
        );
    });
    const companyTypes               = State.getCompanyValue(state, companyId, 'types', []);
    const ownProfileId               = _.get(state, 'user.profile');
    const isOnOwnProfile             = Navigation.isOwnProfileVisible(ownProps, ownProfileId);
    const viewingContext             = Navigation.getViewingContext(ownProps, state);

    return {
        companyAddress:    State.getCompanyValue(state, companyId, 'address'),
        companyName:       State.getCompanyValue(state, companyId, 'name'),
        messageRequests:   _.get(state, 'messageRequest.messageRequests', []),
        companyTypes,
        competences:       State.getCompanyValue(state, companyId, 'competencesHierarchy', []),
        currentCompanyId:  companyId,
        currentCompanyUrl: companyId,
        technologyTags:    simplifyTagList(selectedProject[CompanyTagFields.TECHNOLOGY]),
        industries:        simplifyTagList(selectedProject[CompanyTagFields.BRANCHES]),
        materialTags:      simplifyTagList(selectedProject[CompanyTagFields.MATERIAL]),
        inspectionTags:    simplifyTagList(selectedProject[CompanyTagFields.INSPECTION]),
        mandatoryTags:     simplifyTagList(selectedProject[CompanyTagFields.MANDATORY]),
        partFamilyTags:    simplifyTagList(selectedProject[CompanyTagFields.PART_FAMILY]),
        interestedMessage,
        company,
        isOnOwnCompany,
        isOnOwnProfile,
        viewingContext,
        projectId,
        selectedProject,
        isClosed:          _.get(selectedProject, 'status', null) === ProjectStatusConstant.CLOSED,
        isCurrentCompanyInterested,
        isCurrentCompanyRejected,
    };
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Screen);
