//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                from 'react';
import BingMapsReact        from 'bingmaps-react';
import Keys                 from '@/constants/Keys';
import PropTypes            from '@/components/PropTypes';
import CookieBannerRequired from '@/components/connected/CookieBannerRequired';

export class Component extends React.Component {
    render() {
        const { geoLocation } = this.props;

        if (geoLocation) {
            const { cookiesAccepted } = this.props;

            if (cookiesAccepted) {
                const { pinName }             = this.props;
                const { latitude, longitude } = geoLocation;
                const pins                    = [];
                const center                  = {
                    latitude,
                    longitude,
                };

                if (pinName) {
                    pins.push({
                        center,
                        options: {
                            title: pinName,
                        },
                    });
                }

                return (
                    <BingMapsReact
                        bingMapsKey={Keys.BING_MAPS_API_KEY}
                        mapOptions={{
                            navigationBarMode: 'minified',
                            showZoomButtons:   false,
                            showDashboard:     false,
                        }}
                        viewOptions={{
                            center,
                        }}
                        pushPins={pins}
                    />
                );
            }

            return (
                <CookieBannerRequired />
            );
        }

        return null;
    }
}

export const Map = Component;

Component.propTypes = {
    cookiesAccepted: PropTypes.bool,
    geoLocation:     PropTypes.geoLocation,
    pinName:         PropTypes.string,
};

Component.defaultProps = {
    cookiesAccepted: null,
    geoLocation:     {
        latitude:  0,
        longitude: 0,
    },
    pinName:         null,
};

export default Component;
