//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                     from 'react';
import _                         from 'lodash';
import I18n                      from 'i18next';
import * as Api                  from '@/api';
import ComponentHelper           from '@/helper/ComponentHelper';
import EntityLabelSize           from '@/components/stateless/composed/EntityLabel/EntityLabelSize';
import FavoriseCompanyButton     from '@/components/connected/FavoriseCompanyButton';
import Filter                    from '@/constants/Filter';
import PropTypes                 from '@/components/PropTypes';
import Route                     from '@/helper/Route';
import RouteHelper               from '@/helper/Route';
import StarButton                from '@/components/stateless/composed/StarButton';
import TableWithFilter           from '@/components/connected/TableWithFilter';
import { AddressLabel }          from '@/components/stateless/composed/AddressLabel';
import { EntityLabel }           from '@/components/stateless/composed/EntityLabel';
import { TableBodyColumn }       from '@/components/stateless/atomic/TableBodyColumn';
import { TableBodyRow }          from '@/components/stateless/atomic/TableBodyRow';
import { TableHeaderColumn }     from '@/components/stateless/atomic/TableHeaderColumn';
import { TableHeaderRow }        from '@/components/stateless/atomic/TableHeaderRow';
import Company                   from '@/helper/Company';
import { MachineCapacityStatus } from '@/components/stateless/composed/MachineCapacityStatus';
import MachineCapacity           from '@/constants/MachineCapacity';
import MemberStatus              from '@/constants/MemberStatus';

export class Component extends React.Component {
    onResultClicked = (url) => () => {
        // TODO: https://lulububu.atlassian.net/browse/SUPPLYDU-250
        let pathname     = _.get(url, 'route', '');
        const parameters = _.get(url, 'parameters', {});
        pathname         = RouteHelper.replaceParametersInUrl(pathname, parameters);

        this.props.history.push(pathname);
    };

    getCapacityByCompanyId = (companyId) => {
        const currentYear     = new Date().getFullYear();
        const machineCapacity = _.find(
            this.props.capacities,
            (capacity) => {
                return (
                    _.get(capacity, 'company.id') === companyId &&
                    _.get(capacity, 'year') === currentYear
                );
            },
        );

        return _.get(machineCapacity, 'capacity', MachineCapacity.inUse);
    };

    renderMachineCapacity = (company) => {
        const companyId    = _.get(company, 'id', '');
        const memberStatus = _.get(company, 'memberStatus', MemberStatus.basic);
        const capacity     = this.getCapacityByCompanyId(companyId);
        const isSupplier   = Company.isSupplier(company);

        if (
            memberStatus === MemberStatus.trustedMember &&
            company.verified &&
            isSupplier
        ) {
            return (
                <MachineCapacityStatus
                    capacity={capacity}
                />
            );
        }

        return null;
    };

    renderCompanyBodyRow = (company, index) => {
        if (!company) {
            return null;
        }

        const companyId    = _.get(company, 'id', '');
        const title        = _.get(company, 'name');
        const types        = _.get(company, 'types');
        const address      = _.get(company, 'address');
        const verified     = {
            verified:    _.get(company, 'verified', false),
            certificate: _.get(company, 'verifiedCertificate'),
        };
        const city         = _.get(address, 'city');
        const country      = _.get(address, 'country');
        const countryCode  = _.get(address, 'countryCode');
        const subtitle     = Company.getCompanyTypesString(types);
        const companyRoute = Route.buildRouteForCompany(company);
        let imagePath      = _.get(company, ['logo', 'path']);
        imagePath          = Api.getImagePath(imagePath);

        return (
            <TableBodyRow
                key={`machine-company-list-row-${index}`}
                onClick={this.onResultClicked(companyRoute)}
            >
                <TableBodyColumn>
                    <EntityLabel
                        title={title}
                        subtitle={subtitle}
                        verified={verified}
                        avatar={imagePath}
                        size={EntityLabelSize.small}
                        to={companyRoute}
                    />
                </TableBodyColumn>
                <TableBodyColumn>
                    {this.renderMachineCapacity(company)}
                </TableBodyColumn>
                <TableBodyColumn>
                    <AddressLabel
                        city={city}
                        country={country}
                        iso31661Alpha2CountryCode={countryCode}
                    />
                </TableBodyColumn>
                <TableBodyColumn>
                    <FavoriseCompanyButton
                        noHover={true}
                        company={companyId}
                    />
                </TableBodyColumn>
            </TableBodyRow>
        );
    };

    renderCompanyBodyRows = () => {
        return _.map(
            _.get(this.props, 'companies', []),
            this.renderCompanyBodyRow,
        );
    };

    renderHeaderRows = () => {
        return (
            <TableHeaderRow>
                <TableHeaderColumn
                    offset={55}
                >
                    {I18n.t('company')}
                </TableHeaderColumn>
                <TableHeaderColumn
                    offset={35}
                >
                    {I18n.t('capacities')}
                </TableHeaderColumn>
                <TableHeaderColumn
                    offset={43}
                >
                    {I18n.t('region')}
                </TableHeaderColumn>
                <TableHeaderColumn>
                    <StarButton
                        gray={true}
                        noHover={true}
                    />
                </TableHeaderColumn>
            </TableHeaderRow>
        );
    };

    render() {
        return (
            <TableWithFilter
                columnCount={3}
                context={Filter.companyListTable}
                renderTableHeaderRows={this.renderHeaderRows()}
                renderTableBodyRows={this.renderCompanyBodyRows()}
            />
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const CompanyListTable = Component;

Component.propTypes = {
    capacities: PropTypes.array,
    companies:  PropTypes.array,
    history:    PropTypes.object,
};

Component.defaultProps = {
    capacities: [],
    companies:  [],
    history:    null,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
