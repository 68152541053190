//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React      from 'react';
import I18n       from 'i18next';
import classNames from 'classnames';
import _          from 'lodash';

import { CountryDropdown }  from 'react-country-region-selector';
import IconType             from '@/components/stateless/atomic/Icon/IconType';
import ComponentHelper      from '@/helper/ComponentHelper';
import PropTypes            from '@/components/PropTypes';
import ColorBoxColor        from '@/components/stateless/atomic/ColorBox/ColorBoxColor';
import ColorBox             from '@/components/stateless/atomic/ColorBox';
import TextInput            from '@/components/stateless/atomic/TextInput';
import HeadlineMedium       from '@/components/stateless/atomic/HeadlineMedium';
import HeadlineSmall        from '@/components/stateless/atomic/HeadlineSmall';
import Spacer               from '@/components/stateless/atomic/Spacer';
import TextInputSize        from '@/components/stateless/atomic/TextInput/TextInputSize';
import IconButton           from '@/components/stateless/atomic/IconButton';
import { LoadingIndicator } from '@/components/stateless/atomic/LoadingIndicator';
import Icon                 from '@/components/stateless/atomic/Icon';
import TagSearchResult      from '@/components/stateless/composed/TagSearchResult';
import Overlays             from '@/constants/Overlays';
import OverlayType          from '@/components/connected/Overlay/OverlayType';
import DateInput            from '@/components/stateless/atomic/DateInput';
import AttachmentUpload     from '@/components/stateless/composed/AttachmentUpload';
import CheckBox             from '@/components/stateless/atomic/CheckBox';
import Overlay              from '@/components/connected/Overlay';
import Flex                 from '@/components/stateless/atomic/Flex';
import FlexDirection        from '@/components/stateless/atomic/Flex/FlexDirection';
import AlignItems           from '@/components/stateless/atomic/Flex/AlignItems';
import { ValidationError }  from '@/components/stateless/atomic/ValidationError';
import { TagSelector }      from '@/components/stateless/composed/TagSelector';
import TagTypeDropDown      from '@/constants/TagTypeDropDown';

import styles from './styles.module.scss';

const TagSelectorType = {
    industry:     'industry',
    competences:  'competences',
    material:     'material',
    partFamily:   'partFamily',
    process:      'process',
    technologies: 'technologies',
    mandatory:    'mandatory',
    inspection:   'inspection',
};

const TagSelectorToTagTypeMap = {
    [TagSelectorType.industry]:     [TagTypeDropDown.industry],
    [TagSelectorType.competences]:  [TagTypeDropDown.process],
    [TagSelectorType.material]:     [TagTypeDropDown.material],
    [TagSelectorType.partFamily]:   [TagTypeDropDown.partFamily],
    [TagSelectorType.process]:      [TagTypeDropDown.process],
    [TagSelectorType.technologies]: [TagTypeDropDown.process],
    [TagSelectorType.inspection]:   [TagTypeDropDown.process],
    [TagSelectorType.mandatory]:    [
        TagTypeDropDown.process,
        TagTypeDropDown.industry,
        TagTypeDropDown.certificates,
        TagTypeDropDown.material,
        TagTypeDropDown.partFamily,
    ],
};

export class CreateProjectOverlay extends React.Component {
    static propTypes = {
        addIndustryTag:              PropTypes.func,
        addInspectionTag:            PropTypes.func,
        addMandatoryTag:             PropTypes.func,
        addMaterialTag:              PropTypes.func,
        addPartFamilyTag:            PropTypes.func,
        addTechnologyTag:            PropTypes.func,
        data:                        PropTypes.object,
        isLoading:                   PropTypes.bool,
        okButtonPressed:             PropTypes.func,
        onCompanyInterestTagsAdd:    PropTypes.func,
        onCompanyInterestTagsDelete: PropTypes.func,
        onFileDeleted:               PropTypes.func,
        onFilesChanged:              PropTypes.func,
        onFormDataChanged:           PropTypes.func,
        onSaveButtonPressed:         PropTypes.func,
        onSaveButtonValidator:       PropTypes.func,
        onTagSearch:                 PropTypes.func,
        removeIndustryTag:           PropTypes.func,
        removeInspectionTag:         PropTypes.func,
        removeMandatoryTag:          PropTypes.func,
        removeMaterialTag:           PropTypes.func,
        removePartFamilyTag:         PropTypes.func,
        removeTechnologyTag:         PropTypes.func,
        resetTagQuery:               PropTypes.func,
        interestsResultList:         PropTypes.array,
        tagSearchQuery:              PropTypes.string,
        tags:                        PropTypes.object,
        validation:                  PropTypes.object,
    };

    static defaultProps = {
        addIndustryTag:              _.noop,
        addInspectionTag:            _.noop,
        addMandatoryTag:             _.noop,
        addMaterialTag:              _.noop,
        addPartFamilyTag:            _.noop,
        addTechnologyTag:            _.noop,
        data:                        {},
        isLoading:                   false,
        okButtonPressed:             _.noop,
        onCompanyInterestTagsAdd:    _.noop,
        onCompanyInterestTagsDelete: _.noop,
        onFileDeleted:               _.noop,
        onFilesChanged:              _.noop,
        onFormDataChanged:           _.noop,
        onSaveButtonPressed:         _.noop,
        onSaveButtonValidator:       _.noop,
        onTagSearch:                 _.noop,
        removeIndustryTag:           _.noop,
        removeInspectionTag:         _.noop,
        removeMandatoryTag:          _.noop,
        removeMaterialTag:           _.noop,
        removePartFamilyTag:         _.noop,
        removeTechnologyTag:         _.noop,
        resetTagQuery:               _.noop,
        interestsResultList:         [],
        tagSearchQuery:              null,
        tags:                        {},
        validation:                  {},
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [
        'addTagsTo',
    ];

    constructor(props) {
        super(props);

        this.state = {
            addTagsTo: null,
        };
    }

    renderTooltip = () => {
        const toolTip = [];

        toolTip.push(`<p>${I18n.t('cacheInvalidation')}</p>`);
        toolTip.push(`<span>${I18n.t('cacheInvalidationText')}</span>`);

        return toolTip.join(' ');
    };

    onTagSearch = (tagSelectorType) => (event) => {
        this.props.onTagSearch({
            tagType: TagSelectorToTagTypeMap[tagSelectorType],
            title:   event.target.value,
        });
    };

    renderTagSearchBox = (tagSelectorType) => {
        if (this.state.addTagsTo === tagSelectorType) {
            const { tags }       = this.props;
            const { results }    = tags;
            const addTagCallback = this.addTag(tagSelectorType);

            return (
                <>
                    <div
                        className={classNames(
                            styles.editCompanyTagInputWrapper,
                            {
                                [styles.editCompanyTagInputWrapperWithResults]: results?.length,
                            },
                        )}
                    >
                        <TextInput
                            onChange={this.onTagSearch(tagSelectorType)}
                            value={this.props.tagSearchQuery}
                        />
                        <div className={styles.editCompanyTagInputIcon}>
                            {this.renderSearchIcon()}
                        </div>
                        <div className={styles.editCompanyTagAbortSearch}>
                            <IconButton
                                iconType={IconType.cross}
                                onClick={this.resetTagSearch}
                            />
                        </div>
                    </div>
                    {this.renderTagResults(addTagCallback, results)}
                </>
            );
        }

        return null;
    };

    addTag = (tagSelectorType) => (tag) => {
        const newTag = tag.tag ? tag.tag : tag;

        switch (tagSelectorType) {
            case TagSelectorType.technologies:
                this.props.addTechnologyTag({
                    tag: newTag,
                });

                break;
            case TagSelectorType.material:
                this.props.addMaterialTag({
                    tag: newTag,
                });

                break;
            case TagSelectorType.industry:
                this.props.addIndustryTag({
                    tag: newTag,
                });

                break;
            case TagSelectorType.partFamily:
                this.props.addPartFamilyTag({
                    tag: newTag,
                });

                break;
            case TagSelectorType.mandatory:
                this.props.addMandatoryTag({
                    tag: newTag,
                });

                break;
            case TagSelectorType.inspection:
                this.props.addInspectionTag({
                    tag: newTag,
                });

                break;
            default:
                break;
        }

        this.setState({
            addTagsTo: null,
        });
    };

    removeTag = (tagSelectorType) => (tag) => {
        const tagToRemove = tag.data ? tag.data : tag;

        switch (tagSelectorType) {
            case TagSelectorType.technologies:
                this.props.removeTechnologyTag({
                    tag: tagToRemove,
                });

                break;
            case TagSelectorType.material:
                this.props.removeMaterialTag({
                    tag: tagToRemove,
                });

                break;
            case TagSelectorType.industry:
                this.props.removeIndustryTag({
                    tag: tagToRemove,
                });

                break;
            case TagSelectorType.partFamily:
                this.props.removePartFamilyTag({
                    tag: tagToRemove,
                });

                break;
            case TagSelectorType.mandatory:
                this.props.removeMandatoryTag({
                    tag: tagToRemove,
                });

                break;
            case TagSelectorType.inspection:
                this.props.removeInspectionTag({
                    tag: tagToRemove,
                });

                break;
            default:
                break;
        }
        this.setState({
            addTagsTo: null,
        });
    };

    renderTagBox = (tagSelectorType, tags, header) => {
        return (
            <>
                <HeadlineSmall
                    text={header}
                    iconType={IconType.addCircle}
                    onClick={() => this.switchTagToAdd(tagSelectorType)}
                >
                    <span
                        data-tip={this.renderTooltip()}
                        className={styles.editCompanyDropDownInfoIcon}
                    />
                </HeadlineSmall>
                <div className={styles.editCompanyTagInputContainer}>
                    <ColorBox color={ColorBoxColor.whiteGrayBorder}>
                        {this.renderTagSearchBox(tagSelectorType)}
                        <TagSelector
                            tags={tags}
                            onTagDelete={this.removeTag(tagSelectorType)}
                            addTagQuery={this.addTag(tagSelectorType)}
                        />
                    </ColorBox>
                </div>
            </>
        );
    };

    renderTagResult = (tagResult, index, onTagSearchResultClicked) => {
        const clicked = () => {
            onTagSearchResultClicked(tagResult);
        };

        return (
            <TagSearchResult
                key={`tag-search-result-${index}-${tagResult.id}`}
                tagChain={tagResult.parentTagsAsString}
                highlightText={this.props.tagSearchQuery}
                onClick={clicked}
            />
        );
    };

    renderTagResults = (onTagSearchResultClicked, tagResultList) => {
        return (
            <div className={styles.editCompanyTagResultWrapper}>
                {tagResultList.map((tagResult, index) => {
                    return this.renderTagResult(tagResult, index, onTagSearchResultClicked);
                })}
            </div>
        );
    };

    renderSearchIcon = () => {
        if (this.props.isLoading) {
            return (
                <LoadingIndicator />
            );
        }

        return (
            <Icon iconType={IconType.search} />
        );
    };

    resetTagSearch = () => {
        this.switchTagToAdd(null);
    };

    switchTagToAdd = (addTagsTo) => {
        this.props.resetTagQuery();
        this.setState({
            addTagsTo,
        });
    };

    onFormFieldChanged = (field) => {
        return (value) => {
            let fieldValue = value;

            if (fieldValue.target) {
                fieldValue = fieldValue.target.value;
            }

            this.props.onFormDataChanged({
                field,
                value: fieldValue,
            });
        };
    };

    getFormFieldData = (field) => {
        return _.get(this, ['props', 'data', field], null);
    };

    renderBasicInfos = () => {
        return (
            <ColorBox
                color={ColorBoxColor.grayLight}
                className={styles.basicProjectInfosBox}
            >
                <HeadlineSmall
                    text={I18n.t('title')}

                />
                <TextInput
                    onChange={this.onFormFieldChanged('name')}
                    value={this.getFormFieldData('name')}
                />
                <ValidationError
                    validation={this.props.validation}
                    field={'name'}
                />
                <HeadlineSmall
                    text={I18n.t('description')}
                />
                <TextInput
                    size={TextInputSize.multiline}
                    onChange={this.onFormFieldChanged('comment')}
                    value={this.getFormFieldData('comment')}
                />
            </ColorBox>
        );
    };

    renderVolumes = () => {
        return (
            <>
                <HeadlineSmall
                    text={I18n.t('volumes')}
                />
                <ColorBox
                    color={ColorBoxColor.grayLight}
                    className={styles.volumesBox}
                >
                    <div>
                        <HeadlineSmall
                            text={I18n.t('total')}
                        />
                        <TextInput
                            type={'number'}
                            onChange={this.onFormFieldChanged('totalVolume')}
                            value={this.getFormFieldData('totalVolume')}
                        />
                        <ValidationError
                            validation={this.props.validation}
                            field={'totalVolume'}
                        />
                    </div>
                    <div>
                        <HeadlineSmall
                            text={I18n.t('peakYear')}
                        />
                        <TextInput
                            type={'number'}
                            onChange={this.onFormFieldChanged('peakYearVolume')}
                            value={this.getFormFieldData('peakYearVolume')}
                        />
                        <ValidationError
                            validation={this.props.validation}
                            field={'peakYearVolume'}
                        />
                    </div>
                </ColorBox>
            </>
        );
    };

    renderProjectDuration = () => {
        return (
            <>
                <HeadlineSmall
                    text={I18n.t('projectDuration')}
                />
                <ColorBox
                    color={ColorBoxColor.grayLight}
                    className={styles.projectDurationBox}
                >
                    <div>
                        <HeadlineSmall
                            text={I18n.t('start')}
                        />
                        <DateInput
                            onChange={this.onFormFieldChanged('startDate')}
                            value={this.getFormFieldData('startDate')}
                        />
                        <ValidationError
                            validation={this.props.validation}
                            field={'startDate'}
                        />
                    </div>
                    <div>
                        <HeadlineSmall
                            text={I18n.t('end')}
                        />
                        <DateInput
                            onChange={this.onFormFieldChanged('endDate')}
                            value={this.getFormFieldData('endDate')}
                        />
                        <ValidationError
                            validation={this.props.validation}
                            field={'endDate'}
                        />
                    </div>
                </ColorBox>
            </>
        );
    };

    onFilesChanged = (files) => {
        this.props.onFilesChanged({
            files,
        });
    };

    onFileDeleted = (file) => {
        this.props.onFileDeleted({
            file,
        });
    };

    renderAttachments = () => {
        const { data }        = this.props;
        const { attachments } = data;

        return (
            <>
                <HeadlineSmall
                    text={I18n.t('attachments')}
                />
                <ColorBox
                    color={ColorBoxColor.grayLight}
                    className={styles.attachmentsBox}
                >
                    <AttachmentUpload
                        files={attachments}
                        onFilesChanged={this.onFilesChanged}
                        onFileDeleted={this.onFileDeleted}
                    />
                </ColorBox>
            </>
        );
    };

    renderPostingTime = () => {
        return (
            <>
                <HeadlineSmall
                    text={I18n.t('postingTime')}
                />
                <ColorBox
                    color={ColorBoxColor.grayLight}
                    className={styles.postingTimeBox}
                >
                    <Flex
                        gap={'70px'}
                        alignItems={AlignItems.end}
                    >
                        <CheckBox
                            text={I18n.t('now')}
                            className={styles.postingTimeBoxCheckBox}
                            valueChanged={this.onFormFieldChanged('visibleNow')}
                            checked={this.getFormFieldData('visibleNow')}
                        />
                        <Flex
                            gap={'10px'}
                            alignItems={AlignItems.end}
                        >
                            <CheckBox
                                className={styles.postingTimeBoxCheckBox}
                                valueChanged={this.onFormFieldChanged('visibleDate')}
                                checked={this.getFormFieldData('visibleDate')}
                            />
                            <Flex flexDirection={FlexDirection.column}>
                                <HeadlineSmall
                                    text={I18n.t('start')}
                                />
                                <DateInput
                                    onChange={this.onFormFieldChanged('visibleFromDate')}
                                    value={this.getFormFieldData('visibleFromDate')}
                                />
                                <ValidationError
                                    validation={this.props.validation}
                                    field={'visibleFromDate'}
                                />
                            </Flex>
                            <Flex flexDirection={FlexDirection.column}>
                                <HeadlineSmall
                                    text={I18n.t('end')}
                                />
                                <DateInput
                                    onChange={this.onFormFieldChanged('visibleToDate')}
                                    value={this.getFormFieldData('visibleToDate')}
                                />
                                <ValidationError
                                    validation={this.props.validation}
                                    field={'visibleToDate'}
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                </ColorBox>
            </>
        );
    };

    renderBody = () => {
        const { data }                                                                                  = this.props;
        const { technologyTags, materialTags, branches, partFamilyTags, mandatoryTags, inspectionTags } = data;

        return (
            <div className={styles.wrapper}>
                <HeadlineMedium text={I18n.t('createProject')} />
                <Spacer
                    height={20}
                    width={10}
                />
                {this.renderBasicInfos()}
                <Spacer
                    height={30}
                    width={10}
                />
                {this.renderTagBox(TagSelectorType.technologies, technologyTags, I18n.t('technologies'))}
                {this.renderTagBox(TagSelectorType.material, materialTags, I18n.t('material'))}
                {this.renderTagBox(TagSelectorType.industry, branches, I18n.t('industry'))}
                {this.renderTagBox(TagSelectorType.partFamily, partFamilyTags, I18n.t('partFamily'))}
                {this.renderTagBox(TagSelectorType.mandatory, mandatoryTags, I18n.t('mandatory'))}
                {this.renderTagBox(TagSelectorType.inspection, inspectionTags, I18n.t('inspection'))}
                {this.renderCountryOfDelivery()}
                {this.renderVolumes()}
                {this.renderProjectDuration()}
                {this.renderAttachments()}
                {this.renderPostingTime()}
            </div>
        );
    };

    renderCountryOfDelivery = () => {
        const { countryOfDelivery } = this.props.data;

        return (
            <>
                <HeadlineSmall
                    text={I18n.t('countryOfDelivery')}
                />
                <ColorBox
                    color={ColorBoxColor.grayLight}
                >
                    <CountryDropdown
                        classes={styles.countryDropdown}
                        value={countryOfDelivery}
                        valueType={'short'}
                        onChange={this.onFormFieldChanged('countryOfDelivery')}
                    />
                    <ValidationError
                        validation={this.props.validation}
                        field={'countryOfDelivery'}
                    />
                </ColorBox>
            </>
        );
    };

    render() {
        return (
            <Overlay
                id={Overlays.createProject}
                okButtonPressed={this.props.onSaveButtonPressed}
                okButtonValidator={this.props.onSaveButtonValidator}
                okButtonText={I18n.t('save')}
                overlayType={OverlayType.full}
                disableCloseOnOutsideClick={true}
            >
                {this.renderBody()}
            </Overlay>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default CreateProjectOverlay;
