//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update                                  from 'immutability-helper';
import { LOCATION_CHANGE }                     from 'connected-react-router';
import AlertBoxType                            from '@/components/stateless/atomic/AlertBox/AlertBoxType';
import SelectionHelper                         from '@/helper/SelectionHelper';
import { AlertBoxTypes as AlertBoxTypesRedux } from '@/store/actions/alertBox';
import { UserTypes }                           from '@/store/actions/user';

const initialState       = {
    alertBoxes: [],
};
const defaultLifeCounter = 1;

const clear = (action, state) => {
    return update(state, {
        $set: initialState,
    });
};

const clearSingle = (action, state) => {
    return update(state, {
        alertBoxes: {
            $splice: [
                [
                    action.index,
                    defaultLifeCounter,
                ],
            ],
        },
    });
};

const showResult = (action, state) => {
    const lifeCounter = _.defaultTo(action.lifeCounter, defaultLifeCounter);
    const type        = SelectionHelper.get(
        action.type,
        {
            [AlertBoxTypesRedux.SHOW_ERROR]:   AlertBoxType.error,
            [AlertBoxTypesRedux.SHOW_SUCCESS]: AlertBoxType.success,
        },
    );

    return update(state, {
        alertBoxes: {
            $push: [
                {
                    lifeCounter,
                    text: action.text,
                    type,
                },
            ],
        },
    });
};

const locationChange = (action, state) => {
    const locationChangeUpdateConfiguration = {
        alertBoxes: {},
    };
    const alertBoxesToDelete                = [];

    for (const alertBoxIndex in state.alertBoxes) {
        const alertBox = state.alertBoxes[alertBoxIndex];

        if (defaultLifeCounter >= alertBox.lifeCounter) {
            alertBoxesToDelete.push([
                alertBoxIndex,
                1,
            ]);
        } else {
            locationChangeUpdateConfiguration.alertBoxes[alertBoxIndex] = {
                lifeCounter: {
                    $set: alertBox.lifeCounter - 1,
                },
            };
        }
    }

    const stateWithIncreasedLifeCounters = update(state, locationChangeUpdateConfiguration);

    alertBoxesToDelete.reverse();

    const stateWithRemovedAlertBoxes = update(stateWithIncreasedLifeCounters, {
        alertBoxes: {
            $splice: alertBoxesToDelete,
        },
    });

    return stateWithRemovedAlertBoxes;
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case UserTypes.LOGOUT:
        case AlertBoxTypesRedux.CLEAR:        return clear(action, state);
        case AlertBoxTypesRedux.CLEAR_SINGLE: return clearSingle(action, state);
        case AlertBoxTypesRedux.SHOW_ERROR:
        case AlertBoxTypesRedux.SHOW_SUCCESS: return showResult(action, state);
        case LOCATION_CHANGE:                 return locationChange(action, state);
        default:                              return state;
        // @formatter:on
    }
}
