//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import PropTypes          from 'prop-types';
import Align              from '@/constants/Align';
import ApiMode            from '@/constants/ApiMode';
import IconType           from '@/components/stateless/atomic/Icon/IconType';
import MachineParameter   from '@/components/stateless/atomic/MachineParameter';
import { TestIdPrefixes } from '@/constants/TestIds';
import { TestIds }        from '@/constants/TestIds';
import FactSheetEditType  from '@/constants/FactSheetEditType';
import MachineCapacity    from '@/constants/MachineCapacity';

PropTypes.to                    = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
]);
PropTypes.tab                   = PropTypes.shape({
    badgeCount: PropTypes.number,
    title:      PropTypes.string,
});
PropTypes.machineParameter      = PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf([MachineParameter]),
}));
PropTypes.houseNumber           = PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
]);
PropTypes.postalCode            = PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
]);
PropTypes.geoLocation           = PropTypes.shape({
    latitude:  PropTypes.number,
    longitude: PropTypes.number,
});
PropTypes.partner               = PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string,
});
PropTypes.children              = PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
]);
PropTypes.image                 = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
]);
PropTypes.stringOrArray         = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
]);
PropTypes.stringOrArrayOrObject = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
]);
PropTypes.oneOfObjectKeys       = (object) => {
    return PropTypes.oneOf(Object.keys(object));
};
PropTypes.oneOfObjectValues     = (object) => {
    return PropTypes.oneOf(Object.values(object));
};
PropTypes.apiMode               = PropTypes.oneOfObjectValues(ApiMode);
PropTypes.factSheetEditType     = PropTypes.oneOfObjectValues(FactSheetEditType);
PropTypes.machineCapacity       = PropTypes.oneOfObjectValues(MachineCapacity);
PropTypes.factSheetEditContext  = PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
]);
PropTypes.icon                  = PropTypes.oneOfObjectValues(IconType);
PropTypes.align                 = PropTypes.oneOfObjectValues(Align);
PropTypes.oneOfTestIds          = PropTypes.oneOfObjectValues(TestIds);
PropTypes.oneOfTestIdPrefix     = PropTypes.oneOfObjectValues(TestIdPrefixes);
PropTypes.tagTree               = PropTypes.shape({
    tags:             PropTypes.array,
    selectedTagIndex: PropTypes.number,
});
PropTypes.tagTree.children      = PropTypes.arrayOf(PropTypes.tagTree);

export default PropTypes;
