//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                   from 'lodash';
import update              from 'immutability-helper';
import StartPageSearchMode from '@/components/stateless/composed/StartPageSearch/StartPageSearchMode';
import Tag                 from '@/helper/Tag';
import { SearchTypes }     from '@/store/actions/search';
import { REHYDRATE }       from 'redux-persist';

const initialState = {
    query:                 '',
    indices:               [
        'tags',
        'machines',
        'companies',
    ],
    items:                 {},
    resultItems:           {},
    tagQuery:              [],
    tagQueryAsList:        [],
    paging:                {
        machines:             {
            page: 1,
        },
        supplier:             {
            page: 1,
        },
        machine_manufacturer: {
            page: 1,
        },
    },
    searchMode:            StartPageSearchMode.resultList,
    possibleResultsCount:  0,
    isLoading:             0,
    otherSearch:           false,
    lastAddedSiblingTagId: null,
};

const addChildrenToTagQuery = (action, state) => {
    const tag      = _.get(action, 'tag');
    const children = _.get(action, 'children');
    const tagQuery = Tag.addPossibleChildToTagSelectorTree(state.tagQuery, tag, children);

    return update(
        state,
        {
            tagQuery:       {
                $set: tagQuery,
            },
            tagQueryAsList: {
                $set: Tag.gatherLeafIdentifier(tagQuery),
            },
        },
    );
};

const addNextSibling = (action, state) => {
    const hierarchyPath                       = _.get(action, 'data.hierarchyPath');
    const possibleChildren                    = _.get(action, 'data.tags');
    const { tagQuery, lastAddedSiblingTagId } = Tag.addNextSiblingToTagSelectorTree(state.tagQuery, hierarchyPath, possibleChildren);

    return update(
        state,
        {
            tagQuery:              {
                $set: tagQuery,
            },
            tagQueryAsList:        {
                $set: Tag.gatherLeafIdentifier(tagQuery),
            },
            lastAddedSiblingTagId: {
                $set: lastAddedSiblingTagId,
            },
        },
    );
};

const addTagToQuery = (action, state) => {
    const tagQuery = Tag.addTagToTagSelectorTree(state.tagQuery, action.tag);

    return update(state, {
        tagQuery:       {
            $set: tagQuery,
        },
        tagQueryAsList: {
            $set: Tag.gatherLeafIdentifier(tagQuery),
        },
    });
};

const changeSearchMode = (action, state) => {
    return update(state, {
        searchMode: {
            $set: action.mode,
        },
    });
};

const changeTag = (action, state) => {
    const hierarchyPath = _.get(action, 'data.hierarchyPath');
    const changedValue  = _.get(action, 'changedValue.value');
    const tagQuery      = Tag.changeTagInTagSelectorTree(state.tagQuery, hierarchyPath, changedValue);

    return update(
        state,
        {
            tagQuery:       {
                $set: tagQuery,
            },
            tagQueryAsList: {
                $set: Tag.gatherLeafIdentifier(tagQuery),
            },
        },
    );
};

const clearQuery = (action, state) => {
    return update(state, {
        query: {
            $set: '',
        },
        items: {
            $set: {},
        },
    });
};

const clearItems = (action, state) => {
    return update(state, {
        items:       {
            $set: {},
        },
        resultItems: {
            $set: {},
        },
    });
};

const clearSearchResults = () => {
    return initialState;
};

const deleteTag = (action, state) => {
    const hierarchyPath = _.get(action, 'data.hierarchyPath');
    const tagQuery      = Tag.removeTagFromTagSelectorTree(state.tagQuery, hierarchyPath);

    return update(
        state,
        {
            tagQuery:       {
                $set: tagQuery,
            },
            tagQueryAsList: {
                $set: Tag.gatherLeafIdentifier(tagQuery),
            },
        },
    );
};

const search = (action, state) => {
    return update(state, {
        query:       {
            $set: action.query,
        },
        isLoading:   {
            $increaseBy: 1,
        },
        otherSearch: {
            $set: action.otherSearch ? action.otherSearch : false,
        },
    });
};

const searchByTags = (action, state) => {
    return update(state, {
        isLoading: {
            $increaseBy: 1,
        },
    });
};

const searchByTagsSuccess = (action, state) => {
    const sortByScoreAndName = (left, right) => {
        const scoreLeft  = _.get(left, 'score');
        const scoreRight = _.get(right, 'score');

        return scoreRight - scoreLeft;
    };

    const markTop3AsBestMatch = (items) => {
        const copy         = _.cloneDeep(items);
        const highestScore = _.maxBy(copy, (item) => item.score);

        for (let index = 0; index < copy.length; index++) {
            copy[index].bestMatch = (
                index < 3 &&
                copy[index].score === highestScore.score
            );
        }

        return copy;
    };

    let supplier            = _.get(action, 'results.supplier', []);
    let machineManufacturer = _.get(action, 'results.machine_manufacturer', []);
    let machines            = _.get(action, 'results.machines', []);
    machines                = machines.sort(sortByScoreAndName);
    machines                = markTop3AsBestMatch(machines);
    supplier                = supplier.sort(sortByScoreAndName);
    supplier                = markTop3AsBestMatch(supplier);
    machineManufacturer     = machineManufacturer.sort(sortByScoreAndName);
    machineManufacturer     = markTop3AsBestMatch(machineManufacturer);
    const results           = {
        supplier,
        'machine_manufacturer': machineManufacturer,
        machines,
    };

    return update(state, {
        possibleResultsCount:      {
            $set: action.resultCount,
        },
        resultItems:               {
            $set: results,
        },
        totalItemsWithoutMachines: {
            $set: _.get(action, 'totalItemsWithoutMachines', 0),
        },
        paging:                    {
            $set: _.get(action, 'paging', initialState.paging),
        },
        isLoading:                 {
            $set: 0,
        },
    });
};

const searchSuccess = (action, state) => {
    return update(state, {
        items:     {
            $set: action.items,
        },
        isLoading: {
            $decreaseBy: 1,
        },
    });
};

const searchFailed = (action, state) => {
    return update(state, {
        isLoading: {
            $decreaseBy: 1,
        },
    });
};

const showLoadingIndicator = (action, state) => {
    return update(state, {
        isLoading: {
            $increaseBy: 1,
        },
    });
};

const hideLoadingIndicator = (action, state) => {
    return update(state, {
        isLoading: {
            $decreaseBy: 1,
        },
    });
};

const updatePaging = (action, state) => {
    const context    = _.get(action, 'context');
    let currentState = state;

    if (!state.paging) {
        currentState = update(currentState, {
            paging: {
                $set: initialState.paging,
            },
        });
    }

    return update(currentState, {
        paging: {
            [context]: {
                page: {
                    $set: _.get(action, 'page', 1),
                },
            },
        },
    });
};

const rehydrate = (action, state) => {
    return update(state, {
        isLoading: {
            $set: 0,
        },
    });
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case SearchTypes.ADD_CHILDREN_TO_TAG_QUERY: return addChildrenToTagQuery(action, state);
        case SearchTypes.ADD_NEXT_SIBLING:          return addNextSibling(action, state);
        case SearchTypes.ADD_TAG_QUERY:             return addTagToQuery(action, state);
        case SearchTypes.CHANGE_SEARCH_MODE:        return changeSearchMode(action, state);
        case SearchTypes.CHANGE_TAG:                return changeTag(action, state);
        case SearchTypes.CLEAR_ITEMS:               return clearItems(action, state);
        case SearchTypes.CLEAR_QUERY:               return clearQuery(action, state);
        case SearchTypes.CLEAR_SEARCH_RESULTS:      return clearSearchResults(action, state);
        case SearchTypes.DELETE_TAG:                return deleteTag(action, state);
        case SearchTypes.SEARCH:                    return search(action, state);
        case SearchTypes.SEARCH_BY_TAGS:            return searchByTags(action, state);
        case SearchTypes.SEARCH_BY_TAGS_SUCCEEDED:  return searchByTagsSuccess(action, state);
        case SearchTypes.SEARCH_SUCCEEDED:          return searchSuccess(action, state);
        case SearchTypes.SEARCH_FAILED:             return searchFailed(action, state);
        case SearchTypes.SHOW_LOADING_INDICATOR:    return showLoadingIndicator(action, state);
        case SearchTypes.HIDE_LOADING_INDICATOR:    return hideLoadingIndicator(action, state);
        case SearchTypes.UPDATE_PAGING:             return updatePaging(action, state);

        case REHYDRATE:                             return rehydrate(action, state);
        default:                                    return state;
        // @formatter:on
    }
}
