//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { makeActionCreator } from '@/helper/Store';

export const AlertBoxTypes = {
    CLEAR:        'AlertBox/CLEAR',
    CLEAR_SINGLE: 'AlertBox/CLEAR_SINGLE',
    SHOW_ERROR:   'AlertBox/SHOW_ERROR',
    SHOW_SUCCESS: 'AlertBox/SHOW_SUCCESS',
};

const clearAlerts = makeActionCreator(
    AlertBoxTypes.CLEAR,
    {},
);

const clearSingleAlert = makeActionCreator(
    AlertBoxTypes.CLEAR_SINGLE,
    {
        index: null,
    },
);

const showErrorAlert = makeActionCreator(
    AlertBoxTypes.SHOW_ERROR,
    {
        text:      null,
        lifeCount: null,
    },
);

const showSuccessAlert = makeActionCreator(
    AlertBoxTypes.SHOW_SUCCESS,
    {
        text:      null,
        lifeCount: null,
    },
);

export const AlertBoxActions = {
    clearAlerts,
    clearSingleAlert,
    showErrorAlert,
    showSuccessAlert,
};
