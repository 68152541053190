//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { put }        from 'redux-saga/effects';
import { delay }      from 'redux-saga/effects';
import { call }       from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import { takeEvery }  from 'redux-saga/effects';
import I18n           from 'i18next';
import _              from 'lodash';

import * as Api                  from '@/api';
import SagaStateHelper           from '@/helper/SagaStateHelper';
import StateHelper               from '@/helper/State';
import { AlertBoxActions }       from '@/store/actions/alertBox';
import { MessageRequestActions } from '@/store/actions/messageRequest';
import { MessageRequestTypes }   from '@/store/actions/messageRequest';
import HydraHelper               from '@/helper/Hydra';
import MessageRequestState       from '@/constants/MessageRequestState';
import { LoadingActions }        from '@/store/actions/loading';
import { getError }              from '@/constants/Errors';
import Route                     from '@/helper/Route';
import { NavigationActions }     from '@/store/actions/navigation';

function* markMessagesAsRead(action) {
    const { messageRequestId } = action;

    yield call(Api.markMessagesAsRead, messageRequestId);
}

function* fetchMessageRequests(action) {
    const { postAction } = action;
    const search         = yield SagaStateHelper.selectFromState('router', 'location', 'search');
    const parameter      = new URLSearchParams(search);
    const page           = parameter.get('page') ?? 1;
    const response       = yield call(Api.fetchMessageRequests, page);

    if (response.ok) {
        const { member, totalItems } = response.data;
        const messageRequests        = HydraHelper.cleanupObject(member);

        yield put(MessageRequestActions.fetchMessageRequestsSucceeded({
            messageRequests,
            postAction,
            totalItems,
            page,
        }));
    } else {
        yield put(MessageRequestActions.fetchMessageRequestsFailed());
    }
}

function* fetchMessageRequestsSucceeded(action) {
    const { postAction } = action;

    if (postAction) {
        yield put(postAction);
    }
}

function* sendMessageRequest() {
    const messageRequest       = yield SagaStateHelper.selectFromState('messageRequest');
    const state                = yield SagaStateHelper.getState();
    const companyIri           = StateHelper.getCurrentCompanyIri(state);
    const requestingCompanyIri = StateHelper.getUserCompanyIri(state);
    const portfolioProduct     = _.get(messageRequest, 'portfolioProduct.iri', null);
    const machine              = _.get(messageRequest, 'machine.iri', null);
    let tags                   = _.map(
        _.get(messageRequest, 'tags', []),
        (tag) => _.get(tag, 'iri', null),
    );
    tags                       = _.compact(tags);
    const mappedMessageRequest = {
        ...messageRequest,
        company:           companyIri,
        tags,
        requestingCompany: requestingCompanyIri,
        portfolioProduct,
        machine,
    };
    const isValid              = (
        _.get(mappedMessageRequest, 'subject', null) &&
        _.get(mappedMessageRequest, 'message', null)
    );

    yield put(AlertBoxActions.clearAlerts());

    if (!isValid) {
        yield put(AlertBoxActions.showErrorAlert({
            text: I18n.t('sendMessageRequestMissingSubjectOrMessage'),
        }));
        yield put(LoadingActions.resetOverlay());

        return;
    }

    const response = yield call(
        Api.saveMessageRequest,
        mappedMessageRequest,
    );

    if (response.ok) {
        const messageRequestResponse = HydraHelper.cleanupObject(response.data);

        yield put(MessageRequestActions.sendMessageRequestSucceeded());
        yield delay(1000);
        yield put(MessageRequestActions.reset());
        yield delay(1000);

        const companyId         = yield SagaStateHelper.selectFromState('user', 'company');
        const company           = yield SagaStateHelper.selectFromState('company', 'companies', companyId);
        let messageRequestRoute = null;

        if (companyId) {
            messageRequestRoute = Route.buildPathForCompanyMessageRequest(company, messageRequestResponse);
        } else {
            messageRequestRoute = Route.buildPathForProfileMessageRequestDetail(messageRequestResponse);
        }

        yield put(MessageRequestActions.fetchMessageRequests({
            postAction: NavigationActions.openUrl({
                url: messageRequestRoute,
            }),
        }));
    } else {
        yield put(MessageRequestActions.sendMessageRequestFailed());
    }
}

function* sendMessageRequestFailed() {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('sendMessageRequestFailed'),
    }));
}

function* sendMessageRequestSucceeded() {
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('sendMessageRequestSucceeded'),
    }));
}

function* setMessageRequestState(action) {
    const { iri, state }       = action;
    const messageRequests      = yield SagaStateHelper.selectFromState('messageRequest', 'messageRequests');
    const messageRequest       = _.find(messageRequests, {
        iri,
    });
    const clonedMessageRequest = _.cloneDeep(messageRequest);
    clonedMessageRequest.state = state;
    const response             = yield call(
        Api.saveMessageRequest,
        clonedMessageRequest,
    );

    if (response.ok) {
        yield put(MessageRequestActions.setMessageRequestStateSucceeded({
            state,
        }));
    } else {
        yield put(MessageRequestActions.setMessageRequestStateFailed());
    }
}

function* setMessageRequestStateSucceeded(action) {
    const { state }  = action;
    const messageKey = (
        state === MessageRequestState.ACCEPTED ?
            'setMessageRequestStateAccepted' :
            'setMessageRequestStateDeclined'
    );

    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t(messageKey),
    }));
    yield put(MessageRequestActions.fetchMessageRequests());
}

function* setMessageRequestStateFailed() {
    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t('setMessageRequestStateFailed'),
    }));
}

function* sendMessage() {
    const state       = yield SagaStateHelper.getState();
    const message     = yield SagaStateHelper.selectFromState('messageRequest', 'sendMessageText');
    const attachments = yield SagaStateHelper.selectFromState('messageRequest', 'sendMessageAttachments');
    const isValid     = _.get(message, 'length', 0) > 0;
    const companyIri  = StateHelper.getCurrentCompanyIri(state);

    yield put(AlertBoxActions.clearAlerts());

    if (!isValid) {
        yield put(AlertBoxActions.showErrorAlert({
            text: I18n.t('sendMessageMissingMessage'),
        }));
        yield put(LoadingActions.resetOverlay());

        return;
    }

    const messageObject = {
        message,
        attachments:    (
            _.isNil(attachments) ?
                [] :
                attachments
        ),
        company:        companyIri,
        messageRequest: _.get(state, 'messageRequest.currentMessageRequest'),
    };
    const response      = yield call(
        Api.saveMessage,
        messageObject,
    );

    if (response.ok) {
        yield put(MessageRequestActions.fetchMessageRequests());
        yield put(MessageRequestActions.sendMessageSucceeded());
    } else {
        const error = getError(response);

        yield put(MessageRequestActions.sendMessageFailed({
            error,
        }));
    }
}

function* sendMessageFailed(action) {
    const error      = _.get(action, 'error', null);
    const messageKey = _.get(error, 'messageKey', 'sendMessageFailed');

    yield put(AlertBoxActions.showErrorAlert({
        text: I18n.t(messageKey),
    }));
}

function* sendMessageSucceeded() {
    yield put(AlertBoxActions.showSuccessAlert({
        text: I18n.t('sendMessageSucceeded'),
    }));
}

export const callMessageRequestSagas = () => {
    return [
        // @formatter:off
        takeLatest([MessageRequestTypes.FETCH_MESSAGE_REQUESTS_SUCCEEDED],    fetchMessageRequestsSucceeded),
        takeLatest([MessageRequestTypes.SEND_MESSAGE_REQUEST],                sendMessageRequest),
        takeLatest([MessageRequestTypes.SEND_MESSAGE_REQUEST_FAILED],         sendMessageRequestFailed),
        takeLatest([MessageRequestTypes.SEND_MESSAGE_REQUEST_SUCCEEDED],      sendMessageRequestSucceeded),
        takeLatest([MessageRequestTypes.MARK_MESSAGES_AS_READ],               markMessagesAsRead),
        takeEvery([MessageRequestTypes.FETCH_MESSAGE_REQUESTS],               fetchMessageRequests),
        takeLatest([MessageRequestTypes.SET_MESSAGE_REQUEST_STATE],           setMessageRequestState),
        takeLatest([MessageRequestTypes.SET_MESSAGE_REQUEST_STATE_FAILED],    setMessageRequestStateFailed),
        takeLatest([MessageRequestTypes.SET_MESSAGE_REQUEST_STATE_SUCCEEDED], setMessageRequestStateSucceeded),
        takeLatest([MessageRequestTypes.SEND_MESSAGE],                        sendMessage),
        takeLatest([MessageRequestTypes.SEND_MESSAGE_FAILED],                 sendMessageFailed),
        takeLatest([MessageRequestTypes.SEND_MESSAGE_SUCCEEDED],              sendMessageSucceeded),
        // @formatter:on
    ];
};
