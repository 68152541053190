//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React      from 'react';
import I18n       from 'i18next';
import classNames from 'classnames';
import _          from 'lodash';

import Icon               from '@/components/stateless/atomic/Icon';
import IconType           from '@/components/stateless/atomic/Icon/IconType';
import ComponentHelper    from '@/helper/ComponentHelper';
import PropTypes          from '@/components/PropTypes';
import File               from '@/helper/File';
import { IconTextButton } from '@/components/stateless/atomic/IconTextButton';
import { TextInput }      from '@/components/stateless/atomic/TextInput';
import { AlertBox }       from '@/components/stateless/atomic/AlertBox';
import AlertBoxType       from '@/components/stateless/atomic/AlertBox/AlertBoxType';
import { Spacer }         from '@/components/stateless/atomic/Spacer';
import styles             from './styles.module.scss';

export class AttachmentUpload extends React.Component {
    static propTypes = {
        accept:                   PropTypes.string,
        attachedFilesTitle:       PropTypes.string,
        buttonText:               PropTypes.string,
        buttonIcon:               PropTypes.string,
        files:                    PropTypes.array,
        headline:                 PropTypes.string,
        iconType:                 PropTypes.string,
        onFileDeleted:            PropTypes.func,
        onFilesChanged:           PropTypes.func,
        onTitleChanged:           PropTypes.func,
        renderOnlyAttachmentList: PropTypes.bool,
        renderOnlyUploadButton:   PropTypes.bool,
        renderTitleInput:         PropTypes.bool,
        renderUploadButton:       PropTypes.bool,
        title:                    PropTypes.string,
    };

    static defaultProps = {
        accept:                   null,
        attachedFilesTitle:       I18n.t('attachedFiles'),
        buttonText:               I18n.t('addAttachment'),
        buttonIcon:               IconType.file,
        files:                    [],
        headline:                 I18n.t('upload'),
        iconType:                 IconType.file,
        onFileDeleted:            _.noop,
        onFilesChanged:           _.noop,
        onTitleChanged:           _.noop,
        renderOnlyAttachmentList: false,
        renderOnlyUploadButton:   false,
        renderTitleInput:         false,
        renderUploadButton:       false,
        title:                    null,
    };

    static renderAffectingProps = Object.keys(this.defaultProps);

    static renderAffectingStates = [
        'dragActive',
        'invalidFormat',
    ];

    inputReference = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            dragActive:    false,
            invalidFormat: false,
        };
    }

    renderFile = (file) => {
        const { renderOnlyAttachmentList } = this.props;
        const displayName                  = _.get(file, 'displayName', null);
        const name                         = _.get(file, 'name', null);

        if (!file) {
            return null;
        }

        return (
            <div
                className={classNames({
                    [styles.fileEntry]:                      !renderOnlyAttachmentList,
                    [styles.renderOnlyAttachmentsFileEntry]: renderOnlyAttachmentList,
                })}
                key={_.uniqueId(file.name)}
            >
                {_.defaultTo(displayName, name)}
                <div
                    className={styles.deleteCircleWrapper}
                    onClick={this.onDeleteClicked(file)}
                >
                    <Icon iconType={IconType.deleteCircle} />
                </div>
            </div>
        );
    };

    onDeleteClicked = (file) => {
        return () => {
            this.props.onFileDeleted(file);
        };
    };

    onTitleChanged = (event) => {
        this.props.onTitleChanged(_.get(event, 'target.value', ''));
    };

    renderFiles = () => {
        const { files }          = this.props;
        const renderedComponents = [];

        for (const file of files) {
            renderedComponents.push(this.renderFile(file));
        }

        return renderedComponents;
    };

    onHandleFileChange = async (event) => {
        console.group('onHandleFileChange');

        const files = _.get(event, 'target.files', []);

        this.setState({
            ...this.state,
            invalidFormat: false,
        });

        this.onHandleFiles(files);
    };

    onHandleFiles = async (files) => {
        console.group('onHandleFileChange');

        const convertedFiles = [];

        for (const file of files) {
            console.time('convertFile');

            const fileName      = file.name;
            const fileExtension = `.${fileName.split('.').pop()}`;
            const accept        = this.props.accept;

            if (
                accept &&
                !accept.includes(fileExtension)
            ) {
                this.setState({
                    invalidFormat: true,
                });

                break;
            }

            const fileAsUrl = await File.getFileFromBlob(file);

            convertedFiles.push({
                name: file.name,
                type: file.type,
                size: file.size,
                file: fileAsUrl,
            });
            console.timeEnd('convertFile');
        }

        this.props.onFilesChanged([
            ...this.props.files,
            ...convertedFiles,
        ]);
        console.groupEnd();
    };

    onHandleDrag = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (
            event.type === 'dragenter' ||
            event.type === 'dragover'
        ) {
            this.setState({
                dragActive: true,
            });
        } else if (event.type === 'dragleave') {
            this.setState({
                dragActive: false,
            });
        }
    };

    onHandleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            dragActive: false,
        });

        const { dataTransfer } = event;
        const { files }        = dataTransfer;

        if (
            files &&
            files[0]
        ) {
            this.onHandleFiles(files);
        }
    };

    onButtonClick = () => {
        this.inputReference.current.click();
    };

    onSubmit = (event) => {
        event.preventDefault();
    };

    renderTitleInputOrAttachmentsTitle = () => {
        if (this.props.renderTitleInput) {
            return (
                <>
                    <h3>
                        {I18n.t('title')}
                    </h3>
                    <TextInput
                        onChange={this.onTitleChanged}
                        value={this.props.title}
                    />
                </>
            );
        }

        return (
            <h3>
                {this.props.attachedFilesTitle}
            </h3>
        );
    };

    renderAcceptError = () => {
        if (this.state.invalidFormat) {
            return (
                <>
                    <AlertBox
                        text={I18n.t(
                            'invalidFileFormat',
                            {
                                accept: this.props.accept,
                            },
                        )}
                        type={AlertBoxType.error}
                    />
                    <Spacer height={10} />
                </>
            );
        }

        return null;
    };

    render() {
        const { dragActive }                                                      = this.state;
        const { renderOnlyAttachmentList, renderOnlyUploadButton, files, accept } = this.props;

        if (renderOnlyAttachmentList) {
            if (
                _.isNil(files) ||
                _.isEmpty(files)
            ) {
                return null;
            }

            return (
                <div>
                    <h3>
                        {this.props.attachedFilesTitle}
                    </h3>
                    {this.renderFiles()}
                </div>
            );
        }

        if (renderOnlyUploadButton) {
            return (
                <>
                    <IconTextButton
                        text={this.props.buttonText}
                        iconType={this.props.buttonIcon}
                        onClick={this.onButtonClick}
                    />
                    <div className={styles.hideFileInput}>
                        <input
                            ref={this.setInputReference}
                            type={'file'}
                            multiple={true}
                            accept={accept}
                            onChange={this.onHandleFileChange}
                        />
                    </div>
                </>
            );
        }

        return (
            <>
                {this.renderAcceptError()}
                <div className={styles.wrapper}>
                    <div className={styles.left}>
                        <h3>
                            {this.props.headline}
                        </h3>
                        <form
                            onDragEnter={this.onHandleDrag}
                            onSubmit={this.onSubmit}
                            onClick={this.onButtonClick}
                        >
                            <input
                                ref={this.setInputReference}
                                type={'file'}
                                multiple={true}
                                accept={accept}
                                onChange={this.onHandleFileChange}
                            />
                            <div
                                className={classNames(
                                    styles.label,
                                    {
                                        [styles.dragActive]: dragActive,
                                    },
                                )}
                            >
                                <div className={styles.iconWrapper}>
                                    <Icon
                                        iconType={this.props.iconType}
                                    />
                                </div>
                                <div className={styles.uploadButton}>
                                    {I18n.t('dragDropFileText')}
                                </div>
                            </div>
                            {this.renderDragElement()}
                        </form>
                        {this.props.renderUploadButton && (
                            <>
                                <Spacer height={10} />
                                <IconTextButton
                                    text={this.props.buttonText}
                                    iconType={this.props.buttonIcon}
                                    onClick={this.onButtonClick}
                                />
                            </>
                        )}
                    </div>
                    <div className={styles.right}>
                        {this.renderTitleInputOrAttachmentsTitle()}
                        {this.renderFiles()}
                    </div>
                </div>
            </>
        );
    }

    renderDragElement = () => {
        if (this.state.dragActive) {
            return (
                <div
                    className={styles.dragFileElement}
                    onDragEnter={this.onHandleDrag}
                    onDragLeave={this.onHandleDrag}
                    onDragOver={this.onHandleDrag}
                    onDrop={this.onHandleDrop}
                />
            );
        }

        return null;
    };

    setInputReference = (reference) => {
        this.inputReference.current = reference;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export default AttachmentUpload;
