//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _               from 'lodash';
import React           from 'react';
import AlertBox        from '@/components/stateless/atomic/AlertBox';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div className={styles.alertBoxWrapper}>
                {this.props.alertBoxes.map(this.renderAlertBox)}
            </div>
        );
    }

    renderAlertBox = (data, index) => {
        if (data) {
            return (
                <AlertBox
                    onCloseButtonClick={this.props.hideButtonPressed}
                    key={`alert-${index}`}
                    text={data.text}
                    type={data.type}
                />
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const AlertBoxWrapper = Component;

Component.propTypes = {
    alertBoxes:        PropTypes.array,
    hideButtonPressed: PropTypes.func,
};

Component.defaultProps = {
    alertBoxes:        [],
    hideButtonPressed: _.noop,
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
