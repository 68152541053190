//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { takeLatest } from 'redux-saga/effects';

import { AlertBoxTypes } from '@/store/actions/alertBox';

function* scrollToTop(action) {
    if (action.scrollToTop) {
        window.scrollTo(0, 0);
    }
}

export const callAlertSagas = () => {
    return [
        // @formatter:off
        takeLatest([AlertBoxTypes.SHOW_SUCCESS], scrollToTop),
        takeLatest([AlertBoxTypes.SHOW_ERROR],   scrollToTop),
        // @formatter:on
    ];
};
