//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import RedirectCheck             from '@/helper/RedirectCheck';
import { redirectWithDelay }     from '@/helper/Route';
import Route                     from '@/helper/Route';
import { MessageRequestActions } from '@/store/actions/messageRequest';
import ReactGA                   from 'react-ga4';
import ReactTooltip              from 'react-tooltip';
import { put }                   from 'redux-saga/effects';
import { select }                from 'redux-saga/effects';
import { push }                  from 'connected-react-router';
import Focus                     from '@/helper/Focus';
import Overlays                  from '@/constants/Overlays';
import { CompanyActions }        from '@/store/actions/company';
import { CompanyTypeActions }    from '@/store/actions/companyType';
import { SearchActions }         from '@/store/actions/search';
import { FilterActions }         from '@/store/actions/filter';
import _                         from 'lodash';
import { AlertBoxActions }       from '@/store/actions/alertBox';
import I18n                      from 'i18next';
import RouteHelper               from '@/helper/RouteHelper';
import { CookieBannerActions }   from '@/store/actions/cookieBanner';
import Routes                    from '@/constants/Routes';
import { MachineActions }        from '@/store/actions/machine';

const emailValidatedField = 'email_validated';
const cookiesField        = 'cookies';
const acceptCookiesField  = 'acceptCookies';

function* locationChange(action) {
    requestAnimationFrame(ReactTooltip.rebuild);

    const location        = yield select((state) => state.router.location);
    const cookiesAccepted = yield select((state) => state.cookieBanner.accepted);
    const currentRoute    = Route.getRouteAndOverlay(action.payload.location);
    const nextRoute       = location.pathname + location.search;
    const query           = location.query;

    console.log('Navigation: location change:', currentRoute, nextRoute);

    if (cookiesAccepted) {
        ReactGA.send('pageview');
    }

    if (Route.matchesPartRoute(Routes.companyMessage, nextRoute)) {
        const currentPage = yield select((state) => state.messageRequest.messageCurrentPage);

        if (Route.hasParameter(nextRoute, 'machine')) {
            yield put(MachineActions.resetMachine());
        }

        if (query.page !== currentPage) {
            yield put(MessageRequestActions.fetchMessageRequests());
        }
    }

    switch (currentRoute.overlay) {
        case Overlays.addMachine:
        case Overlays.addNewMachine:
            yield put(CompanyActions.editOwnCompany());
            yield put(SearchActions.clearSearchResults());

            break;
        case Overlays.editCompany:
            yield put(CompanyTypeActions.fetchCompanyTypes());
            yield put(CompanyActions.getOwnCompanyChildTags());

            break;
    }

    if (_.has(query, emailValidatedField)) {
        const emailValidated = _.get(query, emailValidatedField, false);

        yield* RouteHelper.removeUnsecureQueryParameters([
            emailValidatedField,
        ]);

        if (emailValidated) {
            yield put(AlertBoxActions.showSuccessAlert({
                text: I18n.t('emailValidationSuccessfully'),
            }));
        } else {
            yield put(AlertBoxActions.showErrorAlert({
                text: I18n.t('emailValidationFailed'),
            }));
        }
    }

    if (_.has(query, cookiesField)) {
        const showCookies = _.get(query, cookiesField, 'false') === 'true';

        yield* RouteHelper.removeUnsecureQueryParameters([
            cookiesField,
        ]);

        if (showCookies) {
            yield put(CookieBannerActions.resetCookies());
        }
    }

    if (_.has(query, acceptCookiesField)) {
        const acceptCookies = _.get(query, acceptCookiesField, 'false') === 'true';

        yield* RouteHelper.removeUnsecureQueryParameters([
            acceptCookiesField,
        ]);

        if (acceptCookies) {
            yield put(CookieBannerActions.acceptCookies());
        }
    }

    yield put(FilterActions.resetFilter());
    Focus.blurActiveElement();

    const { pathname } = location;
    const user         = yield select((state) => state.user);

    if (RedirectCheck.checkSessionRedirect(user, pathname)) {
        yield* redirectWithDelay(RedirectCheck.getSessionRedirect(pathname));
    }
}

function* openUrl(action) {
    yield put(push(action.url));
}

export default {
    locationChange,
    openUrl,
};
