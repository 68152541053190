//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import _               from 'lodash';
import I18n            from 'i18next';
import ColorBox        from '@/components/stateless/atomic/ColorBox';
import ColorBoxColor   from '@/components/stateless/atomic/ColorBox/ColorBoxColor';
import Facts           from '@/components/stateless/atomic/Facts';
import Map             from '@/components/connected/Map';
import PropTypes       from '@/components/PropTypes';
import CCPTranslations from '@/helper/CCPTranslations';

import styles from './styles.module.scss';

export class Component extends React.Component {
    getAddressString = () => {
        return _.compact([
            this.props.street,
            this.props.houseNumber,
        ]).join(' ');
    };

    render() {
        return (
            <div className={styles.addressBoxWrapper}>
                <ColorBox color={ColorBoxColor.grayLight}>
                    <Facts
                        headline={I18n.t('address')}
                        responsive={false}
                        facts={[
                            {
                                label: 'name',
                                value: this.props.companyName,
                            },
                            {
                                label: 'country',
                                value: CCPTranslations.countryString(this.props.country),
                            },
                            {
                                label: 'address',
                                value: this.getAddressString(),
                            },
                            {
                                label: 'city',
                                value: this.props.city,
                            },
                            {
                                label: 'state',
                                value: this.props.state,
                            },
                            {
                                label: 'postalCode',
                                value: this.props.postalCode,
                            },
                            {
                                label: 'phone',
                                value: this.props.companyPhone,
                            },
                        ]}
                    />
                    <div className={styles.addressBoxMapWrapper}>
                        <Map
                            geoLocation={this.props.geoLocation}
                            pinName={this.props.companyName}
                        />
                    </div>
                </ColorBox>
            </div>
        );
    }
}

export const AddressBox = Component;

Component.propTypes = {
    city:         PropTypes.string,
    companyName:  PropTypes.string,
    companyPhone: PropTypes.string,
    country:      PropTypes.string,
    geoLocation:  PropTypes.geoLocation,
    houseNumber:  PropTypes.houseNumber,
    postalCode:   PropTypes.postalCode,
    state:        PropTypes.string,
    street:       PropTypes.string,
};

Component.defaultProps = {
    city:         null,
    companyName:  null,
    companyPhone: null,
    country:      null,
    geoLocation:  null,
    houseNumber:  null,
    postalCode:   null,
    state:        null,
    street:       null,
};

export default Component;
