//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import _                      from 'lodash';
import I18n                   from 'i18next';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { connect }            from 'react-redux';

import appStyles          from '@/styles.module.scss';
import ComponentHelper    from '@/helper/ComponentHelper';
import Ids                from '@/constants/Ids';
import PropTypes          from '@/components/PropTypes';
import { ProfileActions } from '@/store/actions/profile';
import Routes             from '@/constants/Routes';
import AlertBoxManager    from '@/components/connected/AlertBoxManager';
import { UserActions }    from '@/store/actions/user';
import ProfileSideMenu    from '@/components/connected/ProfileSideMenu';
import { Spacer }         from '@/components/stateless/atomic/Spacer';
import { TagActions }     from '@/store/actions/tag';
import MyCompanySearchBox from '@/components/connected/MyCompanySearchBox';
import CreateCompanyBox   from '@/components/connected/CreateCompanyBox';
import State              from '@/helper/State';
import styles             from './styles.module.scss';

class Screen extends React.Component {
    componentDidMount() {
        this.props.fetch();
    }

    renderCreateCompany = () => {
        const { joinRequests } = this.props;

        if (!_.isEmpty(joinRequests)) {
            return null;
        }

        return (
            <>
                <Spacer height={55} />
                <h4>
                    {I18n.t('companyNotFound')}
                </h4>
                <CreateCompanyBox
                    joinRequests={joinRequests}
                />
            </>
        );
    };

    renderJoinCompany = () => {
        const { joinRequests } = this.props;

        return (
            <>
                <h3>
                    {I18n.t('noCompanyLinked')}
                </h3>
                <p>
                    {I18n.t('noCompanyLinkedInfoText')}
                </p>
                <Spacer height={25} />
                <MyCompanySearchBox
                    joinRequests={joinRequests}
                />
                {this.renderCreateCompany()}
            </>
        );
    };

    render() {
        return (
            <div className={appStyles.defaultContainer}>
                <div
                    className={appStyles.defaultInnerContainer}
                    id={Ids.contentContainer}
                >
                    <ProfileSideMenu
                        route={Routes.myProfileJoinCompany}
                        {...this.props}
                        openMyProfileProjects={this.props.openMyProfileProjects}
                        openMyProfileMessages={this.props.openMyProfileMessages}
                    />
                    <div className={appStyles.defaultContentContainer}>
                        <AlertBoxManager />
                        <div className={styles.myProfileJoinCompanyContainer}>
                            <div className={styles.myProfileJoinCompanyContent}>
                                {this.renderJoinCompany()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Screen.propTypes = {
    fetch:                  PropTypes.func,
    joinRequests:           PropTypes.array,
    openMyProfileMessages:  PropTypes.func,
    openMyProfileProjects:  PropTypes.func,
    profileFormDataChanged: PropTypes.func,
};

Screen.defaultProps = {
    fetch:                  _.noop,
    joinRequests:           [],
    openMyProfileMessages:  _.noop,
    openMyProfileProjects:  _.noop,
    profileFormDataChanged: _.noop,
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...UserActions,
        ...ProfileActions,
        ...TagActions,
    },
    dispatch,
);

const mapStateToProps = (state) => {
    const isUserWithoutCompany = _.isNil(State.getUserCompany(state));

    return {
        isUserWithoutCompany,
        joinRequests: _.get(state, 'user.joinRequests', []),
    };
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Screen);
