//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import update                  from 'immutability-helper';
import _                       from 'lodash';
import { MessageRequestTypes } from '@/store/actions/messageRequest';

const pageSize = 30;

const initialState = {
    attachments:            [],
    currentMessageRequest:  null,
    images:                 [],
    message:                null,
    messageRequests:        [],
    messageCurrentPage:     1,
    messagePages:           1,
    sendMessageText:        null,
    sendMessageAttachments: [],
    subject:                null,
    tags:                   [],
};

const editMessageRequestField = (action, state) => {
    const { field, value, index } = action;
    const pushOrDelete            = (
        value ?
            {
                $push: [
                    value,
                ],
            } :
            {
                $removeArrayItemAtIndex: index,
            }
    );
    const updateAction            = (
        !_.isNil(index) ?
            {
                ...pushOrDelete,
            } :
            {
                $set: value,
            }
    );

    return update(state, {
        [field]: {
            ...updateAction,
        },
    });
};

const reset = (action, state) => {
    return update(state, {
        $set: initialState,
    });
};

const fetchMessageRequestsSucceeded = (action, state) => {
    const {
        messageRequests,
        totalItems,
        page,
    } = action;

    return update(state, {
        messageRequests:    {
            $set: messageRequests,
        },
        messagePages:       {
            $set: Math.ceil(totalItems / pageSize),
        },
        messageCurrentPage: {
            $set: parseInt(page, 10),
        },
    });
};

const setSendMessageText = (action, state) => {
    const { text } = action;

    return update(state, {
        sendMessageText: {
            $set: text,
        },
    });
};

const setSendMessageAttachments = (action, state) => {
    const { attachments } = action;

    return update(state, {
        sendMessageAttachments: {
            $set: attachments,
        },
    });
};

const setCurrentMessageRequest = (action, state) => {
    const { iri } = action;

    return update(state, {
        currentMessageRequest: {
            $set: iri,
        },
    });
};

const sendMessageSucceeded = (action, state) => {
    return update(state, {
        sendMessageText:        {
            $set: initialState.sendMessageText,
        },
        sendMessageAttachments: {
            $set: initialState.sendMessageAttachments,
        },
    });
};

const addMessageRequestTag = (action, state) => {
    return update(state, {
        tags: {
            $push: [
                {
                    title: null,
                    iri:   null,
                },
            ],
        },
    });
};

const removeMessageRequestTag = (action, state) => {
    return update(state, {
        tags: {
            $removeArrayItemAtIndex: action.index,
        },
    });
};

const updateMessageRequestTag = (action, state) => {
    return update(state, {
        tags: {
            [action.index]: {
                $set: action.tag,
            },
        },
    });
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case MessageRequestTypes.EDIT_MESSAGE_REQUEST_FIELD:       return editMessageRequestField(action, state);
        case MessageRequestTypes.RESET:                            return reset(action, state);
        case MessageRequestTypes.FETCH_MESSAGE_REQUESTS_SUCCEEDED: return fetchMessageRequestsSucceeded(action, state);
        case MessageRequestTypes.SET_SEND_MESSAGE_TEXT:            return setSendMessageText(action, state);
        case MessageRequestTypes.SET_SEND_MESSAGE_ATTACHMENTS:     return setSendMessageAttachments(action, state);
        case MessageRequestTypes.SET_CURRENT_MESSAGE_REQUEST:      return setCurrentMessageRequest(action, state);
        case MessageRequestTypes.SEND_MESSAGE_SUCCEEDED:           return sendMessageSucceeded(action, state);
        case MessageRequestTypes.ADD_MESSAGE_REQUEST_TAG:          return addMessageRequestTag(action, state);
        case MessageRequestTypes.REMOVE_MESSAGE_REQUEST_TAG:       return removeMessageRequestTag(action, state);
        case MessageRequestTypes.UPDATE_MESSAGE_REQUEST_TAG:       return updateMessageRequestTag(action, state);
        default:                                                   return state;
        // @formatter:on
    }
}
