//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import _                      from 'lodash';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { connect }            from 'react-redux';
import appStyles              from '@/styles.module.scss';
import ComponentHelper        from '@/helper/ComponentHelper';
import Ids                    from '@/constants/Ids';
import PropTypes              from '@/components/PropTypes';
import { ProfileActions }     from '@/store/actions/profile';
import Routes                 from '@/constants/Routes';
import AlertBoxManager        from '@/components/connected/AlertBoxManager';
import { UserActions }        from '@/store/actions/user';
import ProfileSideMenu        from '@/components/connected/ProfileSideMenu';
import { TagActions }         from '@/store/actions/tag';

import State                   from '@/helper/State';
import I18n                    from 'i18next';
import { Spacer }              from '@/components/stateless/atomic/Spacer';
import IconType                from '@/components/stateless/atomic/Icon/IconType';
import { IconTextButton }      from '@/components/stateless/atomic/IconTextButton';
import * as Api                from '@/api';
import { Avatar }              from '@/components/stateless/atomic/Avatar';
import { CountryFlag }         from '@/components/stateless/atomic/CountryFlag';
import { InformationBox }      from '@/components/stateless/composed/InformationBox';
import InformationBoxDirection from '@/components/stateless/composed/InformationBox/InformationBoxDirection';
import { CompanyActions }      from '@/store/actions/company';
import styles                  from './styles.module.scss';

class Screen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showConfirmProfileDelete: false,
        };

        this.props.loadInitialProfileData();
    }

    renderCurrentRoleContent = () => {
        const roleInfoCurrentRoleText = `${I18n.t('currentRole')}: `;
        const roleInfoRoleText        = this.props.isUserWithoutCompany ?
            I18n.t('currentRoleBuyer') :
            I18n.t('currentRoleSupplier');
        const userName                = _.get(this.props, 'user.name', null);
        const userImagePath           = Api.getImagePath(_.get(this.props, 'user.image.path'));
        const userPosition            = _.get(this.props, 'user.position', null);
        const userCountry             = _.get(this.props, 'user.country', null);

        return (
            <>
                <h3>
                    {I18n.t('yourCurrentRole')}
                </h3>
                <Spacer height={25} />
                <div className={styles.currentRoleContainer}>
                    <div className={styles.infoContainer}>
                        <div className={styles.roleInfoContainer}>
                            <span>
                                {roleInfoCurrentRoleText}
                            </span>
                            <span>
                                {roleInfoRoleText}
                            </span>
                        </div>
                        <div className={styles.userInfoContainer}>
                            <div className={styles.avatarWrapper}>
                                <Avatar
                                    image={userImagePath}
                                    fallback={userName}
                                />
                            </div>
                            <div className={styles.userWrapper}>
                                <div>
                                    {userName}
                                    <CountryFlag
                                        width={20}
                                        iso31661Alpha2CountryCode={userCountry}
                                    />
                                </div>
                                <div>
                                    {userPosition}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.currentRoleActionContainer}>
                        <IconTextButton
                            iconType={IconType.pencil}
                            onClick={this.onChangeRoleClicked(false)}
                            text={I18n.t('changeRole')}
                        />
                        <IconTextButton
                            iconType={IconType.cross}
                            onClick={this.toggleConfirmCompanyDelete(true)}
                            text={I18n.t('deleteProfile')}
                        />
                    </div>
                </div>

            </>
        );
    };

    toggleConfirmCompanyDelete = (showConfirmProfileDelete) => () => {
        this.setState({
            showConfirmProfileDelete,
        });
    };

    onChangeRoleClicked = (confirmCompanyDelete = false) => () => {
        const { isUserWithoutCompany, history, companyId } = this.props;

        if (isUserWithoutCompany) {
            history.push(Routes.myProfileJoinCompany);
            return;
        }

        this.props.leaveCompany({
            companyId,
            confirmCompanyDelete,
        });
    };

    onDeleteProfileClicked = () => {
        this.props.deleteProfile();
    };

    onCancelChangeRoleLastSupplierClick = () => {
        this.props.confirmCompanyDelete({
            confirmCompanyDelete: false,
        });
    };

    renderDeleteProfileInfoBox = () => {
        if (!this.state.showConfirmProfileDelete) {
            return null;
        }

        return (
            <InformationBox
                headline={I18n.t('attention')}
                subline={I18n.t('deleteProfileText')}
                buttonIcon={null}
                buttonText={I18n.t('deleteProfile')}
                onClick={this.onDeleteProfileClicked}
                direction={InformationBoxDirection.column}
                onCancelClick={this.toggleConfirmCompanyDelete(false)}
                cancelButtonText={I18n.t('cancel')}
            />
        );
    };

    renderChangeRoleLastSupplierInfoBox = () => {
        if (!this.props.showConfirmCompanyDelete) {
            return null;
        }

        return (
            <InformationBox
                headline={I18n.t('attention')}
                subline={I18n.t('deleteSupplierProfileText')}
                buttonIcon={null}
                buttonText={I18n.t('deleteSupplierProfile')}
                onClick={this.onChangeRoleClicked(true)}
                direction={InformationBoxDirection.column}
                onCancelClick={this.onCancelChangeRoleLastSupplierClick}
                cancelButtonText={I18n.t('cancel')}
            />
        );
    };

    render() {
        return (
            <div className={appStyles.defaultContainer}>
                <div
                    className={appStyles.defaultInnerContainer}
                    id={Ids.contentContainer}
                >
                    <ProfileSideMenu
                        route={Routes.myProfileCurrentRole}
                        {...this.props}
                        openMyProfileProjects={this.props.openMyProfileProjects}
                        openMyProfileMessages={this.props.openMyProfileMessages}
                    />
                    <div className={appStyles.defaultContentContainer}>
                        <AlertBoxManager />
                        <div className={styles.myProfileCurrentRoleContainer}>
                            <div className={styles.myProfileCurrentRoleContent}>
                                {this.renderCurrentRoleContent()}
                            </div>
                        </div>
                        {this.renderChangeRoleLastSupplierInfoBox()}
                        {this.renderDeleteProfileInfoBox()}
                    </div>
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(this, Screen, nextProps, nextState);
    }
}

Screen.propTypes = {
    isUserWithoutCompany:     PropTypes.bool,
    loadInitialProfileData:   PropTypes.func,
    openMyProfileMessages:    PropTypes.func,
    openMyProfileProjects:    PropTypes.func,
    user:                     PropTypes.object,
    showConfirmCompanyDelete: PropTypes.bool,
    companyId:                PropTypes.number,
    confirmCompanyDelete:     PropTypes.func,
    leaveCompany:             PropTypes.func,
    history:                  PropTypes.object,
    deleteProfile:            PropTypes.func,
};

Screen.defaultProps = {
    isUserWithoutCompany:     false,
    loadInitialProfileData:   _.noop,
    openMyProfileMessages:    _.noop,
    openMyProfileProjects:    _.noop,
    user:                     null,
    showConfirmCompanyDelete: false,
    companyId:                null,
    confirmCompanyDelete:     _.noop,
    leaveCompany:             _.noop,
    history:                  {},
    deleteProfile:            _.noop,
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [
    'showConfirmProfileDelete',
];

const mapDispatchToProps = (dispatch) => bindActionCreators({
    ...UserActions,
    ...CompanyActions,
    ...ProfileActions,
    ...TagActions,
}, dispatch);

const mapStateToProps = (state) => {
    const user                     = _.get(state, 'user');
    const isUserWithoutCompany     = _.isNil(State.getUserCompany(state));
    const companyId                = State.getUserCompanyId(state);
    const showConfirmCompanyDelete = _.get(state, 'company.confirmCompanyDelete', false);

    return {
        user,
        isUserWithoutCompany,
        companyId,
        showConfirmCompanyDelete,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Screen);
