//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { create } from 'apisauce';
import _          from 'lodash';

import ApiConfiguration  from '@/constants/Api';
import ApiUrls           from '@/constants/ApiUrls';
import Hydra             from '@/helper/Hydra';
import ProjectsContext   from '@/api/context/projects';
import ProfileContext    from '@/api/context/profile';
import CompanyContext    from '@/api/context/company';
import MachineContext    from '@/api/context/machine';
import MembershipContext from '@/api/context/membership';
import PartnerContext    from '@/api/context/partner';

const contentTypes = {
    applicationJSON:           'application/json',
    applicationLDJSON:         'application/ld+json',
    applicationMergePatchJSON: 'application/merge-patch+json',
};

const getBaseUrlWithUrl = (baseUrl, url = '') => {
    let urlSuffix = url;

    if (baseUrl.endsWith('/') && urlSuffix.startsWith('/')) {
        urlSuffix = urlSuffix.substring(1);
    }

    return baseUrl + urlSuffix;
};

const createInstance = (host, apiPath) => {
    ApiConfiguration.checkBackendUrl(host);

    const api = create({
        baseURL: `${host}${apiPath}`,
        timeout: 30000,
        headers: {
            'Content-Type': contentTypes.applicationJSON,
            'accept':       contentTypes.applicationLDJSON,
        },
    });

    api.host   = host;
    api.getUrl = (url = '', baseUrl = api.getBaseURL()) => (
        getBaseUrlWithUrl(baseUrl, url)
    );

    api.addResponseTransform((response) => {
        if (response.ok) {
            response.data = Hydra.cleanupObject(response.data);
        }

        return response;
    });

    return api;
};

const Api = createInstance(ApiConfiguration.getBackendUrl(), '/');

const getUrl = (url = '') => (
    getBaseUrlWithUrl(Api.getBaseURL(), url)
);

const getFilePath = (path, fallback = null) => {
    if (path) {
        if (
            path.indexOf('https://') === -1 &&
            path.indexOf('http://') === -1
        ) {
            return getUrl(path);
        }

        return path;
    }

    return fallback;
};

const getImagePath = (path) => {
    if (
        _.isObject(path) &&
        _.has(path, 'path')
    ) {
        return getFilePath(path.path);
    }

    if (path) {
        return getFilePath(path);
    }

    return null;
};

const getImagePathByImageObject = (image) => {
    const imagePath = _.get(image, 'path');

    return getImagePath(imagePath);
};

const createMachine = (machine) => {
    return Api.post(
        ApiUrls.API_MACHINES_URL,
        machine,
    );
};

const deleteCompetenceTag = (competenceTagIri) => {
    return Api.delete(ApiConfiguration.getBackendUrl() + competenceTagIri);
};

const deleteInterestTag = (interestTagIri) => {
    return Api.delete(ApiConfiguration.getBackendUrl() + interestTagIri);
};

const fetchCompany = (companyId) => {
    return Api.get(`${ApiUrls.API_COMPANY_URL}/${companyId}`);
};

const setCompanyAsActive = (companyId) => {
    return Api.put(
        `${ApiUrls.API_COMPANY_URL}/${companyId}`,
        {
            daysSinceLastUpdate: 0,
        },
    );
};

const joinCompany = (companyId) => {
    return Api.get(`${ApiUrls.API_COMPANY_URL}/${companyId}/join`);
};

const leaveCompany = (companyId, confirmCompanyDelete = false) => {
    const queryParameter = confirmCompanyDelete ?
        '?confirmCompanyDelete=true' :
        '';

    return Api.get(`${ApiUrls.API_COMPANY_URL}/${companyId}/leave${queryParameter}`);
};

const fetchJoinRequests = (userIri) => {
    return Api.get(`${userIri}/join-requests`);
};

const fetchUser = (userIri) => {
    return Api.get(userIri);
};

const deleteProfile = (userIri) => {
    return Api.delete(userIri);
};

const createUserVerification = (userVerification) => {
    return Api.post(
        ApiUrls.API_USER_VERIFICATION,
        userVerification,
    );
};

const acceptJoinRequest = (companyIri, userId) => {
    return Api.get(`${companyIri}/join/accept/${userId}`);
};

const declineJoinRequest = (companyIri, userId) => {
    return Api.get(`${companyIri}/join/decline/${userId}`);
};

const createCompany = (company) => {
    return Api.post(
        ApiUrls.API_COMPANY_URL,
        company,
    );
};

const fetchCompanyTypes = () => {
    return Api.get(ApiUrls.API_COMPANY_TYPE_URL);
};

const fetchMachineByCompany = (companyId) => {
    return Api.get(
        ApiUrls.API_MACHINES_URL,
        {
            'companies.id': companyId,
        },
    );
};

const fetchMachine = (machineId) => {
    return Api.get(`${ApiUrls.API_MACHINES_URL}/${machineId}`);
};

const fetchTag = (id) => {
    return Api.get(`${ApiUrls.API_TAG_URL}/${id}`);
};

const fetchTags = (parameters) => {
    return Api.get(
        ApiUrls.API_TAG_URL,
        parameters,
    );
};

const fetchTagsPreview = (parameters) => {
    return Api.get(
        ApiUrls.API_TAG_PREVIEW_URL,
        parameters,
    );
};

const fetchRootTags = () => {
    return Api.get(ApiUrls.API_ROOT_TAGS_URL);
};

const fetchIndustries = () => {
    return Api.get(ApiUrls.API_INDUSTRIES_URL);
};

const fetchMessageRequests = (page = 1) => {
    return Api.get(`${ApiUrls.API_MESSAGE_REQUEST_URL}?page=${page}`);
};

const markMessagesAsRead = (messageRequestId) => {
    const url = `${ApiUrls.API_MESSAGES_READ_URL}/${messageRequestId}`;

    return Api.post(url);
};

const login = (
    identifier,
    password,
) => {
    return Api.post(
        ApiUrls.API_TOKENS,
        {
            email: identifier,
            password,
        },
    );
};

const patchUser = (
    iri,
    user,
) => {
    return Api.patch(
        iri,
        user,
        {
            headers: {
                'Content-Type': contentTypes.applicationMergePatchJSON,
            },
        },
    );
};

const requestPassword = (identifier) => {
    return Api.post(
        ApiUrls.API_RESET_PASSWORD,
        {
            email: identifier,
        },
    );
};

const saveProduct = (data) => {
    const iri    = _.get(data, 'iri');
    const url    = iri ?? ApiUrls.API_PORTFOLIO_PRODUCTS_URL;
    const method = (
        iri ?
            Api.put :
            Api.post
    );

    return method(
        url,
        data,
    );
};

const fetchProducts = (parameters) => {
    return Api.get(
        ApiUrls.API_PORTFOLIO_PRODUCTS_URL,
        parameters,
    );
};

const deleteProduct = (iri) => {
    return Api.delete(iri);
};

const deleteMachine = (iri) => {
    return Api.delete(iri);
};

const saveMessageRequest = (data) => {
    const iri    = _.get(data, 'iri');
    const url    = iri ?? ApiUrls.API_MESSAGE_REQUEST_URL;
    const method = (
        iri ?
            Api.put :
            Api.post
    );

    return method(
        url,
        data,
    );
};

const saveMessage = (data) => {
    return Api.post(
        ApiUrls.API_MESSAGE_URL,
        data,
    );
};

const setNewPassword = (
    token,
    password,
) => {
    return Api.post(
        ApiUrls.API_SET_NEW_PASSWORD,
        {
            token,
            password,
        },
    );
};

const signup = (signupData) => {
    const company   = _.get(signupData, 'company');
    const signupUrl = (
        company ?
            ApiUrls.API_USERS_CLAIM_COMPANY(company) :
            ApiUrls.API_USERS
    );

    return Api.post(
        signupUrl,
        signupData,
    );
};

const postCompetenceTag = (tag, company) => {
    return Api.post(
        ApiUrls.API_TAG_COMPETENCES_URL,
        {
            tag,
            company,
        },
    );
};

const postInterestTag = (tag, company) => {
    return Api.post(
        ApiUrls.API_TAG_INTERESTS_URL,
        {
            tag,
            company,
        },
    );
};

const postMachineParameter = (machine, name, value) => {
    return Api.post(
        `${ApiUrls.API_MACHINE_PARAMETERS_URL}?context=create`,
        {
            machine,
            name,
            value,
        },
    );
};

const removeToken = () => {
    delete Api.headers.Authorization;
};

const getAuth = () => {
    return Api.headers.Authorization;
};

const search = (query, indices, parameters = [], flags = []) => {
    return Api.get(
        ApiUrls.API_QUERY_URL,
        {
            query,
            indices,
            limit: 10,
            ...parameters,
            ...flags,
        },
    );
};

const searchByTags = (tags, paging) => {
    return Api.post(
        ApiUrls.API_QUERY_BY_TAGS_URL,
        {
            tags,
            paging,
        },
    );
};

const setToken = (token) => {
    console.log('API: setToken', token);

    if (token) {
        const authorization = `Bearer ${token}`;

        Api.setHeader('Authorization', authorization);
    } else {
        removeToken();
    }
};

const updateCompany = (companyIri, data) => {
    return Api.put(
        companyIri,
        data,
    );
};

const patchCompany = (companyIri, data) => {
    return Api.patch(
        companyIri,
        data,
        {
            headers: {
                'Content-Type': contentTypes.applicationMergePatchJSON,
            },
        },
    );
};

const updateFactSheet = (factSheetIri, data) => {
    if (!factSheetIri) {
        return Api.post(
            ApiUrls.API_COMPANY_FACT_SHEET_URL,
            data,
        );
    }

    return Api.put(
        factSheetIri,
        data,
    );
};

const updateMachineCapacity = (data) => {
    const id = _.get(data, 'id');

    if (!id) {
        return Api.post(
            ApiUrls.API_MACHINE_CAPACITY_URL,
            data,
        );
    }

    return Api.put(
        `${ApiUrls.API_MACHINE_CAPACITY_URL}/${id}`,
        data,
    );
};

const updateMachine = (machineIri, machine) => {
    return Api.put(
        machineIri,
        machine,
    );
};

export {
    Api,
    acceptJoinRequest,
    createCompany,
    createMachine,
    createUserVerification,
    declineJoinRequest,
    deleteCompetenceTag,
    deleteInterestTag,
    deleteProduct,
    deleteMachine,
    deleteProfile,
    fetchUser,
    fetchCompany,
    fetchCompanyTypes,
    fetchMachine,
    fetchMachineByCompany,
    fetchIndustries,
    fetchJoinRequests,
    fetchMessageRequests,
    fetchProducts,
    fetchTag,
    fetchTags,
    fetchTagsPreview,
    fetchRootTags,
    getFilePath,
    getImagePath,
    getImagePathByImageObject,
    joinCompany,
    leaveCompany,
    login,
    patchUser,
    postCompetenceTag,
    postInterestTag,
    postMachineParameter,
    removeToken,
    requestPassword,
    saveMessage,
    saveMessageRequest,
    saveProduct,
    search,
    searchByTags,
    setCompanyAsActive,
    setNewPassword,
    setToken,
    getAuth,
    getUrl,
    signup,
    markMessagesAsRead,
    updateCompany,
    updateMachine,
    patchCompany,
    updateFactSheet,
    updateMachineCapacity,
};

export const context = {
    company:    CompanyContext(Api),
    membership: MembershipContext(Api),
    machine:    MachineContext(Api),
    partner:    PartnerContext(Api),
    profile:    ProfileContext(Api),
    projects:   ProjectsContext(Api),
};
