//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                    from 'react';
import _                        from 'lodash';
import { bindActionCreators }   from 'redux';
import { connect }              from 'react-redux';
import * as Api                 from '@/api';
import ComponentHelper          from '@/helper/ComponentHelper';
import PropTypes                from '@/components/PropTypes';
import Route                    from '@/helper/Route';
import Routes                   from '@/constants/Routes';
import StartPageSearchMode      from '@/components/stateless/composed/StartPageSearch/StartPageSearchMode';
import StatelessStartPageSearch from '@/components/stateless/composed/StartPageSearch';
import { Avatar }               from '@/components/stateless/atomic/Avatar';
import { CompanySearchResult }  from '@/components/stateless/composed/CompanySearchResult';
import { CompanyTypes }         from '@/store/actions/company';
import { Link }                 from '@/components/stateless/atomic/Link';
import { MachineSearchResult }  from '@/components/stateless/composed/MachineSearchResult';
import { NavigationActions }    from '@/store/actions/navigation';
import { NoSearchResults }      from '@/components/stateless/composed/NoSearchResults';
import { SearchActions }        from '@/store/actions/search';
import { TagSearchResult }      from '@/components/stateless/composed/TagSearchResult';

export class Component extends React.Component {
    constructor(props) {
        super(props);

        if (!props.hasHeaderParent) {
            this.props.clearSearchResults();
        }
    }

    render() {
        return (
            <StatelessStartPageSearch
                clearSearchResultsAction={this.props.clearSearchResults}
                hasHeaderParent={this.props.hasHeaderParent}
                isLoading={this.props.isLoading}
                mode={this.props.searchMode}
                addTagQuery={this.props.addTagQuery}
                onAddSibling={this.props.addNextSibling}
                onAddNextChild={this.props.addNextChild}
                onTagChange={this.props.changeTag}
                onTagDelete={this.props.deleteTag}
                onTextChange={this.search}
                possibleResultsCount={this.props.possibleResultsCount}
                query={this.props.query}
                headerInputFocused={this.props.headerInputFocused}
                resultList={this.renderSearchResults()}
                tagQuery={this.props.tagQuery}
                rootTags={this.props.rootTags}
                openUrl={this.props.openUrl}
                allowInitialTagSearch={this.props.allowInitialTagSearch}
            />
        );
    }

    getCompanyType(/* company */) {
        // const types = company.types;

        // TODO: https://lulububu.atlassian.net/browse/SUPPLYDU-227
        return CompanyTypes.serviceProvider;
    }

    getCompanyImage(company) {
        const logoPath    = _.get(company, 'logo.path', null);
        const companyName = _.get(company, 'name', null);

        return (
            <Avatar
                image={logoPath}
                fallback={companyName}
            />
        );
    }

    tagClicked(tag) {
        return () => {
            this.props.addTagQuery({
                tag,
            });
            this.props.changeSearchMode({
                mode: StartPageSearchMode.tagQueryTree,
            });
            this.props.clearQuery();
        };
    }

    renderSearchResultCallback = (query, resultGroup) => {
        /* eslint-disable react/display-name */
        return (searchResult) => {
            switch (resultGroup) {
                case 'tags':
                    return (
                        <TagSearchResult
                            key={searchResult.tagChain}
                            onClick={this.tagClicked(searchResult)}
                            tagChain={searchResult.tagChain}
                            highlightText={query}
                        />
                    );

                case 'machines':
                    return (
                        <Link
                            key={searchResult.name}
                            to={Route.buildRoute(Routes.machine, searchResult.id)}
                        >
                            <MachineSearchResult
                                machineName={_.get(searchResult, 'name')}
                                machineImage={_.get(searchResult, 'image.path')}
                            />
                        </Link>
                    );

                case 'companies':
                    return (
                        <Link
                            key={searchResult.name}
                            to={Route.buildRouteForCompany(searchResult)}
                        >
                            <CompanySearchResult
                                companyName={searchResult.name}
                                companyImage={Api.getImagePath(_.get(searchResult, 'logo.path', null))}
                                companyTypes={searchResult.types}
                                highlightText={query}
                            />
                        </Link>
                    );

                default:
                    return null;
            }
        };
        /* eslint-enable react/display-name */
    };

    renderSearchResults() {
        const { name }      = this.props;
        const items         = _.get(this, 'props.items', {});
        const query         = _.get(this.props, 'query');
        const isLoading     = _.get(this.props, 'isLoading', true);
        const resultGroups  = Object.keys(items);
        const searchResults = _.flatten(_.map(
            resultGroups,
            (resultGroup) => _.map(
                _.get(items, resultGroup, []),
                this.renderSearchResultCallback(query, resultGroup),
            ),
        ));

        if (
            !isLoading &&
            query &&
            query.length > 2 &&
            _.isEmpty(searchResults)
        ) {
            return (
                [
                    <NoSearchResults
                        key={'no-results-found'}
                        name={name}
                        keyword={query}
                    />,
                ]
            );
        }

        return searchResults;
    }

    search = (event) => {
        this.props.search({
            query: event.target.value,
        });
        this.props.changeSearchMode({
            mode: StartPageSearchMode.resultList,
        });
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const StartPageSearch = Component;

Component.propTypes = {
    addNextChild:              PropTypes.func,
    addNextSibling:            PropTypes.func,
    addTag:                    PropTypes.func,
    addTagQuery:               PropTypes.func,
    changeSearchMode:          PropTypes.func,
    changeTag:                 PropTypes.func,
    clearQuery:                PropTypes.func,
    clearSearchResults:        PropTypes.func,
    deleteTag:                 PropTypes.func,
    hasHeaderParent:           PropTypes.bool,
    headerInputFocused:        PropTypes.bool,
    isLoading:                 PropTypes.bool,
    items:                     PropTypes.object,
    name:                      PropTypes.string,
    openUrl:                   PropTypes.func,
    otherSearch:               PropTypes.bool,
    possibleResultsCount:      PropTypes.number,
    query:                     PropTypes.string,
    results:                   PropTypes.object,
    rootTags:                  PropTypes.array,
    search:                    PropTypes.func,
    searchMode:                PropTypes.string,
    tagQuery:                  PropTypes.array,
    tagQueryAsList:            PropTypes.array,
    totalItemsWithoutMachines: PropTypes.number,
    allowInitialTagSearch:     PropTypes.bool,
};

Component.defaultProps = {
    addNextChild:              _.noop,
    addNextSibling:            _.noop,
    addTag:                    _.noop,
    addTagQuery:               _.noop,
    changeSearchMode:          _.noop,
    changeTag:                 _.noop,
    clearQuery:                _.noop,
    clearSearchResults:        _.noop,
    deleteTag:                 _.noop,
    hasHeaderParent:           false,
    headerInputFocused:        false,
    isLoading:                 false,
    items:                     {},
    name:                      null,
    openUrl:                   _.noop,
    otherSearch:               false,
    possibleResultsCount:      0,
    query:                     null,
    results:                   {},
    rootTags:                  [],
    search:                    _.noop,
    searchMode:                null,
    tagQuery:                  [],
    tagQueryAsList:            [],
    totalItemsWithoutMachines: 0,
    allowInitialTagSearch:     false,
};

Component.renderAffectingProps = _.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = (state) => (
    {
        isLoading:            !!_.get(state, 'search.isLoading', false),
        items:                _.get(state, 'search.items', {}),
        otherSearch:          _.get(state, 'search.otherSearch', false),
        possibleResultsCount: _.get(state, 'search.totalItemsWithoutMachines', 0),
        query:                _.get(state, 'search.query', null),
        results:              _.get(state, 'search.resultItems', []),
        searchMode:           _.get(state, 'search.searchMode', StartPageSearchMode.resultList),
        tagQuery:             _.get(state, 'search.tagQuery', {}),
        tagQueryAsList:       _.get(state, 'search.tagQueryAsList', []),
        rootTags:             _.get(state, 'tag.rootTags', []),
        name:                 _.get(state, 'user.name'),
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators({
    ...SearchActions,
    ...NavigationActions,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
