// _    _   _     _
// | | _ _| |_ _| |__ _ _| |__ _ _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const english = {
    about:                                       'About',
    aboutUs:                                     'About us',
    accept:                                      'Accept',
    acceptedJoinRequest:                         'Accepted join request',
    acceptedProjects:                            'Accepted Projects',
    acceptingJoinRequestFailed:                  'Accepting join request failed',
    actingPrivate:                               'Registered as single user',
    actingPrivateFailed:                         'Creation of single user profile failed',
    actingPrivateNow:                            'You are now registered as single user',
    actions:                                     'Actions',
    actPrivate:                                  'Create single user profile',
    adChoices:                                   'Ad choices',
    addAttachment:                               'Add attachment',
    addMachine:                                  'Find or create',
    addMachineText:                              'Here you can manage your machines',
    addMachineTitle:                             'Add machine',
    addNewMachine:                               'Add new machine',
    addNewMachineText:                           'Here you can manage your production portfolio',
    addNewMachineTitle:                          'Add new machine',
    addNewProduct:                               'Add new product',
    addNewProductText:                           'Here you can manage your product portfolio',
    addNewProductTitle:                          'Add new Product',
    addNewProject:                               'Add new Project',
    addNewProjectInfoText:                       'You have not created any project. Create your first project now!',
    addPhoto:                                    'Add photo',
    address:                                     'Address',
    addTag:                                      'Add competence',
    alertBoxErrorText:                           'Error',
    alertBoxSuccessText:                         'Success',
    all:                                         'All',
    alreadyActsPrivate:                          'You are already registered as singled user',
    alreadyRegistered:                           'Already registered?',
    areYouTheManufacturer:                       'Are you the manufacturer of this machine?',
    attachedFiles:                               'Attached files',
    attachedImages:                              'Attached images',
    attachments:                                 'Attachments',
    attention:                                   'Attention',
    back:                                        'Back',
    belowTagText:                                'Please specify a <strong>\'{{tag}}\'</strong> category',
    bestMatch:                                   'Best Match',
    businessServices:                            'Business Services',
    cacheInvalidation:                           'Attention',
    cacheInvalidationText:                       'It takes a couple of minutes until your changes are visible in the search results.',
    cancel:                                      'Cancel',
    capacities:                                  'Capacities',
    capacity:                                    'Capacity',
    category:                                    'Category',
    categoryMachineHelpText:                     'If you want to create a new category for your machines, enter the new name here. \nIf you want to assign a machine to an existing category, enter the category name in this field.',
    categoryProductHelpText:                     'If you want to create a new category for your products, enter the new name here. \nIf you want to assign a product to an existing category, enter the category name in this field.',
    certificateFileMemberHint:                   'When you are a member or a trusted member, you can upload a file with an expiration date as a proof of certification. Please upgrade your membership to upload a certificate.',
    certificateName:                             'SUpplyDU_{{companyName}}_Certificate.{{fileExtension}}',
    certificates:                                'Certificates',
    changedRoleFailed:                           'Your role could not be changed. Please try again later.',
    changedRoleSuccess:                          'Your role was changed successfully',
    changeRole:                                  'Change Role',
    chat:                                        'Chat',
    checkActPrivateCompanyButton:                'Part/Equipment manufacturer',
    checkActPrivateCustomerButton:               'Customer/Purchaser',
    checkActPrivateText:                         'Would you like to create a company profile or just get in touch with other companies as a customer?',
    checkActPrivateTitle:                        'Are you a company or private person/customer?',
    city:                                        'City',
    claim:                                       'Claim',
    claimOwnership:                              'Claim Ownership',
    clearButtonAction:                           'Clear',
    clickHere:                                   'Click here',
    close:                                       'Close',
    CLOSED:                                      'Closed',
    closed:                                      'Closed',
    closedProjects:                              'Closed Projects',
    closeProject:                                'Close Project',
    commodities:                                 'Commodities',
    companies:                                   'Companies',
    companiesOwningMachine:                      'Companies owning this machine type',
    company:                                     'Company',
    companyDescriptionPlaceholder:               'Please describe your company in a few sentences.',
    companyMachine:                              'Production machine',
    companyMachineCompanyName:                   'Production machine - {{companyName}}',
    companyMessage:                              'Message',
    companyName:                                 'Company name',
    companyNameRequired:                         'Please enter a company name',
    companyNotFound:                             'Do you want to create a new company profile?',
    companyOverview:                             'Company overview',
    companyPositions:                            {
        engineering:                'Engineering',
        logistics:                  'Logistics',
        others:                     'Others',
        processEngineering:         'Process Engineering',
        purchasingProcurement:      'Purchasing/Procurement',
        qualitySupplierDevelopment: 'Quality/Supplier Development',
    },
    companyProduction:                           'Product portfolio',
    companyProfile:                              'Company profile',
    companyProfileCompanyName:                   '{{companyName}}',
    companyProfileDescriptionTitle:              'About {{companyName}}',
    companyProfileMessage:                       'Message {{companyName}}',
    companySetAsActiveFailed:                    'Your company could not be set as active',
    companySetAsActiveSuccess:                   'Your company was set as active again',
    companyTypeMachineManufacturer:              'Machine manufacturer',
    companyTypePlaceholder:                      'Please select a company type',
    companyTypeServiceProvider:                  'Service provider',
    companyTypeSupplier:                         'Supplier',
    companyTypeUnknown:                          'Unknown type',
    companyUpdateRequiredMessage:                'Please confirm that your company is still active.',
    companyUpdateRequiredTitle:                  'Confirm company still active',
    companyUploadPresentation:                   'Upload company presentation',
    companyUploadVideo:                          'Upload company video',
    companyUploadVideoAndPresentationWarning:    'The video and presentation in your company profile will only be publicly visible if you are a member or a trusted member. Please upgrade your membership in order to display your video and presentation on your company profile to everyone.',
    competences:                                 'Competences',
    competencesSelectorPlaceholder:              'Press the add icon to search for competences...',
    completionAddress:                           'Address',
    completionCompanyDescription:                'Company description',
    completionCompanyLogo:                       'Company logo',
    completionCompetences:                       'Competences',
    completionCompletedFactsheet:                'Completed Factsheet',
    completionHeadline:                          'Complete your profile.',
    completionIndustries:                        'Industries',
    completionInterests:                         'Interests',
    completionMinProductImages:                  'min. {{amount}} Product-images',
    completionProductionMachines:                'Production machines',
    completionText:                              'You can improve your visibility on SUpplyDU.com by completing each profile item.',
    confirm:                                     'Confirm',
    contact:                                     'Contact',
    continue:                                    'Continue',
    cookieBanner:                                {
        buttonAccept: 'Accept all',
        buttonReject: 'Reject',
        text:         'We use cookies on our website. Some of them are necessary, while others help us to improve our online offer and to operate it economically. You can agree to the use of non-essential cookies by clicking on the "Accept all" button or decide otherwise by clicking on "Reject". The consent includes all preselected cookies or those selected by you. You can call up these settings at any time and also subsequently deselect cookies at any time (in the data protection declaration and in the footer of our website).\n\n \n\n<bold>Note on the processing of your data collected on this website in the USA by AWS-CloudFront, Facebook Pixel, Google Analytics 4, Bing Maps, Google Maps and Elasticsearch:</bold> By clicking "Accept all", you agree At the same time, pursuant to Article 49 (1) sentence 1 lit. a GDPR, you agree that your data will be processed in the USA. The USA is rated by the European Court of Justice as a country with an insufficient level of data protection according to EU standards. In particular, there is a risk that your data may be processed by US authorities for control and monitoring purposes, possibly without the possibility of legal remedies. If you click on "Reject", the transmission described above will not take place.\n\n \n\nIf you are under the age of 16 and wish to give your consent to volunteer services, you must ask your legal guardian for permission. We use cookies and other technologies on our website. Some of them are essential, while others help us to improve this website and your experience. Personal data can be processed (e.g. IP addresses), e.g. B. for personalized ads and content or ad and content measurement. You can find more information about the use of your data in our data <privacyLink>protection declaration</privacyLink>. You can revoke or adjust your selection at any time under <settingsLink>Settings</settingsLink>.',
        title:        'Information on the use of cookies',
    },
    cookieBannerRequired:                        'This feature requires cookies. Accept them to use this feature.',
    countDownDay:                                'day',
    countDownDays:                               'days',
    countDownHour:                               'hour',
    countDownHours:                              'hours',
    countDownMinute:                             'minute',
    countDownMinutes:                            'minutes',
    countDownSecond:                             'second',
    countDownSeconds:                            'seconds',
    country:                                     'Country',
    countryOfDelivery:                           'Country of delivery',
    createCompany:                               'Create company',
    createCompanySucceeded:                      'The company was successfully created',
    createCompanyText:                           'You can also act as private person if you are not allowed to create a official company profile. We will only display your position in your company without creating a company profile at all.',
    createMachineFailed:                         'The machine profile could not be created',
    createMachineSuccess:                        'The machine profile was successfully created',
    createMachineTitle:                          'Create a machine profile',
    createProductTitle:                          'Create a product',
    createProject:                               'Create a project',
    currentPassword:                             'Current password',
    currentRole:                                 'Current Role',
    currentRoleBuyer:                            'Buyer',
    currentRoleSupplier:                         'Supplier',
    dangerZone:                                  'Danger zone',
    dataProtection:                              'Privacy',
    dateFormat:                                  'DD.MM.YYYY',
    decline:                                     'Decline',
    declined:                                    'Declined',
    declinedJoinRequest:                         'Declined join request',
    declineProject:                              'Decline project',
    decliningJoinRequestFailed:                  'Declining join request failed',
    delete:                                      'Delete',
    deleteCompany:                               'Delete company',
    deleteCompanyMachineSucceeded:               'The machine profile was successfully deleted',
    deleteCompanyText:                           'Are you sure you want to delete your company \'{{companyName}}\'? This action cannot be undone.',
    deleteCompanyTitle:                          'Delete company',
    deleteImage:                                 'Delete image',
    deleteMachineText:                           'Are you sure you really want to delete <strong>{{machineName}}</strong>?.',
    deleteMachineTitle:                          'Delete machine',
    deleteProductFailed:                         'This product could not be deleted.',
    deleteProductSucceeded:                      'The product was successfully deleted.',
    deleteProfile:                               'Delete Profile',
    deleteProfileText:                           'Are you sure you want to delete your profile? This action cannot be undone.',
    deleteSupplierProfile:                       'Delete supplier profile',
    deleteSupplierProfileText:                   'You are the last admin with a supplier profile. Your supplier profile will be deleted in case of changing roles. Do you really want to delete your supplier profile?',
    description:                                 'Description',
    details:                                     'Details',
    developmentRevenue:                          'Revenue development',
    downloadCertificate:                         'Download certificate',
    dragDropFileText:                            'Drop a file or click to upload',
    dropDownEmptyText:                           'No matches',
    edit:                                        'Edit',
    editMachineTitle:                            'Edit machine',
    editMachineWarning:                          'You cannot change the machine data, but you can edit your machine capacity and choose your category.',
    editProductTitle:                            'Edit product',
    emailAddress:                                'Email address',
    emailValidationFailed:                       'Your email address could not be confirmed. Maybe the link has already been used, then try to log in.',
    emailValidationSuccessfully:                 'Your email address has been successfully confirmed. You can now log in.',
    end:                                         'End',
    error404:                                    '404',
    error:                                       'Error',
    errors:                                      {
        noName:                'Name must not be empty',
        unknownError:          'Unknown error.',
        companyDuplicateError: 'The name is already used. Use the search field below to find and claim your company.',
    },
    exportRatio:                                 'Export by region',
    facebookUrl:                                 'https://www.facebook.com/SUpplyDU',
    facts:                                       'Facts',
    factSheet:                                   'Factsheet',
    factSheetDeleteProductText:                  'Are you sure you really want to delete the product <strong>{{productTitle}}</strong>',
    factSheetDeleteProductTitle:                 'Delete product',
    factSheetFactCertificates:                   'Certificates',
    factSheetFactCommodities:                    'Commodities',
    factSheetFactEmployees:                      'Employees',
    factSheetFactLanguage:                       'Language',
    factSheetFactMainCustomers:                  'Customers',
    factSheetFactProcesses:                      'Manufacturing processes',
    factSheetFactProductionType:                 'Serial production type',
    factSheetProductLimitExceeded:               'You can only add up to {{amount}} products to your Factsheet. For more products you need to upgrade your membership.',
    factSheetProductMissingTitleOrImage:         'Please select a title and an image for the product!',
    factSheetProductsSubtitle:                   'Select up to {{productAmount}} products',
    factSheetProductsTitle:                      'Top {{productAmount}} Products',
    favorites:                                   'Favorites',
    firstTimeCTAButton:                          'About SUpplyDU.com?',
    firstTimeCTAText:                            'Find your business partner in the casting industry in less than 60 seconds',
    forecast:                                    'Forecast',
    free:                                        'Free',
    from:                                        'From',
    headerSearchFieldPlaceholder:                'What are you looking for?',
    home:                                        'Home',
    houseNumber:                                 'No.',
    iAmTheManufacturerCheckBoxLabel:             'I am the manufacturer of this machine',
    ignore:                                      'Ignore',
    image:                                       'Image',
    imageOf:                                     'Image of',
    imprint:                                     'Imprint',
    Industries:                                  'Industries',
    industries:                                  'Industries',
    industry:                                    'Industry',
    industryPlaceholder:                         'Select the industries your company is active in...',
    industryRatioDuplicateTag:                   'Please choose each industry only once!',
    Inspection:                                  'Inspection',
    inspection:                                  'Inspection',
    instagramUrl:                                'https://todo.de',
    INTERESTED:                                  'Interested',
    interested:                                  'Interested',
    interestedInProjectSubject:                  'Interested in project',
    interests:                                   'Interests',
    interestSelectorPlaceholder:                 'Press the add icon to search for interests...',
    invalidFileFormat:                           'You can only upload files with the following file formats: {{accept}}',
    join:                                        'Join',
    joinCompany:                                 'Join company',
    joinRequested:                               'Requested',
    joinRequestInfoText:                         'The following users want to join your company.<br/>That will allow this user to act as your company!',
    joinRequests:                                'Join requests',
    keywordNotFound:                             'Keyword not found?',
    language:                                    'Language',
    languages:                                   {
        de: 'German',
        us: 'English',
        cn: 'Chinese',
    },
    lastUpdate:                                  'last updated: {{updateString}}',
    linkedinUrl:                                 'https://www.linkedin.com/company/supplydu-com/',
    loading:                                     'Loading...',
    loadingLogin:                                'Validating login data...',
    login:                                       'Log in',
    loginError:                                  'Login failed. Please re-enter your email or password and try again.',
    loginToContinue:                             'Login to continue',
    logInToSeeMoreResults:                       'If you want to see all results please register for free',
    logout:                                      'Logout',
    lostPassword:                                'Lost password?',
    lostPasswordHeadline:                        'Lost your password?',
    lostPasswordRequestButton:                   'Reset password',
    lostPasswordSubtext:                         'No problem. Just enter your email address, we will send you further instructions to reset your password.',
    lostPasswordTitle:                           'Lost password',
    machine:                                     'Machine',
    MACHINE_MANUFACTURER:                        'Machine Manufacturer',
    machine_manufacturer:                        'Machine Manufacturer',
    machineCapacity:                             'Machine capacity',
    machineCapacityStatusBusy:                   'Machine is at full capacity',
    machineCapacityStatusFree:                   'Machine has capacity',
    machineCapacityStatusInUse:                  'Machine is in use, contact owner',
    machineCapacityStatusNoMember:               'not specified',
    machineCapacityWarningNotVerified:           'This will only be visible in the profile if your company is verified.',
    machineDetailName:                           '{{machineName}}',
    machineLabel:                                'Machine',
    machineOverlayDescriptionMissingError:       'Please enter a machine description before saving.',
    machineOverlayImageMissingError:             'Please add a machine image before saving.',
    machineOverlayNameMissingError:              'Please enter the machine name before saving.',
    machineOverlayParametersMissingError:        'Please enter the machine parameters before saving.',
    machines:                                    'Machines',
    mainCustomers:                               'Main customers',
    mandatory:                                   'Mandatory',
    mandatoryTags:                               'Mandatory tags',
    manufacturer:                                'Manufacturer',
    matches:                                     'Matches',
    material:                                    'Material',
    Materials:                                   'Materials',
    member:                                      'Member',
    membership:                                  'Membership',
    membershipDataNotSent:                       'Something went wrong',
    membershipOptions:                           'Membership options',
    message:                                     'Message',
    messageDetail:                               'Message details',
    messageRequestDeclined:                      'You cannot send a message because your message request was declined.',
    messageRequestPending:                       'You cannot send a message because your message request is not accepted yet',
    messages:                                    'Messages',
    missingMembershipData:                       'Please fill all fields',
    missingTag:                                  'Missing:',
    myCompany:                                   'My Company',
    myCompanyMissingTypes:                       'Please select at least one company type',
    myProfile:                                   'My Profile',
    name:                                        'Name',
    network:                                     'Network',
    newCollection:                               'New Collection',
    newPassword:                                 'New Password',
    news:                                        'News',
    newVersionHintLaterButton:                   'Später',
    newVersionHintText:                          'Eine neue Version dieser Anwendung ist verfügbar',
    newVersionHintUpdateButton:                  'Jetzt aktualisieren',
    noCertificatesSelected:                      'No certificates selected',
    noCompanyLinked:                             'Your account is not linked to a company',
    noCompanyLinkedInfoText:                     'Please create or join a company now. If you decide to join an existing company, the company administrator has to approve your request first.',
    noFavorites:                                 'You have no favorites yet',
    noFavoritesButtonText:                       'Start your search now',
    noFavoritesSubline:                          'Add your favorite companies to your favorites. Start now by clicking on the star icon next to a company.',
    noJoinRequests:                              'No join requests',
    noMachineFoundText:                          'Machine does not exist yet? Create a new one',
    noMachineFoundTitle:                         'No {{machineName}} found',
    noMessages:                                  'You have no messages yet',
    noMessagesButtonText:                        'Start your search now',
    noMessagesSubline:                           'Send a message to a company to start a conversation.',
    noResults:                                   'No results',
    noSearchResults:                             'Sorry, no results found',
    noTagsSelected:                              'No category selected',
    noTagsSelectedHint:                          'No {{fieldName}} selected',
    notDefined:                                  '-',
    notFound:                                    'Not found',
    notFoundText:                                'Not found',
    notFoundTitle:                               '404',
    notInterested:                               'Not interested',
    notLoggedIn:                                 'You are not logged in',
    notLoggedInSubline:                          'Please log in to use this feature',
    now:                                         'Now',
    numberOfEmployees:                           'Number of employees',
    ok:                                          'Ok',
    OPEN:                                        'Open',
    other:                                       'Other',
    others:                                      'Others',
    ownProjects:                                 'My projects',
    pageNotFound:                                'The page could not be found.',
    pageTitle:                                   'SUpplyDU.com',
    parameterName:                               'Name',
    parameters:                                  'Parameters',
    parameterUnit:                               'Unit',
    parameterValue:                              'Value',
    partFamily:                                  'Part family',
    password:                                    'Password',
    passwordDoesNotMatch:                        'Password does not match!',
    passwordIsNotSecureEnough:                   'Your password is not secure enough, please use at least {{minimalLength}} characters and a mix of lowercase and uppercase letters, numbers and special characters.',
    passwordLost:                                'Lost your password?',
    passwordNotMatch:                            'Passwords do not match!',
    peakYear:                                    'Peak year',
    peakYearVolume:                              'Peak Year Volume',
    percentageShort:                             '%',
    phone:                                       'Phone',
    phoneNumber:                                 'Phone number',
    photos:                                      'Photos',
    picture:                                     'Picture',
    portfolioProductLimitExceeded:               'You can only add up to {{amount}} products to your portfolio. For more products you need to upgrade your membership.',
    position:                                    'Position',
    postalCode:                                  'Zip/Postal',
    postingTime:                                 'Posting time',
    preferredLanguage:                           'Preferred Language',
    preferredOnsiteVerificationDate:             'Preferred Onsite Verification Date',
    processes:                                   'Processes',
    productionMachine:                           'Production machine',
    productionPortfolio:                         'Product portfolio',
    productionType:                              'Production type',
    productsAndMachines:                         'Products/Machines',
    profile:                                     'Profile',
    profileDeletedFailed:                        'Your profile could not be deleted. Please try again later.',
    profileDeletedSuccess:                       'Your profile was successfully deleted. You can come back and register again at any time.',
    profileMessages:                             'Messages',
    projectDuration:                             'Project duration',
    projectMatchChatText:                        'Please enter a comment to start the conversation with<br/><strong>{{companyName}}</strong>',
    projectMatchChatTextTitle:                   'Interested in match',
    projects:                                    'Projects',
    projectsAcceptProjectText:                   'You are about to commit your interest in the project<br/><strong>{{projectTitle}}</strong><br/>Please enter a comment to start the conversation with<br/><strong>{{receiver}}</strong>',
    projectsAcceptProjectTitle:                  'Interested',
    projectsCloseProjectText:                    'Are you sure you want to close this project <strong>{{projectTitle}}</strong>? All open matches will be deleted but you can still continue communicate related to this project.',
    projectsCloseProjectTitle:                   'Close project',
    projectsDeclineMatchText:                    'Are you sure you want to decline the company <strong>{{companyName}}</strong> for your project?',
    projectsDeclineMatchTitle:                   'Decline company',
    projectsDeclineProjectText:                  'Are you sure you are not interested in this project <strong>{{projectTitle}}</strong>? This decision cannot be undone.',
    projectsDeclineProjectTitle:                 'Not interested',
    projectsRejectCompanyText:                   'Are you sure you want reject <strong>{{companyName}}</strong> from your project <strong>{{projectTitle}}</strong>? <strong>{{companyName}}</strong> will no longer be able to contact you on this behalf.',
    projectsRejectCompanyTitle:                  'Reject company',
    promotionCode:                               'Promo Code',
    proposeChange:                               'Propose change',
    rangeCombinerFrom:                           'from',
    rangeCombinerShort:                          ' - ',
    rangeCombinerTo:                             'to',
    readMessages:                                'Read',
    refineYourSearch:                            'Refine your search',
    refineYourSearchText:                        'Add another category to refine your search',
    region:                                      'Region',
    rejectCompany:                               'Reject company',
    REJECTED:                                    'Rejected',
    reloadData:                                  'Reload data',
    remove:                                      'Remove',
    removeMachineText:                           'Are you sure you really want to remove <strong>{{machineName}}</strong> from the list of your machines?',
    removeMachineTitle:                          'Remove machine',
    repeatedPassword:                            'Repeat password',
    reportProfile:                               'Report Profile',
    reportProfileBody:                           'I\'d like to report {{companyName}} because of ... \n\n\n {{frontendUrl}}/company/{{companyId}}',
    reportProfileSubject:                        'Report Profile: {{companyName}}',
    requestedJoinFailed:                         'Your request to join the company failed.',
    requestedJoinSuccess:                        'Your request to join the company was successfully sent.',
    requestingCompany:                           'Requesting Company',
    requestingUser:                              'Requesting User',
    requestPasswordFailed:                       'Please verify that your email is correct',
    requestPasswordSucceeded:                    'Please check your email for further instructions.',
    requestToAdd:                                'Request to add',
    requestToAddEmailSubject:                    'Request to add a new search keyword',
    requestToAddEmailTarget:                     'contact@supplydu.com',
    requestToAddEmailText:                       'Dear SUpplyDU Team, \r\n\r\nI would like to add the following search keyword to the search index: \r\n\r\n"{{keyword}}"\r\n\r\nBest regards, \r\n{{name}}',
    results:                                     'Back to results',
    revenueDistribution:                         'Revenue by Industry',
    revenueDistributionRatio:                    'Revenue Distribution by Industry (ratio)',
    runtime:                                     'Runtime',
    save:                                        'Save',
    saveProductFailed:                           'The product could not be saved',
    saveProductImageMissing:                     'Please add a product image',
    saveProductSucceeded:                        'The product was successfully saved',
    saveProductTitleMissing:                     'Please enter a product title',
    saveProfileDefinePosition:                   'Please define your position',
    saveProfileFailure:                          'Your profile could not be saved. Please try again later.',
    saveProfileSuccess:                          'Your profile was successfully saved',
    screenDesign:                                'ScreenDesign',
    search:                                      'Search',
    searchAndSelectYourMachineText:              'Here you can search for your machine in our database',
    searchAndSelectYourMachineTitle:             'Search and select your machine',
    searchButtonAction:                          'Show {{resultCount}} results...',
    searchExistingMachinePlaceholder:            'Enter your machine name or name of machine manufacturer',
    searchFieldPlaceholder:                      'Search for technologies (e.g. die casting), supplier name, machine name, part families',
    searchNewMachinesText:                       'Search for machines to add to your company',
    searchNewMachinesTitle:                      'Search for your machine',
    searchResults:                               'Results',
    searchStandaloneTagNotAllowed:               'The following category cannot be searched without sub-categories: {{tagNames}}',
    selectTagsTip:                               'Select categories you want to search for',
    send:                                        'Send',
    sendMessage:                                 'Send Message',
    sendMessageFailed:                           'Your message could not be sent',
    sendMessageMissingMessage:                   'Please enter a message',
    sendMessageRequestFailed:                    'Your message could not be sent',
    sendMessageRequestMissingSubjectOrMessage:   'Please enter a subject and a message',
    sendMessageRequestSucceeded:                 'Your message was sent successfully',
    sendMessageSucceeded:                        'Your message was sent successfully',
    sendRequest:                                 'Send request',
    sendRequestTo:                               'Send {{company}}-Request',
    sentMembershipRequestText:                   'The subscription will only be activated after receipt of payment',
    sentMembershipRequestTitle:                  'Subscription activation',
    SERVICE_PROVIDER:                            'Service provider',
    service_provider:                            'Service Provider',
    setMessageRequestStateAccepted:              'The message request was accepted successfully',
    setMessageRequestStateDeclined:              'The message request was declined successfully',
    setMessageRequestStateFailed:                'The message request status could not be changed',
    setNewPasswordFailed:                        'Your password could not be changed!',
    setNewPasswordSubtext:                       'Please enter a new password and confirm it.',
    setNewPasswordSucceeded:                     'Your password was changed successfully.',
    setNewPasswordTitle:                         'Set a new password',
    settings:                                    'Settings',
    showCertificates:                            'Show Certificate',
    showLessTags:                                'Show less categories',
    showMore:                                    'Show more',
    showTags:                                    'Show categories',
    signIn:                                      'Sign in',
    signUp:                                      'Sign up',
    signupFailed:                                'Your account could not be created! Try to login with your email and password.',
    signUpForFree:                               'Sign up for free',
    signupSucceeded:                             'Your account has been created successfully. Before you can login, you need to confirm your email address. Please check your mailbox for further instructions.',
    signupToContinue:                            'Sign up to continue',
    signupToContinueText:                        'Please log in or sign up for free to see all results.',
    singUp:                                      'Sing up',
    singUpTerms:                                 'Our <a href="https://welcome.supplydu.com/terms-of-use" target="_blank">terms of use</a> and <a href="https://welcome.supplydu.com/terms-and-conditions" target="_blank">conditions</a> apply. We use your personal data exclusively in accordance with our <a href="https://welcome.supplydu.com/privacy" target="_blank">data protection</a> information.',
    stagingPasswordPlaceholder:                  'Staging password',
    start:                                       'Start',
    state:                                       'State/Region',
    status:                                      'Status:',
    street:                                      'Street',
    subject:                                     'Subject',
    subMenu:                                     'Sub Menu',
    success:                                     'Success',
    suggestedProjectsText:                       'As soon as we have a project matching your company\'s capabilities, we will inform you in this area.',
    suggestedProjectsTitle:                      'Suggested Projects',
    SUPPLIER:                                    'Supplier',
    supplier:                                    'Supplier',
    supplyDuOnFacebook:                          'SUpplyDU on Facebook',
    supplyDuOnInstagram:                         'SUpplyDU on Instagram',
    supplyDuOnLinkedin:                          'SUpplyDU on LinkedIn',
    supplyDuOnTwitter:                           'SUpplyDU on Twitter',
    supplyDuOnYouTube:                           'SUpplyDU on YouTube',
    supportUs:                                   'Support us',
    tag:                                         'Tag',
    tagChain:                                    'Chain',
    tags:                                        'Categories',
    tagSelectorPlaceholder:                      'Press the add icon to search for categories...',
    tagSearchButtonText:                         'Search by Category',
    taxId:                                       'Tax-ID',
    technolgies:                                 'Technologies',
    Technologies:                                'Technologies',
    technologies:                                'Technologies',
    termsAndConditions:                          'Terms and conditions',
    termsOfUse:                                  'Terms of Use',
    textIsTranslatedInformation:                 'translated with DeepL',
    title:                                       'Title',
    to:                                          'To',
    toggleMenu:                                  'Open/Close menu',
    total:                                       'Total',
    totalVolume:                                 'Complete project volume',
    translate:                                   'Translate',
    trusted:                                     'Trusted Member',
    twitterUrl:                                  'https://todo.de',
    types:                                       'Types',
    unknown:                                     'Unknown',
    unread:                                      'Unread',
    updateCompanyFailed:                         'Your company update failed',
    updateCompanySuccess:                        'Your company was updated successfully',
    updateFactSheetFailed:                       'Your factsheet update failed',
    updateMachineCapacity:                       'Update machine capacity',
    updateMachineCapacityFailed:                 'The machine capacity could not be updated',
    updateMachineCapacitySucceeded:              'The machine capacity was successfully updated',
    updateMachineSuccess:                        'The machine profile was successfully updated',
    upload:                                      'Upload',
    userCompanyIsNotVerified:                    'Your company is not verified yet (Please contact us to get verified)',
    userCompanyPosition:                         '{{companyPosition}} at {{companyName}}',
    userManagement:                              'User Management',
    userVerification:                            'Verification',
    userVerificationBusinessCardUpload:          '2. Business card',
    userVerificationDeclinedImages:              'Please upload new portrait & ID and business card images to verify your identity.',
    userVerificationFailed:                      'Your verification could not be completed. Please try again later.',
    userVerificationHeadlineDeclined:            'Your verification was declined',
    userVerificationHeadlinePending:             'Your verification is pending...',
    userVerificationHeadlineVerified:            'Your verification is completed',
    userVerificationIDUpload:                    '1. Portrait & ID',
    userVerificationInformationBusinessCardText: 'Business card of your current employer with your name and function',
    userVerificationInformationIDText:           'Your face & Photo ID',
    userVerificationInformationNote:             'Your verification can take up to 24 hours.',
    userVerificationInformationText:             'The usage of some of our functions requires the verification of your identify. To verify your identity, please upload 2 clear pictures of:',
    userVerificationMissingImages:               'Please upload portrait & ID and business card images to verify your identity.',
    userVerificationSublineDeclined:             'Please try again with new images',
    userVerificationSublinePending:              'We will inform you as soon as your verification is completed',
    userVerificationSublineVerified:             'You are now a verified user',
    userVerificationSubmitButton:                'Start verification',
    userVerificationSucceeded:                   'Your verification was sent successfully. We will inform you as soon as your verification is completed.',
    userVerificationTitle:                       'User Identity Verification',
    validationErrorCountryOfDelivery:            'Please select a country of delivery',
    value:                                       'Value',
    verified:                                    'Verified',
    versionLabel:                                'SUpplyDU {{dateString}} #{{commitHash}}',
    volume:                                      'Volume',
    volumes:                                     'Volume',
    warning:                                     'Warning',
    welcomeTo:                                   'Welcome to',
    whatAreYouLookingFor:                        'What are you <span>looking</span> for?',
    windowSize:                                  'Window size',
    yearMonth:                                   'Year/Month',
    yourCurrentRole:                             'Your current Role',
    youTubeUrl:                                  'https://todo.de',
    ///
    ///
    ///
    ///
    //
    // The following entries are mandatory
    //
    and:                     'and',
    more:                    'More',
    no:                      'No',
    oclock:                  'o\'clock',
    or:                      'or',
    orSelectFile:            'Or select a file',
    versionNumberDateFormat: 'DD.MM.YYYY HH:mm:ss',
    yes:                     'Yes',
};

export default english;
