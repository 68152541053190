//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import _                      from 'lodash';
import I18n                   from 'i18next';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import { connect }            from 'react-redux';

import { components as SelectComponents } from 'react-select';
import appStyles                          from '@/styles.module.scss';
import ComponentHelper                    from '@/helper/ComponentHelper';
import Ids                                from '@/constants/Ids';
import PropTypes                          from '@/components/PropTypes';
import { ProfileActions }                 from '@/store/actions/profile';
import Routes                             from '@/constants/Routes';
import AlertBoxManager                    from '@/components/connected/AlertBoxManager';
import { UserActions }                    from '@/store/actions/user';
import ProfileSideMenu                    from '@/components/connected/ProfileSideMenu';
import { Spacer }                         from '@/components/stateless/atomic/Spacer';
import { HeadlineSmall }                  from '@/components/stateless/atomic/HeadlineSmall';
import { ColorBox }                       from '@/components/stateless/atomic/ColorBox';
import { FormRow }                        from '@/components/stateless/composed/FormRow';
import { TextInput }                      from '@/components/stateless/atomic/TextInput';
import { DropDownInput }                  from '@/components/stateless/atomic/DropDownInput';
import ColorButtonTheme                   from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import { ColorButton }                    from '@/components/stateless/atomic/ColorButton';
import ImageUploadSize                    from '@/components/stateless/atomic/ImageUpload/ImageUploadSize';
import { ImageUpload }                    from '@/components/stateless/atomic/ImageUpload';
import File                               from '@/helper/File';
import { ValidationError }                from '@/components/stateless/atomic/ValidationError';
import { CountryFlag }                    from '@/components/stateless/atomic/CountryFlag';
import { TagActions }                     from '@/store/actions/tag';

import CountryListHelper from '@/helper/CountryListHelper';
import TimezoneSelect    from 'react-timezone-select';

import State            from '@/helper/State';
import TagSearchEditor  from '@/components/connected/TagSearchEditor';
import TagEditorContext from '@/constants/TagEditorContext';
import CompanyPositions from '@/constants/CompanyPositions';
import styles           from './styles.module.scss';

class Screen extends React.Component {
    constructor(props) {
        super(props);

        this.props.loadInitialProfileData();
    }

    getCurrentCountryOption = () => {
        return {
            value: _.get(this.props, 'data.country.value'),
            label: _.get(this.props, 'data.country.label'),
        };
    };

    getCurrentLanguageOptions = () => {
        let preferredLanguages = _.get(this.props, 'data.preferredLanguage', '');

        if (_.isEmpty(preferredLanguages)) {
            return null;
        }

        preferredLanguages = preferredLanguages?.split(',');

        return preferredLanguages.map((language) => {
            return {
                value: language,
                label: I18n.t(`languages.${language}`),
            };
        });
    };

    saveProfile = () => {
        this.props.saveProfile();
    };

    onFormFieldChanged = (field) => {
        return (value) => {
            let fieldValue = value;

            if (fieldValue.target) {
                fieldValue = fieldValue.target.value;
            }

            this.props.profileFormDataChanged({
                field,
                value: fieldValue,
            });
        };
    };

    onImageChanged = (uploadedImage) => {
        if (uploadedImage) {
            File
                .getFileFromBlob(uploadedImage)
                .then((image) => {
                    this.props.profileFormDataChanged({
                        field: 'image',
                        value: image,
                    });
                });
        }
    };

    onChangeTimezone = (timezone) => {
        this.onFormFieldChanged('timezone')(timezone.value);
    };

    renderProfileContainer = () => {
        const currentTimezone = _.get(this.props, 'data.timezone');
        const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timezone        = _.defaultTo(currentTimezone, browserTimezone);

        return (
            <>
                <HeadlineSmall text={I18n.t('Contact')} />
                <Spacer height={20} />
                <ColorBox>
                    <FormRow
                        label={I18n.t('email')}
                        required={true}
                    >
                        <ValidationError
                            validation={this.props.validation}
                            field={'email'}
                        />
                        <TextInput
                            onChange={this.onFormFieldChanged('email')}
                            value={_.get(this.props, 'data.email')}
                        />
                    </FormRow>
                    <Spacer height={20} />
                    <FormRow
                        label={I18n.t('currentPassword')}
                        required={true}
                    >
                        <ValidationError
                            validation={this.props.validation}
                            field={'currentPassword'}
                        />
                        <TextInput
                            onChange={this.onFormFieldChanged('currentPassword')}
                            type={'password'}
                            value={_.get(this.props, 'data.currentPassword')}
                        />
                    </FormRow>
                    <Spacer height={20} />
                    <FormRow label={I18n.t('newPassword')}>
                        <TextInput
                            onChange={this.onFormFieldChanged('plainPassword')}
                            type={'password'}
                            value={_.get(this.props, 'data.plainPassword')}
                        />
                    </FormRow>
                    <Spacer height={20} />
                    <FormRow
                        label={I18n.t('name')}
                        required={true}
                    >
                        <ValidationError
                            validation={this.props.validation}
                            field={'name'}
                        />
                        <TextInput
                            onChange={this.onFormFieldChanged('name')}
                            value={_.get(this.props, 'data.name')}
                        />
                    </FormRow>
                    <Spacer height={20} />
                    <FormRow
                        label={I18n.t('company')}
                        required={true}
                    >
                        <ValidationError
                            validation={this.props.validation}
                            field={'workingAtCompany'}
                        />
                        <TextInput
                            onChange={this.onFormFieldChanged('workingAtCompany')}
                            value={_.get(this.props, 'data.workingAtCompany')}
                        />
                    </FormRow>
                    <Spacer height={20} />
                    <FormRow
                        label={I18n.t('position')}
                        required={true}
                    >
                        <ValidationError
                            validation={this.props.validation}
                            field={'position'}
                        />
                        <DropDownInput
                            options={this.getCompanyPositionOptions()}
                            defaultValue={_.get(this.props, 'data.position')}
                            onChange={this.onFormFieldChanged('position')}
                        />
                    </FormRow>
                    <Spacer height={20} />
                    <FormRow label={I18n.t('phone')}>
                        <TextInput
                            onChange={this.onFormFieldChanged('phone')}
                            value={_.get(this.props, 'data.phone')}
                        />
                    </FormRow>
                    <Spacer height={20} />
                    <FormRow
                        label={I18n.t('country')}
                        required={true}
                    >
                        <ValidationError
                            validation={this.props.validation}
                            field={'country'}
                        />
                        <DropDownInput
                            options={CountryListHelper.getOptions()}
                            defaultValue={this.getCurrentCountryOption()}
                            renderValue={this.renderCompanyCountryOption}
                            onChange={this.onFormFieldChanged('country')}
                        />
                    </FormRow>
                </ColorBox>
                <Spacer height={20} />
                <HeadlineSmall text={I18n.t('Settings')} />
                <Spacer height={20} />
                <ColorBox>
                    <FormRow label={I18n.t('picture')}>
                        <ImageUpload
                            uploadFileCallback={this.onImageChanged}
                            deleteFileCallback={this.onImageChanged}
                            images={[_.get(this.props, 'data.imageString')]}
                            size={ImageUploadSize.default}
                        />
                    </FormRow>
                    <Spacer height={20} />
                    <FormRow label={I18n.t('preferredLanguage')}>
                        <DropDownInput
                            multiple={true}
                            options={this.getLanguageOptions()}
                            defaultValue={this.getCurrentLanguageOptions()}
                            renderValue={this.renderCompanyCountryOption}
                            renderMultiValue={this.renderCompanyCountrySelectedOption}
                            onChange={this.onPreferredLanguageChanged}
                        />
                    </FormRow>
                    <Spacer height={20} />
                    <FormRow>
                        {this.renderInterestTags()}
                    </FormRow>
                    <Spacer height={20} />
                    <FormRow label={I18n.t('timezone')}>
                        <TimezoneSelect
                            value={timezone}
                            onChange={this.onChangeTimezone}
                        />
                    </FormRow>
                </ColorBox>
                <Spacer height={20} />
                <div>
                    <ColorButton
                        onClick={this.saveProfile}
                        text={I18n.t('save')}
                        theme={ColorButtonTheme.orange}
                    />
                </div>
            </>
        );
    };

    renderInterestTags = () => {
        return (
            <TagSearchEditor
                title={I18n.t('interests')}
                tagSelectorPlaceholder={I18n.t('interestsSelectorPlaceholder')}
                tagContext={TagEditorContext.myProfileInterests}
            />
        );
    };

    renderCompanyCountryOption = (name, option) => {
        return (
            <div className={styles.languageOption}>
                <CountryFlag
                    width={16}
                    iso31661Alpha2CountryCode={option.value}
                />
                {name}
            </div>
        );
    };

    renderCompanyCountrySelectedOption = (name, option, props) => {
        return (
            <SelectComponents.MultiValue {...props}>
                <div className={styles.languageOption}>
                    <CountryFlag
                        width={16}
                        iso31661Alpha2CountryCode={option.value}
                    />
                    {name}
                </div>
            </SelectComponents.MultiValue>
        );
    };

    render() {
        return (
            <div className={appStyles.defaultContainer}>
                <div
                    className={appStyles.defaultInnerContainer}
                    id={Ids.contentContainer}
                >
                    <ProfileSideMenu
                        route={Routes.myProfile}
                        {...this.props}
                        openMyProfileProjects={this.props.openMyProfileProjects}
                        openMyProfileMessages={this.props.openMyProfileMessages}
                    />
                    <div className={appStyles.defaultContentContainer}>
                        <AlertBoxManager />
                        <div className={styles.myProfileContainer}>
                            <div className={styles.myProfileContent}>
                                {this.renderProfileContainer()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(this, Screen, nextProps, nextState);
    }

    getCompanyPositionOptions() {
        return _.map(
            CompanyPositions,
            (position) => {
                const label = I18n.t(`companyPositions.${position}`);

                return {
                    value: position,
                    label,
                };
            },
        );
    }

    getLanguageOptions() {
        // They are hardcoded for now because the customer only needs those three
        return [
            {
                value: 'us',
                label: I18n.t('languages.us'),
            }, {
                value: 'de',
                label: I18n.t('languages.de'),
            }, {
                value: 'cn',
                label: I18n.t('languages.cn'),
            },
        ];
    }

    onPreferredLanguageChanged = (values) => {
        const languageString = _.chain(values)
            .reject(({ value }) => !value)
            .map((value) => value.value)
            .join(',')
            .value();

        this.onFormFieldChanged('preferredLanguage')(languageString);
    };
}

Screen.propTypes = {
    isUserWithoutCompany:   PropTypes.bool,
    loadInitialProfileData: PropTypes.func,
    openMyProfileMessages:  PropTypes.func,
    openMyProfileProjects:  PropTypes.func,
    profileFormDataChanged: PropTypes.func,
    saveProfile:            PropTypes.func,
    validation:             PropTypes.object,
};

Screen.defaultProps = {
    isUserWithoutCompany:   false,
    loadInitialProfileData: _.noop,
    openMyProfileMessages:  _.noop,
    openMyProfileProjects:  _.noop,
    profileFormDataChanged: _.noop,
    saveProfile:            _.noop,
    validation:             {},
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators({
    ...UserActions,
    ...ProfileActions,
    ...TagActions,
}, dispatch);

const mapStateToProps = (state) => {
    const data                 = _.get(state, 'profile.data');
    const validation           = _.get(state, 'profile.validation');
    const isUserWithoutCompany = _.isNil(State.getUserCompany(state));

    return {
        data,
        isUserWithoutCompany,
        validation,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Screen);
