//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React        from 'react';
import PropTypes    from '@/components/PropTypes';
import { AlertBox } from '@/components/stateless/atomic/AlertBox';
import AlertBoxType from '@/components/stateless/atomic/AlertBox/AlertBoxType';
import { connect }  from 'react-redux';
import State        from '@/helper/State';
import MemberStatus from '@/constants/MemberStatus';
import { Spacer }   from '@/components/stateless/atomic/Spacer';

export class Component extends React.Component {
    render() {
        const { memberStatus, text } = this.props;

        if (memberStatus !== MemberStatus.basic) {
            return null;
        }

        return (
            <>
                <Spacer height={20} />
                <AlertBox
                    text={text}
                    type={AlertBoxType.warning}
                />
            </>
        );
    }
}

export const MemberStatusWarning = Component;

Component.propTypes = {
    memberStatus: PropTypes.string,
    text:         PropTypes.string,
};

Component.defaultProps = {
    memberStatus: MemberStatus.basic,
    text:         null,
};

const mapStateToProps = (state) => {
    const memberStatus = State.getUserCompanyMemberStatus(state);

    return {
        memberStatus,
    };
};

export default connect(
    mapStateToProps,
    null,
)(Component);
