//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _                from 'lodash';
import update           from 'immutability-helper';
import Tag              from '@/helper/Tag';
import { CompanyTypes } from '@/store/actions/company';
import { UserTypes }    from '@/store/actions/user';

const initialState = {
    companies:              {},
    currentCompanyId:       null,
    currentCompanyUrl:      null,
    createCompany:          {},
    ownCompanyEdit:         {},
    myCompanySearchResults: [],
    myCompanyQuery:         null,
    daysSinceLastUpdate:    0,
    confirmCompanyDelete:   false,
};

const addOwnCompanyChildrenToTagQueryCompetences = (action, state) => {
    const tag                           = _.get(action, 'tag');
    const children                      = _.get(action, 'children');
    const ownCompanyEdit                = _.cloneDeep(state.ownCompanyEdit);
    ownCompanyEdit.competencesHierarchy = Tag.addPossibleChildToTagSelectorTree(ownCompanyEdit.competencesHierarchy, tag, children);

    return update(
        state,
        {
            ownCompanyEdit: {
                $set: ownCompanyEdit,
            },
        },
    );
};

const addOwnCompanyChildrenToTagQueryInterests = (action, state) => {
    const tag                         = _.get(action, 'tag');
    const children                    = _.get(action, 'children');
    const ownCompanyEdit              = _.cloneDeep(state.ownCompanyEdit);
    ownCompanyEdit.interestsHierarchy = Tag.addPossibleChildToTagSelectorTree(ownCompanyEdit.interestsHierarchy, tag, children);

    return update(
        state,
        {
            ownCompanyEdit: {
                $set: ownCompanyEdit,
            },
        },
    );
};

const addOwnCompanyCompetenceTag = (action, state) => {
    const clonedEditCompany                = _.cloneDeep(state.ownCompanyEdit);
    clonedEditCompany.competencesHierarchy = Tag.addTagToTagSelectorTree(clonedEditCompany.competencesHierarchy, action.tag);

    return update(state, {
        ownCompanyEdit: {
            $set: clonedEditCompany,
        },
    });
};

const addOwnCompanyMachine = (action, state) => {
    let newState = _.cloneDeep(state);

    if (!state.ownCompanyEdit.machines) {
        newState = update(state, {
            ownCompanyEdit: {
                machines: {
                    $set: [],
                },
            },
        });
    }

    return update(newState, {
        ownCompanyEdit: {
            machines: {
                $push: [
                    action.machineIri,
                ],
            },
        },
    });
};

const addOwnCompanyInterestTag = (action, state) => {
    const clonedEditCompany              = _.cloneDeep(state.ownCompanyEdit);
    clonedEditCompany.interestsHierarchy = Tag.addTagToTagSelectorTree(clonedEditCompany.interestsHierarchy, action.tag);

    return update(state, {
        ownCompanyEdit: {
            $set: clonedEditCompany,
        },
    });
};

const addCompanyCompetenceTagQuery = (action, state) => {
    const clonedEditCompany                = _.cloneDeep(state.ownCompanyEdit);
    const hierarchyPath                    = _.get(action.tag, 'data.hierarchyPath');
    const possibleChildren                 = _.get(action.tag, 'data.tags');
    clonedEditCompany.competencesHierarchy = Tag.addNextSiblingToTagSelectorTree(clonedEditCompany.competencesHierarchy, hierarchyPath, possibleChildren);

    return update(state, {
        ownCompanyEdit: {
            $set: clonedEditCompany,
        },
    });
};

const addCompanyInterestTagQuery = (action, state) => {
    const clonedEditCompany              = _.cloneDeep(state.ownCompanyEdit);
    const hierarchyPath                  = _.get(action.tag, 'data.hierarchyPath');
    const possibleChildren               = _.get(action.tag, 'data.tags');
    clonedEditCompany.interestsHierarchy = Tag.addNextSiblingToTagSelectorTree(clonedEditCompany.interestsHierarchy, hierarchyPath, possibleChildren);

    return update(state, {
        ownCompanyEdit: {
            $set: clonedEditCompany,
        },
    });
};

const addSiblingCompanyCompetenceTag = (action, state) => {
    const clonedEditCompany                = _.cloneDeep(state.ownCompanyEdit);
    const hierarchyPath                    = _.get(action.tag, 'data.hierarchyPath');
    const possibleChildren                 = _.get(action.tag, 'data.tags');
    clonedEditCompany.competencesHierarchy = Tag.addNextSiblingToTagSelectorTree(clonedEditCompany.competencesHierarchy, hierarchyPath, possibleChildren);

    return update(state, {
        ownCompanyEdit: {
            $set: clonedEditCompany,
        },
    });
};

const addSiblingCompanyInterestTag = (action, state) => {
    const clonedEditCompany              = _.cloneDeep(state.ownCompanyEdit);
    const hierarchyPath                  = _.get(action.tag, 'data.hierarchyPath');
    const possibleChildren               = _.get(action.tag, 'data.tags');
    clonedEditCompany.interestsHierarchy = Tag.addNextSiblingToTagSelectorTree(clonedEditCompany.interestsHierarchy, hierarchyPath, possibleChildren);

    return update(state, {
        ownCompanyEdit: {
            $set: clonedEditCompany,
        },
    });
};

const cancelEditOwnCompany = (action, state) => {
    return update(state, {
        ownCompanyEdit: {
            $set: {},
        },
    });
};

const changeCompanyCompetenceTag = (action, state) => {
    const clonedEditCompany                = _.cloneDeep(state.ownCompanyEdit);
    const hierarchyPath                    = _.get(action.tag, 'data.hierarchyPath');
    const newTag                           = action.tag.changedValue;
    clonedEditCompany.competencesHierarchy = Tag.changeTagInTagSelectorTree(clonedEditCompany.competencesHierarchy, hierarchyPath, newTag.value);

    return update(state, {
        ownCompanyEdit: {
            $set: clonedEditCompany,
        },
    });
};

const changeCompanyInterestTag = (action, state) => {
    const clonedEditCompany              = _.cloneDeep(state.ownCompanyEdit);
    const hierarchyPath                  = _.get(action.tag, 'data.hierarchyPath');
    const newTag                         = action.tag.changedValue;
    clonedEditCompany.interestsHierarchy = Tag.changeTagInTagSelectorTree(clonedEditCompany.interestsHierarchy, hierarchyPath, newTag.value);

    return update(state, {
        ownCompanyEdit: {
            $set: clonedEditCompany,
        },
    });
};

const deleteOwnCompanyCompetenceTag = (action, state) => {
    const clonedEditCompany                = _.cloneDeep(state.ownCompanyEdit);
    const hierarchyPath                    = _.get(action.tag, 'data.hierarchyPath');
    clonedEditCompany.competencesHierarchy = Tag.removeTagFromTagSelectorTree(clonedEditCompany.competencesHierarchy, hierarchyPath);

    return update(state, {
        ownCompanyEdit: {
            $set: clonedEditCompany,
        },
    });
};

const deleteOwnCompanyInterestTag = (action, state) => {
    const clonedEditCompany              = _.cloneDeep(state.ownCompanyEdit);
    const hierarchyPath                  = _.get(action.tag, 'data.hierarchyPath');
    clonedEditCompany.interestsHierarchy = Tag.removeTagFromTagSelectorTree(clonedEditCompany.interestsHierarchy, hierarchyPath);

    return update(state, {
        ownCompanyEdit: {
            $set: clonedEditCompany,
        },
    });
};

const removeMachineFromOwnCompany = (action, state) => {
    const clonedCurrentCompany    = _.cloneDeep(state.companies[state.currentCompanyId]);
    const machines                = clonedCurrentCompany.machines;
    const machine                 = action.machine;
    clonedCurrentCompany.machines = machines.filter((machineIri) => !machineIri.endsWith(machine.id));
    const updatedState            = update(state, {
        companies: {
            [state.currentCompanyId]: {
                $set: clonedCurrentCompany,
            },
        },
    });

    return updatedState;
};

const openDeleteOwnCompanyMachineDialog = (action, state) => {
    const machine = action.machine;

    return update(state, {
        machineToDelete: {
            $set: machine,
        },
    });
};

const openRemoveOwnCompanyMachineDialog = (action, state) => {
    const clonedCurrentCompany             = _.cloneDeep(state.companies[state.currentCompanyId]);
    const machines                         = clonedCurrentCompany.machines;
    const machine                          = action.machine;
    clonedCurrentCompany.machineIdToDelete = machine.id;
    clonedCurrentCompany.machines          = machines.filter((machineIri) => !machineIri.endsWith(machine.id));

    return update(state, {
        machineToRemove:                  {
            $set: machine,
        },
        ownCompanyWithUpdatedMachineList: {
            $set: {
                iri:      clonedCurrentCompany.iri,
                machines: clonedCurrentCompany.machines,
            },
        },
    });
};

const editOwnCompany = (action, state) => {
    let interestsHierarchy     = [];
    let competencesHierarchy   = [];
    const clonedCurrentCompany = _.cloneDeep(state.companies[state.currentCompanyId]);

    clonedCurrentCompany.interests.forEach((interestTag) => {
        interestsHierarchy = Tag.addTagToTagSelectorTree(interestsHierarchy, interestTag);
    });

    clonedCurrentCompany.competences.forEach((competenceTag) => {
        competencesHierarchy = Tag.addTagToTagSelectorTree(competencesHierarchy, competenceTag);
    });

    clonedCurrentCompany.interestsHierarchy   = interestsHierarchy;
    clonedCurrentCompany.competencesHierarchy = competencesHierarchy;

    return update(state, {
        companies:      {
            [state.currentCompanyId]: {
                $set: clonedCurrentCompany,
            },
        },
        ownCompanyEdit: {
            $set: clonedCurrentCompany,
        },
    });
};

const setCurrentCompanyJoinRequests = (action, state) => {
    return update(state, {
        companies: {
            [state.currentCompanyId]: {
                usersRequestedToJoin: {
                    $set: action.usersRequestedToJoin,
                },
            },
        },
    });
};

const editOwnCompanyAddressCity = (action, state) => {
    return update(state, {
        ownCompanyEdit: {
            address: {
                city: {
                    $set: action.city,
                },
            },
        },
    });
};

const editOwnCompanyAddressCountry = (action, state) => {
    return update(state, {
        ownCompanyEdit: {
            address: {
                country:     {
                    $set: action.country,
                },
                countryCode: {
                    $set: action.countryCode,
                },
            },
        },
    });
};

const editOwnCompanyAddressHouseNumber = (action, state) => {
    return update(state, {
        ownCompanyEdit: {
            address: {
                houseNumber: {
                    $set: action.houseNumber,
                },
            },
        },
    });
};

const editOwnCompanyAddressPostalCode = (action, state) => {
    return update(state, {
        ownCompanyEdit: {
            address: {
                postalCode: {
                    $set: action.postalCode,
                },
            },
        },
    });
};

const editOwnCompanyAddressState = (action, state) => {
    return update(state, {
        ownCompanyEdit: {
            address: {
                state: {
                    $set: action.state,
                },
            },
        },
    });
};

const editOwnCompanyAddressStreet = (action, state) => {
    return update(state, {
        ownCompanyEdit: {
            address: {
                street: {
                    $set: action.street,
                },
            },
        },
    });
};

const editOwnCompanyCompanyTypes = (action, state) => {
    return update(state, {
        ownCompanyEdit: {
            types: {
                $set: action.companyTypes,
            },
        },
    });
};

const editOwnCompanyCompanyIndustries = (action, state) => {
    return update(state, {
        ownCompanyEdit: {
            industries: {
                $set: action.companyIndustries,
            },
        },
    });
};

const editOwnCompanyContactPhone = (action, state) => {
    return update(state, {
        ownCompanyEdit: {
            contactData: {
                phone: {
                    $set: action.phoneNumber,
                },
            },
        },
    });
};

const editOwnCompanyDescription = (action, state) => {
    return update(state, {
        ownCompanyEdit: {
            description: {
                $set: action.description,
            },
        },
    });
};

const editOwnCompanyLogo = (action, state) => {
    return update(state, {
        ownCompanyEdit: {
            logoUploaded: {
                $set: action.logo,
            },
        },
    });
};

const editOwnCompanyName = (action, state) => {
    return update(state, {
        ownCompanyEdit: {
            name: {
                $set: action.name,
            },
        },
    });
};

const editOwnCompanyVideo = (action, state) => {
    return update(state, {
        ownCompanyEdit: {
            video: {
                $set: action.video,
            },
        },
    });
};

const editOwnCompanyPresentation = (action, state) => {
    return update(state, {
        ownCompanyEdit: {
            presentation: {
                $set: action.presentation,
            },
        },
    });
};

const fetchCompanySucceeded = (action, state) => {
    const company = action.company;

    return update(state, {
        companies:           {
            [company.id]: {
                $set: company,
            },
        },
        daysSinceLastUpdate: {
            $set: company.daysSinceLastUpdate,
        },
        currentCompanyId:    {
            $set: company.id,
        },
    });
};

const setCurrentCompany = (action, state) => {
    return update(state, {
        currentCompanyId: {
            $set: action.id,
        },
    });
};

const updateCompanySucceeded = (action, state) => {
    return update(state, {
        companies:      {
            [state.currentCompanyId]: {
                $set: action.company,
            },
        },
        ownCompanyEdit: {
            $set: action.company,
        },
    });
};

const setMyCompanyQuery = (action, state) => {
    return update(state, {
        myCompanyQuery: {
            $set: action.query,
        },
    });
};

const setMyCompanySearchResults = (action, state) => {
    return update(state, {
        myCompanySearchResults: {
            $set: action.companies,
        },
    });
};

const setCreateCompany = (action, state) => {
    const companyName = _.defaultTo(
        _.get(action, 'companyName'),
        _.get(state, 'createCompany.companyName'),
    );
    const position    = _.defaultTo(
        _.get(action, 'position'),
        _.get(state, 'createCompany.position'),
    );

    return update(state, {
        createCompany: {
            $set: {
                companyName,
                position,
            },
        },
    });
};

const setConfirmCompanyDelete = (action, state) => {
    return update(state, {
        confirmCompanyDelete: {
            $set: action.confirmCompanyDelete,
        },
    });
};

const resetState = (action, state) => {
    return update(state, {
        $set: initialState,
    });
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        // @formatter:off
        case CompanyTypes.ADD_OWN_COMPANY_CHILDREN_TO_TAG_QUERY_COMPETENCES: return addOwnCompanyChildrenToTagQueryCompetences(action, state);
        case CompanyTypes.ADD_OWN_COMPANY_CHILDREN_TO_TAG_QUERY_INTERESTS:   return addOwnCompanyChildrenToTagQueryInterests(action, state);
        case CompanyTypes.ADD_OWN_COMPANY_COMPETENCE_TAG:                    return addOwnCompanyCompetenceTag(action, state);
        case CompanyTypes.ADD_OWN_COMPANY_MACHINE:                           return addOwnCompanyMachine(action, state);
        case CompanyTypes.ADD_OWN_COMPANY_INTEREST_TAG:                      return addOwnCompanyInterestTag(action, state);
        case CompanyTypes.ADD_OWN_COMPANY_COMPETENCE_TAG_QUERY:              return addCompanyCompetenceTagQuery(action, state);
        case CompanyTypes.ADD_OWN_COMPANY_INTEREST_TAG_QUERY:                return addCompanyInterestTagQuery(action, state);
        case CompanyTypes.ADD_SIBLING_OWN_COMPANY_COMPETENCE_TAG:            return addSiblingCompanyCompetenceTag(action, state);
        case CompanyTypes.ADD_SIBLING_OWN_COMPANY_INTEREST_TAG:              return addSiblingCompanyInterestTag(action, state);
        case CompanyTypes.CANCEL_EDIT_OWN_COMPANY:                           return cancelEditOwnCompany(action, state);
        case CompanyTypes.CHANGE_OWN_COMPANY_COMPETENCE_TAG:                 return changeCompanyCompetenceTag(action, state);
        case CompanyTypes.CHANGE_OWN_COMPANY_INTEREST_TAG:                   return changeCompanyInterestTag(action, state);
        case CompanyTypes.DELETE_OWN_COMPANY_COMPETENCE_TAG:                 return deleteOwnCompanyCompetenceTag(action, state);
        case CompanyTypes.DELETE_OWN_COMPANY_INTEREST_TAG:                   return deleteOwnCompanyInterestTag(action, state);
        case CompanyTypes.OPEN_DELETE_OWN_COMPANY_MACHINE_DIALOG:            return openDeleteOwnCompanyMachineDialog(action, state);
        case CompanyTypes.OPEN_REMOVE_OWN_COMPANY_MACHINE_DIALOG:            return openRemoveOwnCompanyMachineDialog(action, state);
        case CompanyTypes.DELETE_OWN_COMPANY_MACHINE_SUCCEEDED:              return removeMachineFromOwnCompany(action, state);
        case CompanyTypes.REMOVE_OWN_COMPANY_MACHINE_SUCCEEDED:              return removeMachineFromOwnCompany(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY:
        case CompanyTypes.EDIT_OWN_COMPANY_AND_OPEN_OVERLAY:                 return editOwnCompany(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY_ADDRESS_CITY:                     return editOwnCompanyAddressCity(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY_ADDRESS_COUNTRY:                  return editOwnCompanyAddressCountry(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY_ADDRESS_HOUSE_NUMBER:             return editOwnCompanyAddressHouseNumber(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY_ADDRESS_POSTAL_CODE:              return editOwnCompanyAddressPostalCode(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY_ADDRESS_STATE:                    return editOwnCompanyAddressState(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY_ADDRESS_STREET:                   return editOwnCompanyAddressStreet(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY_COMPANY_TYPES:                    return editOwnCompanyCompanyTypes(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY_COMPANY_INDUSTRIES:               return editOwnCompanyCompanyIndustries(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY_CONTACT_PHONE:                    return editOwnCompanyContactPhone(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY_DESCRIPTION:                      return editOwnCompanyDescription(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY_LOGO:                             return editOwnCompanyLogo(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY_NAME:                             return editOwnCompanyName(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY_VIDEO:                            return editOwnCompanyVideo(action, state);
        case CompanyTypes.EDIT_OWN_COMPANY_PRESENTATION:                     return editOwnCompanyPresentation(action, state);
        case CompanyTypes.FETCH_COMPANY_SUCCEEDED:                           return fetchCompanySucceeded(action, state);
        case CompanyTypes.CREATE_COMPANY_SUCCEEDED:                          return fetchCompanySucceeded(action, state);
        case CompanyTypes.SET_CURRENT_COMPANY:                               return setCurrentCompany(action, state);
        case CompanyTypes.SET_CURRENT_COMPANY_JOIN_REQUESTS:                 return setCurrentCompanyJoinRequests(action, state);
        case CompanyTypes.SET_CREATE_COMPANY:                                return setCreateCompany(action, state);
        case CompanyTypes.SET_MY_COMPANY_QUERY:                              return setMyCompanyQuery(action, state);
        case CompanyTypes.SET_MY_COMPANY_RESULTS:                            return setMyCompanySearchResults(action, state);
        case CompanyTypes.UPDATE_COMPANY_SUCCEEDED:                          return updateCompanySucceeded(action, state);
        case CompanyTypes.CONFIRM_COMPANY_DELETE:                            return setConfirmCompanyDelete(action, state);
        case UserTypes.LOGOUT:                                               return resetState(action, state);
        default:                                                             return state;
        // @formatter:on
    }
}
