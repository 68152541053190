//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React                  from 'react';
import { Component }          from 'react';
import classNames             from 'classnames';
import I18n                   from 'i18next';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';
import _                      from 'lodash';
import { connect }            from 'react-redux';

import Align                                 from '@/constants/Align';
import BreadCrumbBar                         from '@/components/connected/BreadCrumbBar';
import ColorButton                           from '@/components/stateless/atomic/ColorButton';
import ColorButtonTheme                      from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import CompanySearchResult                   from '@/components/stateless/composed/CompanySearchResult';
import CompanyTypes                          from '@/constants/CompanyTypes';
import CountryDropDownInput                  from '@/components/stateless/composed/CountryDropDownInput';
import CountryFlag                           from '@/components/stateless/atomic/CountryFlag';
import DateInput                             from '@/components/stateless/atomic/DateInput';
import DropDownInput                         from '@/components/stateless/atomic/DropDownInput';
import EntityLabelSize                       from '@/components/stateless/composed/EntityLabel/EntityLabelSize';
import Facts                                 from '@/components/stateless/atomic/Facts';
import IconButton                            from '@/components/stateless/atomic/IconButton';
import IconButtonTheme                       from '@/components/stateless/atomic/IconButton/IconButtonTheme';
import IconTextButtonPosition                from '@/components/stateless/atomic/IconTextButton/IconTextButtonPosition';
import IconType                              from '@/components/stateless/atomic/Icon/IconType';
import ListWithHeader                        from '@/components/stateless/composed/ListWithHeader';
import MembershipOptions                     from '@/components/stateless/composed/MembershipOptions';
import MachineParameter                      from '@/components/stateless/atomic/MachineParameter';
import Match                                 from '@/components/stateless/composed/Match';
import ProjectStatus                         from '@/constants/ProjectStatus';
import Overlays                              from '@/constants/Overlays';
import OverlayType                           from '@/components/connected/Overlay/OverlayType';
import OwnProjectListElement                 from '@/components/stateless/composed/OwnProjectListElement';
import ParameterInput                        from '@/components/stateless/composed/ParameterInput';
import ProjectListElement                    from '@/components/stateless/composed/ProjectListElement';
import PropTypes                             from '@/components/PropTypes';
import RoundDropDown                         from '@/components/stateless/atomic/RoundDropDown';
import Routes                                from '@/constants/Routes';
import SideMenu                              from '@/components/stateless/composed/SideMenu';
import SideMenuButton                        from '@/components/stateless/atomic/SideMenuButton';
import SideMenuFooterButton                  from '@/components/stateless/atomic/SideMenuFooterButton';
import SideMenuHeadline                      from '@/components/stateless/composed/SideMenuHeadline';
import StarButton                            from '@/components/stateless/composed/StarButton';
import StartPageSearch                       from '@/components/stateless/composed/StartPageSearch';
import StartPageSearchMode                   from '@/components/stateless/composed/StartPageSearch/StartPageSearchMode';
import TagList                               from '@/components/stateless/composed/TagList';
import TagSearchResult                       from '@/components/stateless/composed/TagSearchResult';
import TextInputSize                         from '@/components/stateless/atomic/TextInput/TextInputSize';
import { AddressBox }                        from '@/components/stateless/composed/AddressBox';
import { AddressLabel }                      from '@/components/stateless/composed/AddressLabel';
import { CollapsableTagList }                from '@/components/stateless/composed/CollapsableTagList';
import { CompanyDescription }                from '@/components/stateless/atomic/CompanyDescription';
import { ContentHeaderStateless }            from '@/components/stateless/composed/ContentHeaderStateless';
import { ContentHeaderTitle }                from '@/components/stateless/composed/ContentHeaderTitle';
import { EntityLabel }                       from '@/components/stateless/composed/EntityLabel';
import { MachineBox }                        from '@/components/stateless/composed/MachineBox';
import { FormHeadline }                      from '@/components/stateless/atomic/FormHeadline';
import { FormRow }                           from '@/components/stateless/composed/FormRow';
import { Header }                            from '@/components/stateless/composed/Header';
import { HeadlineMedium }                    from '@/components/stateless/atomic/HeadlineMedium';
import { HeadlineSmall }                     from '@/components/stateless/atomic/HeadlineSmall';
import { IconTextButton }                    from '@/components/stateless/atomic/IconTextButton';
import { ImageUpload }                       from '@/components/stateless/atomic/ImageUpload';
import { InterestedInProjectMessageContent } from '@/components/stateless/composed/InterestedInProjectMessageContent';
import { Link }                              from '@/components/stateless/atomic/Link';
import { Logo }                              from '@/components/stateless/atomic/Logo';
import { MachineHeader }                     from '@/components/stateless/composed/MachineHeader';
import { MachineParameterBox }               from '@/components/stateless/composed/MachineParameterBox';
import { OverlayActions }                    from '@/store/actions/ovleray';
import { OverlayManager }                    from '@/components/connected/OverlayManager';
import { Pagination }                        from '@/components/stateless/atomic/Pagination';
import { Spacer }                            from '@/components/stateless/atomic/Spacer';
import { StartPageBox }                      from '@/components/stateless/composed/StartPageBox';
import { TabBar }                            from '@/components/stateless/composed/TabBar';
import { Table }                             from '@/components/stateless/atomic/Table';
import { TableBody }                         from '@/components/stateless/atomic/TableBody';
import { TableBodyColumn }                   from '@/components/stateless/atomic/TableBodyColumn';
import { TableBodyRow }                      from '@/components/stateless/atomic/TableBodyRow';
import { TableHeader }                       from '@/components/stateless/atomic/TableHeader';
import { TableHeaderColumn }                 from '@/components/stateless/atomic/TableHeaderColumn';
import { TableHeaderRow }                    from '@/components/stateless/atomic/TableHeaderRow';
import { TextInput }                         from '@/components/stateless/atomic/TextInput';
import { BoldTranslation }                   from '@/components/stateless/composed/BoldTranslation';
import appStyles                             from '@/styles.module.scss';

import styles from './styles.module.scss';

const lulububuLogo = require('@/assets/images/dummy/lulububu-blue.png').default;
const machineImage = require('@/assets/images/dummy/machine-image.png').default;

const tagList           = [
    [
        'Casting',
        'casting suppliers',
        'Production process',
        'Automatic molding (Sand)',
        'Core pack',
    ],
    [
        'Casting',
        'casting suppliers',
        'Production process',
        'Automatic molding (Sand)',
        'That pack',
    ],
    [
        'Casting',
        'casting suppliers',
        'Production process',
        'Automatic molding (Sand)',
        'Test pack',
    ],
    [
        'Casting',
        'casting suppliers',
        'Production process',
        'Automatic molding (Sand)',
        'Long name pack',
    ],
    [
        'Casting',
        'casting suppliers',
        'Production process',
        'Automatic molding (Sand)',
        'This pack',
    ],
    [
        'Casting',
        'casting suppliers',
        'Production process',
        'Automatic molding (Sand)',
        'Blub pack',
    ],
];
const membershipOptions = [
    {
        '@id':         '/api/membership-options/e5b338c0-021e-11ef-aa3e-0242ac140002',
        '@type':       'MembershipOption',
        'title':       'Basic',
        'priceInEuro': 0,
        'benefits':    [
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Access to platform',
                'text':  'Get access to the information of all registered companies.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Create company profile',
                'text':  'Create a professional and appealing profile that increase your chance to be found and recognized by customers through our search.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Show your products (Max 4)',
                'text':  'Upload photos and detailed information of your products.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Show your equipment',
                'text':  'Upload photos and detailed information of your available machines to get more and better matches based on machine specific customer needs.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Showcase your competences',
                'text':  'Provide an insight into your technical capabilities and know-how to be found by customers.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Messaging function',
                'text':  'Get contacted by customers and start your business communication directly on the platform.',
            },
        ],
        'highlighted': false,
    },
    {
        '@id':         '/api/membership-options/e5b36e42-021e-11ef-aa3e-0242ac140002',
        '@type':       'MembershipOption',
        'title':       'Member',
        'priceInEuro': 749,
        'benefits':    [
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Access to platform',
                'text':  'Get access to the information of all registered companies.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Create company profile',
                'text':  'Create a professional and appealing profile that increase your chance to be found and recognized by customers through our search.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Show your products (Max 4)',
                'text':  'Upload photos and detailed information of your products.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Show your equipment',
                'text':  'Upload photos and detailed information of your available machines to get more and better matches based on machine specific customer needs.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Showcase your competences',
                'text':  'Provide an insight into your technical capabilities and know-how to be found by customers.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Messaging function',
                'text':  'Get contacted by customers and start your business communication directly on the platform.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Favorite function',
                'text':  'Choose companies you are interested in and add them to your favorites. Get selected by customers as their favorites.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Add company video',
                'text':  'Give your profile a unique charm by adding a company video.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Add company presentation',
                'text':  'Show even more of your skills with your own company presentation on our platform.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'In-message translation',
                'text':  'Benefit from our translation function to communicate in your own language.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Upload quality certificates',
                'text':  'Certificates are an essential part of the supplier selection process. Show customers your current certification status and increase their trust.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Categorize your products and machines',
                'text':  'Create your own categories for your products and machines and make your profile even more clear and attractive.',
            },
        ],
        'highlighted': false,
    },
    {
        '@id':         '/api/membership-options/e5b39df2-021e-11ef-aa3e-0242ac140002',
        '@type':       'MembershipOption',
        'title':       'Trusted Member',
        'priceInEuro': 5799,
        'benefits':    [
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Access to platform',
                'text':  'Get access to the information of all registered companies.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Create company profile',
                'text':  'Create a professional and appealing profile that increase your chance to be found and recognized by customers through our search.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Show your products (Max 4)',
                'text':  'Upload photos and detailed information of your products.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Show your equipment',
                'text':  'Upload photos and detailed information of your available machines to get more and better matches based on machine specific customer needs.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Showcase your competences',
                'text':  'Provide an insight into your technical capabilities and know-how to be found by customers.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Messaging function',
                'text':  'Get contacted by customers and start your business communication directly on the platform.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Favorite function',
                'text':  'Choose companies you are interested in and add them to your favorites. Get selected by customers as their favorites.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Add company video',
                'text':  'Give your profile a unique charm by adding a company video.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Add company presentation',
                'text':  'Show even more of your skills with your own company presentation on our platform.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'In-message translation',
                'text':  'Benefit from our translation function to communicate in your own language.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Upload quality certificates',
                'text':  'Certificates are an essential part of the supplier selection process. Show customers your current certification status and increase their trust.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Categorize your products and machines',
                'text':  'Create your own categories for your products and machines and make your profile even more clear and attractive.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Preferred in search',
                'text':  'Be shown first in search results among other manufacturers with same capabilities.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'On-site verification',
                'text':  'Trust is key! Get verified annually by an accredited institute to confirm your profile status to customers. Receive a special “verified” icon next to your company name.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Project function',
                'text':  'Get new business opportunities! Participate in customer project on our platform that match your capabilities.',
            },
            {
                '@type': 'MembershipOptionBenefit',
                'title': 'Show machine capacities',
                'text':  'Increase your machine utilization by showing customer your available capacities to be awarded with matching projects.',
            },
        ],
        'highlighted': true,
    },
];
const uploadedImages    = [
    {
        path: 'https://placekitten.com/200/200',
    },
    {
        path: 'https://placekitten.com/200/200',
    },
    {
        path: 'https://placekitten.com/200/200',
    },
];
const highlightText     = 'core';

class Screen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            parameters:   [
                {
                    id:    Math.random(),
                    name:  'Width',
                    value: '200m',
                },
            ],
            selectedDate: null,
        };
    }

    renderProjectRelatedTestButtons = () => {
        return (
            <>
                <IconTextButton
                    onClick={this.onOpenCloseProjectDialogClick}
                    iconType={IconType.pencil}
                    text={I18n.t('close')}
                />
                <IconTextButton
                    onClick={this.onOpenRejectCompanyDialogClick}
                    iconType={IconType.pencil}
                    text={I18n.t('rejectCompany')}
                />
                <IconTextButton
                    onClick={this.onOpenDeclineProjectDialogClick}
                    iconType={IconType.pencil}
                    text={I18n.t('declineProject')}
                />
                <IconTextButton
                    onClick={this.onOpenAcceptProjectDialogClick}
                    iconType={IconType.pencil}
                    text={I18n.t('accept')}
                />
            </>
        );
    };

    onOpenCloseProjectDialogClick = () => {
        const message = (
            <BoldTranslation
                translationKey={'projectsCloseProjectText'}
                values={{
                    projectTitle: 'TODO',
                }}
            />
        );

        this.props.openConfirmDialog({
            confirmAction:     [],
            title:             I18n.t('projectsCloseProjectTitle'),
            message,
            confirmButtonText: I18n.t('closeProject'),
            cancelButtonText:  I18n.t('cancel'),
        });
    };

    onOpenRejectCompanyDialogClick = () => {
        const message = (
            <BoldTranslation
                translationKey={'projectsRejectCompanyText'}
                values={{
                    projectTitle: 'TODO',
                    companyName:  'Test',
                }}
            />
        );

        this.props.openConfirmDialog({
            confirmAction:     [],
            title:             I18n.t('projectsRejectCompanyTitle'),
            message,
            confirmButtonText: I18n.t('rejectCompany'),
            cancelButtonText:  I18n.t('cancel'),
        });
    };

    onOpenDeclineProjectDialogClick = () => {
        const message = (
            <BoldTranslation
                translationKey={'projectsDeclineProjectText'}
                values={{
                    projectTitle: 'TODO',
                }}
            />
        );

        this.props.openConfirmDialog({
            confirmAction:     [],
            title:             I18n.t('projectsDeclineProjectTitle'),
            message,
            confirmButtonText: I18n.t('notInterested'),
            cancelButtonText:  I18n.t('cancel'),
        });
    };

    onOpenAcceptProjectDialogClick = () => {
        const message = (
            <InterestedInProjectMessageContent />
        );

        this.props.openConfirmDialog({
            confirmAction:     [],
            title:             I18n.t('projectsAcceptProjectTitle'),
            message,
            overlayType:       OverlayType.widePrompt,
            confirmButtonText: I18n.t('send'),
            cancelButtonText:  I18n.t('cancel'),
        });
    };

    render() {
        return (
            <div
                style={{
                    background:     '#1b3d54',
                    display:        'flex',
                    justifyContent: 'center',
                    flexDirection:  'column',
                    alignItems:     'center',
                }}
            >
                <br />
                <br />
                <br />
                <Header>
                    <StartPageSearch
                        allowOverflowScrolling={false}
                        mode={StartPageSearchMode.resultList}
                        resultList={(
                            <>
                            </>
                        )}
                    />
                </Header>
                <BreadCrumbBar />
                <br />
                <BreadCrumbBar hide={true} />
                <br />
                <br />
                <br />
                <br />
                <a href={Routes.membership}>
                    Membership-Link
                </a>
                <a href={Routes.favorites}>
                    Favorites-Link
                </a>
                <br />
                <br />
                <br />
                <div className={styles.wrapper}>
                    <Link to={OverlayManager.getPathForOverlayKey(Overlays.testPrompt)}>
                        {'PromptOverlay'}
                    </Link>
                    <Link to={OverlayManager.getPathForOverlayKey(Overlays.testFull)}>
                        {'FullOverlay'}
                    </Link>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div
                    style={{
                        width: 500,
                    }}
                >
                    <ParameterInput
                        addButtonClicked={() => {
                            const parameters = [
                                ...this.state.parameters,
                                {
                                    id:   Math.random(),
                                    name: '',

                                    value: '',
                                },
                            ];

                            this.setState({
                                parameters,
                            });
                        }}
                        nameChanged={(index, value) => {
                            console.log('nameChanged', index, value);
                        }}
                        parameters={this.state.parameters}
                        removeButtonClicked={(parameter) => {
                            const parameters    = [
                                ...this.state.parameters,
                            ];
                            const indexToDelete = _.findIndex(
                                parameters,
                                {
                                    id: parameter.id,
                                },
                            );

                            parameters.splice(indexToDelete, 1);

                            this.setState({
                                parameters,
                            });
                        }}
                        valueChanged={(index, value) => {
                            console.log('valueChanged', index, value);
                        }}
                    />
                </div>
                <br />
                <br />
                <div
                    style={{
                        width: 300,
                    }}
                >
                    <DropDownInput
                        options={[
                            {
                                value: 'gds',
                                label: 'Service provider',
                            },
                            {
                                value: '2',
                                label: 'Test',
                            },
                            {
                                value: '3',
                                label: 'Test 2',
                            },
                            {
                                value: 'gds',
                                label: 'Service provider',
                            },
                            {
                                value: '2',
                                label: 'Test',
                            },
                            {
                                value: '3',
                                label: 'Test 2',
                            },
                            {
                                value: 'gds',
                                label: 'Service provider',
                            },
                            {
                                value: '2',
                                label: 'Test',
                            },
                            {
                                value: '3',
                                label: 'Test 2',
                            },
                            {
                                value: 'gds',
                                label: 'Service provider',
                            },
                            {
                                value: '2',
                                label: 'Test',
                            },
                            {
                                value: '3',
                                label: 'Test 2',
                            },
                        ]}
                    />
                    <br />
                    <br />
                    <br />
                    <br />
                    <CountryDropDownInput
                        options={[
                            {
                                label:                     'Deuschland',
                                iso31661Alpha2CountryCode: 'de',
                                value:                     'gds',
                            },
                            {
                                label:                     'Test',
                                iso31661Alpha2CountryCode: 'fr',
                                value:                     '2',
                            },

                        ]}
                    />
                </div>
                <br />
                <br />
                <br />
                <br />
                <StarButton />
                <StarButton glow={true} />
                <IconButton
                    iconType={IconType.star}
                    onClick={() => {
                        console.log('TODO');
                    }}
                    theme={IconButtonTheme.plain}
                />

                <br />
                <br />
                <br />

                <div className={styles.logoSmall}>
                    <Logo to={Routes.screenDesign} />
                </div>

                <br />
                <br />
                <br />

                <Logo />

                <br />
                <br />
                <br />

                <div className={styles.wrapper}>
                    <AddressLabel
                        city={'Weingarten'}
                        country={'Deutschland'}
                        iso31661Alpha2CountryCode={'de'}
                    />
                </div>
                <br />

                <div className={styles.wrapper}>
                    <AddressLabel
                        city={'Paris'}
                        country={'Frankreich'}
                        iso31661Alpha2CountryCode={'fr'}
                    />
                </div>
                <br />

                <div className={styles.wrapper}>
                    <AddressLabel
                        city={'Weingarten'}
                        country={'Deutschland'}
                        houseNumber={25}
                        postalCode={88677}
                        street={'Keltenstraße'}
                        iso31661Alpha2CountryCode={'de'}
                    />
                </div>
                <br />

                <div className={styles.wrapper}>
                    <AddressLabel
                        city={'Weingarten'}
                        country={'Deutschland'}
                        houseNumber={'25a'}
                        postalCode={88677}
                        street={'Keltenstraße'}
                        iso31661Alpha2CountryCode={'de'}
                    />
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />

                <div className={styles.wrapper}>
                    <EntityLabel
                        title={'Thomas Kekeisen'}
                        subtitle={'CEO @ Lulububu Software GmbH'}
                        avatar={lulububuLogo}
                        size={EntityLabelSize.small}
                        iso31661Alpha2CountryCode={'de'}
                    />
                </div>
                <br />
                <br />
                <br />

                <div className={styles.wrapper}>
                    <EntityLabel
                        title={'Thomas Kekeisen'}
                        subtitle={'CEO @ Lulububu Software GmbH'}
                        avatar={lulububuLogo}
                        size={EntityLabelSize.default}
                        verified={{
                            verified: true,
                        }}
                        iso31661Alpha2CountryCode={'fr'}
                    />
                </div>
                <br />
                <br />
                <br />

                <div className={styles.wrapper}>
                    <EntityLabel
                        title={'Thomas Kekeisen'}
                        subtitle={'CEO @ Lulububu Software GmbH'}
                        size={EntityLabelSize.small}
                        verified={{
                            verified: true,
                        }}
                    />
                </div>
                <br />
                <br />
                <br />

                <div className={styles.wrapper}>
                    <EntityLabel
                        title={'Thomas Kekeisen'}
                        subtitle={'CEO @ Lulububu Software GmbH'}
                        size={EntityLabelSize.default}
                    />
                </div>
                <br />
                <br />
                <br />
                <br />
                <CountryFlag
                    iso31661Alpha2CountryCode={'de'}
                    squared={true}
                    width={200}
                />
                <br />
                <CountryFlag
                    iso31661Alpha2CountryCode={'de'}
                    squared={false}
                    width={400}
                />
                <br />
                <br />
                <br />
                <br />
                <div className={styles.wrapper}>
                    <FormHeadline
                        text={'Parameters'}
                        onClick={() => {
                            console.log('TODO');
                        }}
                    />
                </div>
                <br />
                <div className={styles.wrapper}>
                    <FormHeadline
                        text={'Parameters'}
                    />
                </div>
                <br />
                <br />
                <br />
                <br />
                <SideMenu>
                    <SideMenuHeadline
                        text={'Sub menu'}
                    />
                    <Spacer height={20} />
                    <SideMenuButton
                        active={false}
                        title={'Lorem ipsum'}
                        subTitle={'Factsheet'}
                        onClick={() => {
                            console.log('TODO');
                        }}
                    />
                    <SideMenuButton
                        active={true}
                        title={'Service provider'}
                        subTitle={'Company overview'}
                        onClick={() => {
                            console.log('TODO');
                        }}
                    />
                    <SideMenuButton
                        active={false}
                        title={'Production portfolio'}
                        subTitle={'Test 123'}
                        onClick={() => {
                            console.log('TODO');
                        }}
                    />
                    <Spacer height={20} />
                    <SideMenuHeadline
                        text={'Sub menu 2'}
                    />
                    <Spacer height={20} />
                    <SideMenuButton
                        active={false}
                        title={'Production portfolio'}
                        subTitle={'Test 123'}
                        onClick={() => {
                            console.log('TODO');
                        }}
                    />
                    <SideMenuButton
                        active={false}
                        title={'Production portfolio'}
                        subTitle={'Test 123'}
                        onClick={() => {
                            console.log('TODO');
                        }}
                    />
                    <SideMenuFooterButton
                        active={false}
                        title={'Report profile'}
                        onClick={() => {
                            console.log('TODO');
                        }}
                    />
                    <SideMenuFooterButton
                        active={false}
                        title={'Report profile 2'}
                        onClick={() => {
                            console.log('TODO');
                        }}
                    />
                </SideMenu>
                <br />
                <br />
                <br />
                <br />

                <MachineParameterBox>
                    <MachineParameter
                        title={'Dolorem'}
                        value={'Due Ipsum'}
                    />
                    <MachineParameter
                        title={'Ipsum'}
                        value={'Dolores duo'}
                    />
                    <MachineParameter
                        title={'Duo'}
                        value={'Ipsum 200.000'}
                    />
                    <MachineParameter
                        title={'Color'}
                        value={'Diamond Hands'}
                    />
                </MachineParameterBox>
                <br />
                <br />
                <br />
                <br />

                <div
                    style={{
                        width: 848,
                    }}
                >
                    <MachineHeader
                        image={machineImage}
                        title={'Disamatik 200'}
                        manufacturer={'Disa'}
                        text={'The first fall auction of the season at Christie’s on Monday was solid, if low energy. '
                            + 'There were several empty seats, and some bids had to be coaxed out from the room.'}
                    >
                        <IconTextButton
                            key={'machine-header-button-envelope'}
                            to={Routes.screenDesign}
                            iconType={IconType.envelope}
                        />
                        <IconTextButton
                            key={'machine-header-button-owner'}
                            to={Routes.screenDesign}
                            iconType={IconType.owner}
                            text={I18n.t('details')}
                        />
                    </MachineHeader>
                </div>

                <br />
                <br />
                <br />
                <br />

                <div className={styles.wrapper}>
                    <CollapsableTagList>
                        <TagList
                            tags={tagList}
                        />
                    </CollapsableTagList>
                </div>
                <br />

                <div className={styles.wrapper}>
                    <CollapsableTagList closed={true}>
                        <TagList
                            tags={tagList}
                        />
                    </CollapsableTagList>
                </div>
                <br />

                <div className={styles.wrapper}>
                    <CollapsableTagList collapsable={false}>
                        <TagList
                            tags={tagList}
                        />
                    </CollapsableTagList>
                </div>
                <br />

                <div className={styles.wrapper}>
                    <CollapsableTagList title={I18n.t('interests')}>
                        <TagList
                            tags={tagList}
                        />
                    </CollapsableTagList>
                </div>
                <br />

                <div className={styles.wrapper}>
                    <CollapsableTagList
                        title={I18n.t('interests')}
                        closed={true}
                    >
                        <TagList
                            tags={tagList}
                        />
                    </CollapsableTagList>
                </div>
                <br />

                <div className={styles.wrapper}>
                    <CollapsableTagList
                        collapsable={false}
                        title={I18n.t('competences')}
                    >
                        <TagList
                            tags={tagList}
                        />
                    </CollapsableTagList>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <div
                    className={classNames(
                        styles.wrapper,
                        styles.spacing,
                    )}
                >
                    <IconTextButton
                        to={Routes.screenDesign}
                        iconType={IconType.envelope}
                        text={I18n.t('sendRequest')}
                    />
                    <IconTextButton
                        to={Routes.screenDesign}
                        iconType={IconType.filter}
                    />
                    <IconTextButton
                        to={Routes.screenDesign}
                        iconType={IconType.envelope}
                    />
                    <IconTextButton
                        to={Routes.screenDesign}
                        iconType={IconType.owner}
                        text={I18n.t('details')}
                    />
                    <IconTextButton
                        to={Routes.screenDesign}
                        iconType={IconType.check}
                        text={I18n.t('accept')}
                    />
                    <IconTextButton
                        to={Routes.screenDesign}
                        iconType={IconType.cross}
                        text={I18n.t('decline')}
                    />
                    <IconTextButton
                        to={Routes.screenDesign}
                        iconType={IconType.unfollow}
                    />
                    <IconTextButton
                        to={Routes.screenDesign}
                        iconType={IconType.plus}
                        text={I18n.t('newCollection')}
                    />
                    <IconTextButton
                        to={Routes.screenDesign}
                        iconType={IconType.pencil}
                        text={I18n.t('edit')}
                    />
                    <IconTextButton
                        to={Routes.screenDesign}
                        iconType={IconType.remove}
                        text={I18n.t('delete')}
                    />
                    <IconTextButton
                        to={Routes.screenDesign}
                        iconType={IconType.pencil}
                    />
                    <IconTextButton
                        to={Routes.screenDesign}
                        iconType={IconType.remove}
                    />
                    <IconTextButton
                        to={Routes.screenDesign}
                        iconType={IconType.envelope}
                        text={'SUpplyDU'}
                    />
                    <IconTextButton
                        to={Routes.screenDesign}
                        iconType={IconType.user}
                        text={I18n.t('claimOwnership')}
                    />
                    <IconTextButton
                        to={Routes.screenDesign}
                        iconType={IconType.pencil}
                        text={I18n.t('proposeChange')}
                    />
                    <IconTextButton
                        to={Routes.screenDesign}
                        iconType={IconType.plus}
                        text={I18n.t('addTag')}
                        theme={IconButtonTheme.grayRound}
                    />
                    {this.renderProjectRelatedTestButtons()}
                </div>
                <br />
                <br />
                <br />
                <br />
                <div
                    style={{
                        width: 760,
                    }}
                >
                    <StartPageSearch
                        allowOverflowScrolling={true}
                        mode={StartPageSearchMode.resultList}
                        resultList={(
                            <>
                                <TagSearchResult
                                    highlightText={highlightText}
                                    text={'Casting > casting suppliers > Production process > Automatic molding (Sand) > Core pack '}
                                />
                                <TagSearchResult
                                    highlightText={highlightText}
                                    text={'Casting > casting suppliers > Production process > Automatic molding (Sand) > Core pack '}
                                />
                                <TagSearchResult
                                    highlightText={highlightText}
                                    text={'Casting > casting suppliers > Production process > Automatic molding (Sand) > Test pack '}
                                />
                                <TagSearchResult
                                    highlightText={highlightText}
                                    text={'Casting > casting suppliers > Production process > Automatic molding (Sand) > Core pack '}
                                />
                                <TagSearchResult
                                    highlightText={highlightText}
                                    text={'Casting > casting suppliers > Production process > Automatic molding (Sand) > Core pack '}
                                />
                                <TagSearchResult
                                    highlightText={highlightText}
                                    text={'Casting > casting suppliers > Production process > Automatic molding (Sand) > Test pack '}
                                />
                                <CompanySearchResult
                                    highlightText={highlightText}
                                    companyImage={lulububuLogo}
                                    companyName={'Lulububu Software GmbH'}
                                    companyType={CompanyTypes.machineManufacturer}
                                />
                                <CompanySearchResult
                                    highlightText={highlightText}
                                    companyImage={lulububuLogo}
                                    companyName={'Core Core Core'}
                                    companyType={CompanyTypes.serviceProvider}
                                />
                                <CompanySearchResult
                                    highlightText={highlightText}
                                    companyImage={lulububuLogo}
                                    companyName={'Core Test Core'}
                                    companyType={CompanyTypes.supplier}
                                />
                                <CompanySearchResult
                                    highlightText={highlightText}
                                    companyImage={lulububuLogo}
                                    companyName={'Lulububu Software GmbH'}
                                    companyType={CompanyTypes.machineManufacturer}
                                />
                                <CompanySearchResult
                                    highlightText={highlightText}
                                    companyImage={lulububuLogo}
                                    companyName={'Core Core Core'}
                                    companyType={CompanyTypes.serviceProvider}
                                />
                                <CompanySearchResult
                                    highlightText={highlightText}
                                    companyImage={lulububuLogo}
                                    companyName={'Core Test Core'}
                                    companyType={CompanyTypes.supplier}
                                />
                            </>
                        )}
                    />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <StartPageSearch
                        allowOverflowScrolling={false}
                        mode={StartPageSearchMode.resultList}
                        resultList={(
                            <>
                            </>
                        )}
                    />
                    <br />
                    <br />
                    <br />
                    <div
                        style={{
                            backgroundColor: 'white',
                            padding:         30,
                            boxSizing:       'content-box',
                        }}
                    >
                        <DateInput
                            value={this.state.selectedDate}
                            onChange={(date) => {
                                this.setState({
                                    selectedDate: date,
                                });
                            }}
                        />
                        <br />
                        <br />
                        <Facts
                            headline={I18n.t('facts')}
                            facts={[
                                {
                                    label: 'Start',
                                    value: '14.11.2020',
                                },
                                {
                                    label: 'Ende',
                                    value: '14.12.2022',
                                },
                                {
                                    label: 'Volume',
                                    value: '100.000',
                                },
                                {
                                    label: 'Peak year volume',
                                    value: '70.000',
                                },
                            ]}
                        />
                        <br />
                        <br />

                        <ListWithHeader headline={'Matches'}>
                            <Match
                                status={ProjectStatus.OPEN}
                                name={'BORA hansgrohe'}
                                subtitle={I18n.t('companyTypeServiceProvider')}
                                image={lulububuLogo}
                            />
                            <Match
                                status={ProjectStatus.INTERESTED}
                                name={'BORA hansgrohe'}
                                subtitle={I18n.t('companyTypeServiceProvider')}
                                image={lulububuLogo}
                            />
                            <Match
                                status={ProjectStatus.REJECTED}
                                name={'BORA hansgrohe'}
                                subtitle={I18n.t('companyTypeServiceProvider')}
                                image={lulububuLogo}
                            />
                        </ListWithHeader>
                        <ListWithHeader headline={'Projects'}>
                            <ProjectListElement
                                projectName={'Paper holder'}
                                projectRuntime={'14.11.2022 - 20.12.2025'}
                                projectVolume={100000}
                                status={ProjectStatus.OPEN}
                                name={'BORA hansgrohe'}
                                subtitle={I18n.t('companyTypeServiceProvider')}
                                image={lulububuLogo}
                            />
                            <ProjectListElement
                                projectName={'Paper holder'}
                                projectRuntime={'14.11.2022 - 20.12.2025'}
                                projectVolume={100000}
                                status={ProjectStatus.INTERESTED}
                                name={'BORA hansgrohe'}
                                subtitle={I18n.t('companyTypeServiceProvider')}
                                image={lulububuLogo}
                            />
                            <ProjectListElement
                                projectName={'Paper holder'}
                                projectRuntime={'14.11.2022 - 20.12.2025'}
                                projectVolume={100000}
                                status={ProjectStatus.REJECTED}
                                name={'BORA hansgrohe'}
                                subtitle={I18n.t('companyTypeServiceProvider')}
                                image={lulububuLogo}
                            />
                        </ListWithHeader>
                        <ListWithHeader headline={'Own projects'}>
                            <OwnProjectListElement
                                projectName={'Paper holder'}
                                projectRuntime={'14.11.2022 - 20.12.2025'}
                                projectVolume={100000}
                                status={ProjectStatus.OPEN}
                                name={'BORA hansgrohe'}
                                subtitle={I18n.t('companyTypeServiceProvider')}
                                image={lulububuLogo}
                            />
                            <OwnProjectListElement
                                projectName={'Paper holder'}
                                projectRuntime={'14.11.2022 - 20.12.2025'}
                                projectVolume={100000}
                                status={ProjectStatus.INTERESTED}
                                name={'BORA hansgrohe'}
                                subtitle={I18n.t('companyTypeServiceProvider')}
                                image={lulububuLogo}
                            />
                            <OwnProjectListElement
                                projectName={'Paper holder'}
                                projectRuntime={'14.11.2022 - 20.12.2025'}
                                projectVolume={100000}
                                status={ProjectStatus.REJECTED}
                                name={'BORA hansgrohe'}
                                subtitle={I18n.t('companyTypeServiceProvider')}
                                image={lulububuLogo}
                            />
                        </ListWithHeader>
                    </div>
                </div>

                <br />
                <br />
                <br />
                <br />
                <br />
                <div
                    style={{
                        width: 200,
                    }}
                >
                    <RoundDropDown
                        options={[
                            {
                                value: 'chocolate',
                                label: 'Chocolate',
                            },
                            {
                                value: 'strawberry',
                                label: 'Strawberry',
                            },
                            {
                                value: 'vanilla',
                                label: 'Vanilla',
                            },
                        ]}
                    />
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <div
                    style={{
                        width: 848,
                    }}
                >
                    <ContentHeaderStateless
                        leftContent={
                            <EntityLabel
                                title={'Lulububu Software GmbH'}
                                subtitle={'Service Provider'}
                                avatar={lulububuLogo}
                                size={EntityLabelSize.default}
                                iso31661Alpha2CountryCode={'de'}
                            />
                        }
                        rightContent={
                            <StarButton glow={true} />
                        }
                        infoText={'last updated: 4 hours ago'}
                    />
                </div>
                <br />
                <div
                    style={{
                        width: 848,
                    }}
                >
                    <ContentHeaderStateless
                        leftContent={
                            <EntityLabel
                                title={'Lulububu Software GmbH'}
                                subtitle={'Service Provider'}
                                avatar={lulububuLogo}
                                size={EntityLabelSize.default}
                                iso31661Alpha2CountryCode={'de'}
                            />
                        }
                        rightContent={
                            <>
                                <IconButton
                                    to={Routes.screenDesign}
                                    iconType={IconType.share}
                                    theme={IconButtonTheme.grayBorder}
                                />
                                <StarButton glow={true} />
                            </>
                        }
                        infoText={'last updated: 4 hours ago'}
                    />
                </div>
                <br />
                <div
                    style={{
                        width: 848,
                    }}
                >
                    <ContentHeaderStateless
                        leftContent={
                            <EntityLabel
                                title={'Lulububu Software GmbH'}
                                subtitle={'Service Provider'}
                                avatar={lulububuLogo}
                                size={EntityLabelSize.default}
                                iso31661Alpha2CountryCode={'de'}
                            />
                        }
                        rightContent={
                            <>
                                <IconTextButton
                                    to={Routes.screenDesign}
                                    iconType={IconType.print}
                                    theme={IconButtonTheme.grayBorder}
                                    text={I18n.t('factSheet')}
                                    iconPosition={IconTextButtonPosition.left}
                                />
                                <IconButton
                                    to={Routes.screenDesign}
                                    iconType={IconType.share}
                                    theme={IconButtonTheme.grayBorder}
                                />
                                <StarButton glow={true} />
                            </>
                        }
                        infoText={'last updated: 4 hours ago'}
                    />
                </div>
                <br />
                <div
                    style={{
                        width: 848,
                    }}
                >
                    <ContentHeaderStateless
                        leftContent={
                            <ContentHeaderTitle
                                title={'Wood, Metal, Consulting'}
                                subtitle={'Last used 26 days ago'}
                                size={EntityLabelSize.default}
                            />
                        }
                        rightContent={
                            <>
                                <IconTextButton
                                    to={Routes.screenDesign}
                                    iconType={IconType.pencil}
                                    theme={IconButtonTheme.grayBorder}
                                    text={I18n.t('edit')}
                                    iconPosition={IconTextButtonPosition.left}
                                />
                                <IconTextButton
                                    to={Routes.screenDesign}
                                    iconType={IconType.remove}
                                    theme={IconButtonTheme.grayBorder}
                                    text={I18n.t('delete')}
                                    iconPosition={IconTextButtonPosition.left}
                                />
                            </>
                        }
                        infoText={'last updated: 50 days ago'}
                    />
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <div
                    style={{
                        width: 700,
                    }}
                >
                    <TabBar
                        tabs={[
                            {
                                title:      'All',
                                badgeCount: 12,
                            },
                            {
                                title:      'Ipsum',
                                badgeCount: 2,
                            },
                            {
                                title:      'Dolorem',
                                badgeCount: 12,
                            },
                            {
                                title:      'Dolorems',
                                badgeCount: 15,
                            },
                        ]}
                        contentRight={null} // TODO
                    />
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <div className={styles.buttonWrapper}>
                    <ColorButton
                        theme={ColorButtonTheme.white}
                        text={'Test'}
                    />
                    <ColorButton
                        theme={ColorButtonTheme.white}
                        hover={true}
                        text={'Hover'}
                    />
                    <ColorButton
                        theme={ColorButtonTheme.white}
                        disabled={true}
                        text={'Disabled'}
                    />
                </div>
                <div className={styles.buttonWrapper}>
                    <ColorButton
                        theme={ColorButtonTheme.orange}
                        text={'Test'}
                    />
                    <ColorButton
                        theme={ColorButtonTheme.orange}
                        hover={true}
                        text={'Hover'}
                    />
                    <ColorButton
                        theme={ColorButtonTheme.orange}
                        disabled={true}
                        text={'Disabled'}
                    />
                </div>
                <div className={styles.buttonWrapper}>
                    <ColorButton
                        theme={ColorButtonTheme.red}
                        text={'Test'}
                    />
                    <ColorButton
                        theme={ColorButtonTheme.red}
                        hover={true}
                        text={'Hover'}
                    />
                    <ColorButton
                        theme={ColorButtonTheme.red}
                        disabled={true}
                        text={'Disabled'}
                    />
                </div>
                <div
                    className={classNames(
                        styles.buttonWrapper,
                        styles.buttonWrapperBackground,
                    )}
                >
                    <ColorButton
                        theme={ColorButtonTheme.whiteGhost}
                        text={'Test'}
                    />
                    <ColorButton
                        theme={ColorButtonTheme.whiteGhost}
                        hover={true}
                        text={'Hover'}
                    />
                    <ColorButton
                        theme={ColorButtonTheme.whiteGhost}
                        disabled={true}
                        text={'Disabled'}
                    />
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <StartPageBox>
                    <HeadlineMedium text={I18n.t('login')} />
                    <Spacer height={20} />
                    <TextInput
                        onChange={() => {
                            console.log('input changed');
                        }}
                        placeholder={I18n.t('emailAddress')}
                        value={'joshua@lulububu.de'}
                    />
                    <TextInput
                        onChange={() => {
                            console.log('input changed');
                        }}
                        placeholder={I18n.t('password')}
                        type={'password'}
                    />
                    <div className={styles.loginButtonWrapper}>
                        <ColorButton
                            grow={true}
                            theme={ColorButtonTheme.orange}
                            text={I18n.t('login')}
                            onClick={() => {
                                console.log('login');
                            }}
                        />
                        <ColorButton
                            grow={true}
                            theme={ColorButtonTheme.white}
                            text={I18n.t('signUp')}
                            onClick={() => {
                                console.log('signUp');
                            }}
                        />
                    </div>
                    <div className={styles.lostPasswordTextWrapper}>
                        <span className={appStyles.textSmall}>
                            {I18n.t('lostPassword')}
                            &nbsp;
                            <Link
                                className={appStyles.link}
                                to={Routes.screenDesign}
                            >
                                {I18n.t('clickHere')}
                            </Link>
                        </span>
                    </div>
                </StartPageBox>
                <br />
                <br />
                <br />
                <br />
                <br />
                <StartPageBox>
                    <HeadlineMedium
                        text={I18n.t('passwordLost')}
                        align={Align.center}
                    />
                </StartPageBox>
                <br />
                <br />
                <br />
                <br />
                <br />
                <div
                    style={{
                        width: 1141,
                    }}
                >
                    <MachineBox
                        topLeftContent={
                            <MachineHeader
                                image={machineImage}
                                title={'Disamatik 200'}
                                manufacturer={'Disa'}
                                text={'The first fall auction of the season at Christie’s on Monday was solid, if low energy. '
                                    + 'There were several empty seats, and some bids had to be coaxed out from the room.'}
                            >
                                <IconTextButton
                                    key={'machine-header-button-envelope'}
                                    to={Routes.screenDesign}
                                    iconType={IconType.envelope}
                                />
                                <IconTextButton
                                    key={'machine-header-button-owner'}
                                    to={Routes.screenDesign}
                                    iconType={IconType.owner}
                                    text={I18n.t('details')}
                                />
                            </MachineHeader>
                        }
                        bottomLeftContent={
                            <MachineParameterBox>
                                <MachineParameter
                                    title={'Dolorem'}
                                    value={'Due Ipsum'}
                                />
                                <MachineParameter
                                    title={'Ipsum'}
                                    value={'Dolores duo'}
                                />
                                <MachineParameter
                                    title={'Duo'}
                                    value={'Ipsum 200.000'}
                                />
                                <MachineParameter
                                    title={'Color'}
                                    value={'Diamond Hands'}
                                />
                            </MachineParameterBox>
                        }
                        rightContent={
                            <CollapsableTagList>
                                <TagList tags={tagList} />
                            </CollapsableTagList>
                        }
                    />
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <div
                    style={{
                        width: 535,
                    }}
                >
                    <CompanyDescription
                        title={'Into Otherwise Solid Auction dolorem'}
                        text={'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. '
                            + 'At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. '
                            + 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. '
                            + 'At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.'}
                    />
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <div
                    style={{
                        width: 535,
                    }}
                >
                    <AddressBox
                        city={'Weingarten'}
                        companyName={'Lulububu Software GmbH'}
                        companyPhone={'+49 751 18 52 98 05'}
                        country={'Deutschland'}
                        geoLocation={{
                            latitude:  48.535100,
                            longitude: 8.086008,
                        }}
                        houseNumber={25}
                        postalCode={88677}
                        state={'Baden-Württemberg'}
                        street={'Keltenstraße'}
                    />
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <div
                    className={styles.wrapper}
                    style={{
                        width: 1140,
                    }}
                >
                    <HeadlineSmall text={'Inputs'} />
                    <br />
                    <ImageUpload
                        uploadFileCallback={() => {
                            console.log('uploaded callback');
                        }}
                        deleteFileCallback={() => {
                            console.log('deleted callback');
                        }}
                    />
                    <br />
                    <ImageUpload
                        multiple={true}
                        uploadFileCallback={() => {
                            console.log('uploaded callback');
                        }}
                        deleteFileCallback={() => {
                            console.log('deleted callback');
                        }}
                    />
                    <br />
                    <ImageUpload
                        images={uploadedImages}
                        uploadFileCallback={() => {
                            console.log('uploaded callback');
                        }}
                        deleteFileCallback={() => {
                            console.log('deleted callback');
                        }}
                    />
                    <br />
                    <ImageUpload
                        images={uploadedImages}
                        multiple={true}
                        uploadFileCallback={() => {
                            console.log('uploaded callback');
                        }}
                        deleteFileCallback={() => {
                            console.log('deleted callback');
                        }}
                    />
                    <br />
                    <br />
                    <br />
                    <FormRow label={'Text Input'}>
                        <TextInput />
                        <TextInput placeholder={'Placeholder'} />
                        <TextInput value={'Value'} />
                        <TextInput
                            placeholder={'Placeholder'}
                            value={'Value'}
                        />
                    </FormRow>
                    <br />
                    <FormRow label={'Multiline Text Input'}>
                        <TextInput size={TextInputSize.multiline} />
                        <TextInput
                            size={TextInputSize.multiline}
                            placeholder={'Placeholder'}
                        />
                        <TextInput
                            size={TextInputSize.multiline}
                            value={'Value'}
                        />
                        <TextInput
                            size={TextInputSize.multiline}
                            placeholder={'Placeholder'}
                            value={'Value'}
                        />
                        <TextInput
                            size={TextInputSize.multilineLarge}
                            placeholder={'Placeholder'}
                            value={'Value'}
                        />
                        <TextInput
                            size={TextInputSize.multilineExtraLarge}
                            placeholder={'Placeholder'}
                            value={'Value'}
                        />
                    </FormRow>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <div
                    style={{
                        width: 1140,
                    }}
                >
                    <Table filter={true}>
                        <TableHeader>
                            <TableHeaderRow>
                                <TableHeaderColumn>
                                    {'Machine'}
                                </TableHeaderColumn>
                                <TableHeaderColumn offset={55}>
                                    {'Company'}
                                </TableHeaderColumn>
                                <TableHeaderColumn offset={43}>
                                    {'Region'}
                                </TableHeaderColumn>
                                <TableHeaderColumn>
                                    <StarButton
                                        gray={true}
                                        noHover={true}
                                    />
                                </TableHeaderColumn>
                            </TableHeaderRow>
                        </TableHeader>
                        <TableBody>
                            <TableBodyRow>
                                <TableBodyColumn>
                                    {'CNC 1337 42B'}
                                </TableBodyColumn>
                                <TableBodyColumn>
                                    <EntityLabel
                                        title={'Lulububu Software GmbH'}
                                        subtitle={'Service provider'}
                                        avatar={lulububuLogo}
                                        size={EntityLabelSize.small}
                                    />
                                </TableBodyColumn>
                                <TableBodyColumn>
                                    <AddressLabel
                                        city={'Weingarten'}
                                        country={'Deutschland'}
                                        iso31661Alpha2CountryCode={'de'}
                                    />
                                </TableBodyColumn>
                                <TableBodyColumn>
                                    <StarButton gray={true} />
                                </TableBodyColumn>
                            </TableBodyRow>
                            <TableBodyRow>
                                <TableBodyColumn>
                                    {'CNC 1337 42B'}
                                </TableBodyColumn>
                                <TableBodyColumn>
                                    <EntityLabel
                                        title={'Lulububu Software GmbH'}
                                        subtitle={'Service provider'}
                                        avatar={lulububuLogo}
                                        size={EntityLabelSize.small}
                                    />
                                </TableBodyColumn>
                                <TableBodyColumn>
                                    <AddressLabel
                                        city={'Weingarten'}
                                        country={'Deutschland'}
                                        iso31661Alpha2CountryCode={'de'}
                                    />
                                </TableBodyColumn>
                                <TableBodyColumn>
                                    <StarButton gray={true} />
                                </TableBodyColumn>
                            </TableBodyRow>
                            <TableBodyRow>
                                <TableBodyColumn>
                                    {'CNC 1337 42B'}
                                </TableBodyColumn>
                                <TableBodyColumn>
                                    <EntityLabel
                                        title={'Lulububu Software GmbH'}
                                        subtitle={'Service provider'}
                                        avatar={lulububuLogo}
                                        size={EntityLabelSize.small}
                                    />
                                </TableBodyColumn>
                                <TableBodyColumn>
                                    <AddressLabel
                                        city={'Weingarten'}
                                        country={'Deutschland'}
                                        iso31661Alpha2CountryCode={'de'}
                                    />
                                </TableBodyColumn>
                                <TableBodyColumn>
                                    <StarButton gray={true} />
                                </TableBodyColumn>
                            </TableBodyRow>
                        </TableBody>
                    </Table>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <div
                    style={{
                        width: 1140,
                    }}
                >
                    <Table>
                        <TableHeader>
                            <TableHeaderRow>
                                <TableHeaderColumn
                                    text={'Machine'}
                                />
                                <TableHeaderColumn
                                    text={'Company'}
                                    offset={55}
                                />
                                <TableHeaderColumn
                                    text={'Region'}
                                    offset={43}
                                />
                                <TableHeaderColumn>
                                    <StarButton
                                        gray={true}
                                        noHover={true}
                                    />
                                </TableHeaderColumn>
                            </TableHeaderRow>
                        </TableHeader>
                        <TableBody>
                            <TableBodyRow>
                                <TableBodyColumn text={'CNC 1337 42B'} />
                                <TableBodyColumn>
                                    <EntityLabel
                                        title={'Lulububu Software GmbH'}
                                        subtitle={'Service provider'}
                                        avatar={lulububuLogo}
                                        size={EntityLabelSize.small}
                                    />
                                </TableBodyColumn>
                                <TableBodyColumn>
                                    <AddressLabel
                                        city={'Weingarten'}
                                        country={'Deutschland'}
                                        iso31661Alpha2CountryCode={'de'}
                                    />
                                </TableBodyColumn>
                                <TableBodyColumn>
                                    <StarButton gray={true} />
                                </TableBodyColumn>
                            </TableBodyRow>
                            <TableBodyRow>
                                <TableBodyColumn text={'CNC 1337 42B'} />
                                <TableBodyColumn>
                                    <EntityLabel
                                        title={'Lulububu Software GmbH'}
                                        subtitle={'Service provider'}
                                        avatar={lulububuLogo}
                                        size={EntityLabelSize.small}
                                    />
                                </TableBodyColumn>
                                <TableBodyColumn>
                                    <AddressLabel
                                        city={'Weingarten'}
                                        country={'Deutschland'}
                                        iso31661Alpha2CountryCode={'de'}
                                    />
                                </TableBodyColumn>
                                <TableBodyColumn>
                                    <StarButton gray={true} />
                                </TableBodyColumn>
                            </TableBodyRow>
                            <TableBodyRow>
                                <TableBodyColumn text={'CNC 1337 42B'} />
                                <TableBodyColumn>
                                    <EntityLabel
                                        title={'Lulububu Software GmbH'}
                                        subtitle={'Service provider'}
                                        avatar={lulububuLogo}
                                        size={EntityLabelSize.small}
                                    />
                                </TableBodyColumn>
                                <TableBodyColumn>
                                    <AddressLabel
                                        city={'Weingarten'}
                                        country={'Deutschland'}
                                        iso31661Alpha2CountryCode={'de'}
                                    />
                                </TableBodyColumn>
                                <TableBodyColumn>
                                    <StarButton gray={true} />
                                </TableBodyColumn>
                            </TableBodyRow>
                        </TableBody>
                    </Table>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <MembershipOptions options={membershipOptions} />
                <br />
                <br />
                <br />
                <br />
                <br />
                <Pagination
                    currentPage={4}
                    pagesCount={7}
                    route={'/product-list'}
                />
            </div>
        );
    }
}

Screen.propTypes = {
    openConfirmDialog: PropTypes.func,
};

Screen.defaultProps = {
    openConfirmDialog: _.noop,
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...OverlayActions,
    },
    dispatch,
);

export default compose(connect(
    null,
    mapDispatchToProps,
))(Screen);
