//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import _             from 'lodash';
import classNames    from 'classnames';
import DebounceInput from 'react-debounce-input';

import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import TextInputSize   from '@/components/stateless/atomic/TextInput/TextInputSize';
import IconType        from '@/components/stateless/atomic/Icon/IconType';
import Icon            from '@/components/stateless/atomic/Icon';

import styles from './styles.module.scss';

export class Component extends React.Component {
    onChange = (event) => {
        if (this.props.type === 'number') {
            this.props.onChange({
                ...event,
                target: {
                    ...event.target,
                    value: _.toNumber(event.target.value),
                },
            });
        } else {
            this.props.onChange(event);
        }
    };

    render() {
        const { size, type, placeholder, autoFocus, disabled } = this.props;
        const element                                          = size === TextInputSize.default ? 'input' : 'textarea';
        const className                                        = classNames(
            styles.textInput,
            {
                [styles.textInputMultiline]:           size === TextInputSize.multiline,
                [styles.textInputMultilineLarge]:      size === TextInputSize.multilineLarge,
                [styles.textInputMultilineExtraLarge]: size === TextInputSize.multilineExtraLarge,
            },
            this.props.className,
        );
        const value                                            = this.props.value ?? '';

        return (
            <div
                className={styles.inputContainer}
            >
                <DebounceInput
                    className={className}
                    type={type}
                    element={element}
                    debounceTimeout={300}
                    onChange={this.onChange}
                    placeholder={placeholder}
                    value={value}
                    autoFocus={autoFocus}
                    disabled={disabled}
                />
                {this.renderIcon()}
            </div>
        );
    }

    renderIcon = () => {
        const { icon } = this.props;

        if (icon) {
            return (
                <div className={styles.icon}>
                    <Icon iconType={icon} />
                </div>
            );
        }

        return null;
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const TextInput = Component;

Component.propTypes = {
    autoFocus:   PropTypes.bool,
    className:   PropTypes.string,
    disabled:    PropTypes.bool,
    icon:        PropTypes.oneOfObjectValues(IconType),
    onChange:    PropTypes.func,
    placeholder: PropTypes.string,
    size:        PropTypes.oneOfObjectValues(TextInputSize),
    type:        PropTypes.string,
    value:       PropTypes.string,
};

Component.defaultProps = {
    autoFocus:   false,
    className:   null,
    disabled:    false,
    icon:        false,
    onChange:    _.noop,
    placeholder: null,
    size:        TextInputSize.default,
    type:        'text',
    value:       '',
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
