//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React     from 'react';
import _         from 'lodash';
import PropTypes from '@/components/PropTypes';
import { Tab }   from '@/components/stateless/atomic/Tab';
import styles    from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <div className={styles.tabBarWrapper}>
                {this.props.tabs.map(this.renderTab)}
            </div>
        );
    }

    renderTab = (tab, index) => {
        return (
            <Tab
                tab={tab}
                key={`tab-${index}`}
                active={this.props.activeTabIndex === index}
                onClick={this.props.changeActiveTabIndex(index)}
            />
        );
    };
}

export const TabBar = Component;

Component.propTypes = {
    activeTabIndex:       PropTypes.number,
    changeActiveTabIndex: PropTypes.func,
    contentRight:         PropTypes.children,
    tabs:                 PropTypes.array,
};

Component.defaultProps = {
    activeTabIndex:       0,
    changeActiveTabIndex: _.noop,
    contentRight:         null,
    tabs:                 [],
};

export default Component;
