//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { Pagination }            from '@/components/stateless/atomic/Pagination';
import React                     from 'react';
import _                         from 'lodash';
import { bindActionCreators }    from 'redux';
import { compose }               from 'redux';
import { connect }               from 'react-redux';
import I18n                      from 'i18next';
import appStyles                 from '@/styles.module.scss';
import Route                     from '@/helper/Route';
import ComponentHelper           from '@/helper/ComponentHelper';
import Ids                       from '@/constants/Ids';
import Navigation                from '@/helper/Navigation';
import PropTypes                 from '@/components/PropTypes';
import Routes                    from '@/constants/Routes';
import State                     from '@/helper/State';
import StateHelper               from '@/helper/State';
import { CompanyActions }        from '@/store/actions/company';
import ContentHeader             from '@/components/connected/ContentHeader';
import { MachineActions }        from '@/store/actions/machine';
import { OverlayActions }        from '@/store/actions/ovleray';
import MessageRequest            from '@/components/connected/MessageRequest';
import FavoriseCompanyButton     from '@/components/connected/FavoriseCompanyButton';
import HeadlineSmall             from '@/components/stateless/atomic/HeadlineSmall';
import { MessageRequestActions } from '@/store/actions/messageRequest';
import MessageRequestPreview     from '@/components/stateless/composed/MessageRequestPreview';
import Spacer                    from '@/components/stateless/atomic/Spacer';
import MessageRequestState       from '@/constants/MessageRequestState';
import AlertBoxManager           from '@/components/connected/AlertBoxManager';
import { NavigationActions }     from '@/store/actions/navigation';
import ViewingContext            from '@/constants/ViewingContext';
import SideMenu                  from '@/components/connected/SideMenu';
import { ProfileActions }        from '@/store/actions/profile';
import { InformationBox }        from '@/components/stateless/composed/InformationBox';
import IconType                  from '@/components/stateless/atomic/Icon/IconType';

import MessageHelper from '@/helper/MessageHelper';
import styles        from './styles.module.scss';

class Screen extends React.Component {
    componentDidUpdate(prevProps) {
        if (prevProps.currentCompanyId !== this.props.currentCompanyId) {
            this.fetchData();
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        const { viewingContext } = this.props;

        if (
            viewingContext === ViewingContext.OWN_COMPANY ||
            viewingContext === ViewingContext.OWN_PROFILE
        ) {
            this.props.fetchMessageRequests();
        }

        if (
            viewingContext === ViewingContext.OTHER_COMPANY ||
            viewingContext === ViewingContext.OTHER_PROFILE
        ) {
            this.fetchCompany();
        }
    };

    fetchCompany = () => {
        const id = this.props.currentCompanyId;

        this.props.setCurrentCompany({
            id,
        });
    };

    filterUnreadMessages = () => {
        const { messageRequests } = this.props;

        const sortedMessageRequests = _.sortBy(messageRequests, (messageRequest) => {
            const messages    = _.get(messageRequest, 'messages', []);
            const lastMessage = _.last(messages);

            if (!lastMessage) {
                return messageRequest.createdAt;
            }

            return lastMessage.createdAt;
        }).reverse();

        const filteredMessageRequests = _.filter(
            sortedMessageRequests,
            (messageRequest) => {
                const { state } = messageRequest;

                if (state === MessageRequestState.DECLINED) {
                    return false;
                }

                const messages    = _.get(messageRequest, 'messages', []);
                const lastMessage = _.last(messages);

                if (
                    !MessageHelper.isOwnMessage(this.props, messageRequest) &&
                    state === MessageRequestState.PENDING
                ) {
                    return true;
                }

                if (
                    lastMessage &&
                    !_.get(lastMessage, 'read', false) &&
                    !MessageHelper.isOwnMessage(this.props, lastMessage)
                ) {
                    return true;
                }

                return false;
            },
        );

        return filteredMessageRequests;
    };

    onAcceptClicked = (messageRequest) => () => {
        const { iri } = messageRequest;

        this.props.setMessageRequestState({
            iri,
            state: MessageRequestState.ACCEPTED,
        });
    };

    onDeclineClicked = (messageRequest) => () => {
        const { iri } = messageRequest;

        this.props.setMessageRequestState({
            iri,
            state: MessageRequestState.DECLINED,
        });
    };

    onOpenClicked = (messageRequest) => () => {
        const { openUrl, company } = this.props;
        const url                  = (
            company ?
                Route.buildPathForCompanyMessageRequest(company, messageRequest) :
                Route.buildPathForProfileMessageRequestDetail(messageRequest)
        );

        openUrl({
            url,
        });
    };

    renderMessageRequestPreview = (messageRequest) => {
        return (
            <React.Fragment key={messageRequest.iri}>
                <Spacer height={25} />
                <MessageRequestPreview
                    incoming={!MessageHelper.isOwnMessage(this.props, messageRequest)}
                    messageRequest={messageRequest}
                    onAcceptClicked={this.onAcceptClicked}
                    onDeclineClicked={this.onDeclineClicked}
                    onOpenClicked={this.onOpenClicked(messageRequest)}
                />
            </React.Fragment>
        );
    };

    renderListSection = (unread, messageRequests) => {
        const titleKey = (
            unread ?
                'unread' :
                'messages'
        );

        if (messageRequests.length === 0) {
            return null;
        }

        return (
            <>
                <HeadlineSmall
                    text={I18n.t(titleKey)}
                />
                <Spacer height={15} />
                {_.map(messageRequests, this.renderMessageRequestPreview)}
                <Spacer height={15} />
            </>
        );
    };

    searchButtonClicked = () => {
        this.props.openUrl({
            url: Routes.home,
        });
    };

    loginButtonClicked = () => {
        this.props.openUrl({
            url: Routes.login,
        });
    };

    renderMessageListOrMessageRequest = () => {
        const { isLoggedIn } = this.props;

        if (!isLoggedIn) {
            return (
                <InformationBox
                    headline={I18n.t('notLoggedIn')}
                    subline={I18n.t('notLoggedInSubline')}
                    buttonText={I18n.t('login')}
                    buttonIcon={IconType.user}
                    onClick={this.loginButtonClicked}
                />
            );
        }

        if (
            this.props.viewingContext !== ViewingContext.OWN_COMPANY &&
            this.props.viewingContext !== ViewingContext.OWN_PROFILE
        ) {
            return (
                <MessageRequest />
            );
        }

        if (this.props.messageRequests.length === 0) {
            return (
                <InformationBox
                    headline={I18n.t('noMessages')}
                    subline={I18n.t('noMessagesSubline')}
                    buttonText={I18n.t('noMessagesButtonText')}
                    onClick={this.searchButtonClicked}
                />
            );
        }

        const { messageRequests }      = this.props;
        const unreadMessageRequests    = this.filterUnreadMessages();
        const remainingMessageRequests = _.differenceBy(
            messageRequests,
            unreadMessageRequests,
            'iri',
        );

        return (
            <>
                <AlertBoxManager />
                {this.renderListSection(true, unreadMessageRequests)}
                {this.renderListSection(false, remainingMessageRequests)}
            </>
        );
    };

    renderContentHeader = () => {
        const { props } = this;

        if (
            this.props.viewingContext === ViewingContext.OWN_COMPANY ||
            this.props.viewingContext === ViewingContext.OWN_PROFILE
        ) {
            return null;
        }

        return (
            <ContentHeader
                rightContent={this.renderStarButton()}
                currentCompanyUrl={props.currentCompanyUrl}
                currentCompanyId={props.currentCompanyId}
            />
        );
    };

    getSideMenuRoute() {
        switch (this.props.viewingContext) {
            case ViewingContext.OWN_PROFILE:
                return Routes.myProfileMessages;
            case ViewingContext.OWN_COMPANY:
                return Routes.companyMessage;
        }

        return null;
    }

    render() {
        const sideMenuRoute = this.getSideMenuRoute();
        const {
            messageCurrentPage,
            messagePages,
            pathname,
            history,
        }             = this.props;

        return (
            <div className={appStyles.defaultContainer}>
                <div
                    className={appStyles.defaultInnerContainer}
                    id={Ids.contentContainer}
                >
                    <SideMenu
                        route={sideMenuRoute}
                        {...this.props}
                    />
                    <div className={appStyles.defaultContentContainer}>
                        {this.renderContentHeader()}
                        <div className={styles.companyMessageContainer}>
                            <div className={styles.companyMessageContent}>
                                {this.renderMessageListOrMessageRequest()}
                                <Pagination
                                    currentPage={messageCurrentPage}
                                    pagesCount={messagePages}
                                    route={pathname}
                                    history={history}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderStarButton = () => {
        return (
            <FavoriseCompanyButton
                company={this.props.currentCompanyId}
            />
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Screen.propTypes = {
    company:                PropTypes.object,
    companyAddress:         PropTypes.object,
    companyIri:             PropTypes.string,
    companyName:            PropTypes.string,
    companyTypes:           PropTypes.array,
    competences:            PropTypes.array,
    currentCompanyId:       PropTypes.string,
    currentCompanyUrl:      PropTypes.string,
    fetchCompany:           PropTypes.func,
    fetchMessageRequests:   PropTypes.func,
    history:                PropTypes.object,
    isLoggedIn:             PropTypes.bool,
    isOnOwnCompany:         PropTypes.bool,
    messageCurrentPage:     PropTypes.number,
    messagePages:           PropTypes.number,
    messageRequests:        PropTypes.array,
    openConfirmDialog:      PropTypes.func,
    openEditOverlay:        PropTypes.func,
    openUrl:                PropTypes.func,
    ownIri:                 PropTypes.string,
    pathname:               PropTypes.string,
    requestingCompanyIri:   PropTypes.string,
    setCurrentCompany:      PropTypes.func,
    setMessageRequestState: PropTypes.func,
    userIri:                PropTypes.string,
    viewingContext:         PropTypes.oneOfObjectValues(ViewingContext),
};

Screen.defaultProps = {
    company:                null,
    companyAddress:         {},
    companyIri:             null,
    companyName:            null,
    companyTypes:           [],
    competences:            [],
    currentCompanyId:       null,
    currentCompanyUrl:      null,
    fetchCompany:           _.noop,
    fetchMessageRequests:   _.noop,
    history:                null,
    isLoggedIn:             false,
    isOnOwnCompany:         false,
    messageCurrentPage:     1,
    messagePages:           1,
    messageRequests:        [],
    openConfirmDialog:      _.noop,
    openEditOverlay:        _.noop,
    openUrl:                _.noop,
    ownIri:                 null,
    pathname:               null,
    requestingCompanyIri:   null,
    setCurrentCompany:      _.noop,
    setMessageRequestState: _.noop,
    userIri:                null,
    viewingContext:         ViewingContext.UNDEFINED,
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...CompanyActions,
        ...MachineActions,
        ...OverlayActions,
        ...MessageRequestActions,
        ...ProfileActions,
        ...NavigationActions,
    },
    dispatch,
);

const mapStateToProps = (state, ownProps) => {
    const companyUrl           = Navigation.getParameterFromUrl(ownProps, 'companyId');
    const companyId            = Navigation.getCompanyIdFromUrl(ownProps);
    const companyIri           = StateHelper.getCurrentCompanyIri(state);
    const requestingCompanyIri = StateHelper.getUserCompanyIri(state);
    const company              = StateHelper.getCompanyObject(state, companyId);
    const viewingContext       = Navigation.getViewingContext(ownProps, state);
    const userIri              = StateHelper.getCurrentUserIri(state);
    const ownIri               = StateHelper.getUserCompanyIri(state) || StateHelper.getUserIri(state);
    const pathname             = _.get(state, 'router.location.pathname');

    return {
        companyAddress:     State.getCompanyValue(state, companyId, 'address'),
        companyName:        State.getCompanyValue(state, companyId, 'name'),
        messageRequests:    MessageHelper.orderMessageRequests(_.get(state, 'messageRequest.messageRequests', [])),
        messagePages:       _.get(state, 'messageRequest.messagePages', 1),
        messageCurrentPage: _.get(state, 'messageRequest.messageCurrentPage', 1),
        companyTypes:       State.getCompanyValue(state, companyId, 'types', []),
        competences:        State.getCompanyValue(state, companyId, 'competencesHierarchy', []),
        currentCompanyId:   companyId,
        currentCompanyUrl:  companyUrl,
        ownIri,
        companyIri,
        requestingCompanyIri,
        company,
        viewingContext,
        userIri,
        isLoggedIn:         StateHelper.isLoggedIn(state),
        pathname,
    };
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Screen);
