//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import ReactTooltip    from 'react-tooltip';
import ComponentHelper from '@/helper/ComponentHelper';
import PropTypes       from '@/components/PropTypes';
import colors          from '@/styles/colors.module.scss';
import styles          from './styles.module.scss';

export class Component extends React.Component {
    render() {
        return (
            <>
                <span
                    className={styles.tag}
                    data-tip={this.renderTooltip()}
                    data-for={`Tag-${this.props.index}`}
                >
                    {this.props.tag}
                </span>
                <ReactTooltip
                    html={true}
                    border={true}
                    place={'bottom'}
                    effect={'solid'}
                    insecure={true}
                    id={`Tag-${this.props.index}`}
                    className={styles.tooltip}
                    textColor={colors.colorGrayDarker}
                    borderColor={colors.colorGray}
                    backgroundColor={colors.colorWhite}
                />
            </>
        );
    }

    renderTooltip = () => {
        const toolTip = this.props.tree;

        toolTip.push(`<strong>${this.props.tag}</strong>`);

        return toolTip.join(' > ');
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

export const Tag = Component;

Component.propTypes = {
    index: PropTypes.number,
    tag:   PropTypes.string,
    tree:  PropTypes.array,
};

Component.defaultProps = {
    index: -1,
    tag:   '',
    tree:  [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;
