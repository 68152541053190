//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import VerifiedProps                  from '@/constants/VerifiedProps';
import React                          from 'react';
import _                              from 'lodash';
import I18n                           from 'i18next';
import moment                         from 'moment';
import { bindActionCreators }         from 'redux';
import { compose }                    from 'redux';
import { connect }                    from 'react-redux';
import * as Api                       from '@/api';
import AlertBoxManager                from '@/components/connected/AlertBoxManager';
import appStyles                      from '@/styles.module.scss';
import ComponentHelper                from '@/helper/ComponentHelper';
import EntityLabelSize                from '@/components/stateless/composed/EntityLabel/EntityLabelSize';
import IconType                       from '@/components/stateless/atomic/Icon/IconType';
import Ids                            from '@/constants/Ids';
import Navigation                     from '@/helper/Navigation';
import PropTypes                      from '@/components/PropTypes';
import Route                          from '@/helper/Route';
import Routes                         from '@/constants/Routes';
import ShowIfUserIsAdminOfCompany     from '@/components/connected/ShowIfUserIsAdminOfCompany';
import State                          from '@/helper/State';
import String                         from '@/helper/String';
import TagList                        from '@/components/stateless/composed/TagList';
import { AddressBox }                 from '@/components/stateless/composed/AddressBox';
import { CollapsableTagList }         from '@/components/stateless/composed/CollapsableTagList';
import { CompanyActions }             from '@/store/actions/company';
import { CompanyDescription }         from '@/components/stateless/atomic/CompanyDescription';
import { ContentHeaderStateless }     from '@/components/stateless/composed/ContentHeaderStateless';
import { EntityLabel }                from '@/components/stateless/composed/EntityLabel';
import { MachineActions }             from '@/store/actions/machine';
import { IconTextButton }             from '@/components/stateless/atomic/IconTextButton';
import FavoriseCompanyButton          from '@/components/connected/FavoriseCompanyButton';
import JoinCompanyHeader              from '@/components/stateless/atomic/JoinNowHeader';
import Hydra                          from '@/helper/Hydra';
import { UserActions }                from '@/store/actions/user';
import CompanySideMenu                from '@/components/connected/CompanySideMenu';
import { ClaimCompanyHeader }         from '@/components/stateless/atomic/ClaimCompanyHeader';
import { SignupActions }              from '@/store/actions/signup';
import { CallToActionMessageBlock }   from '@/components/stateless/composed/CallToActionMessageBlock';
import Company                        from '@/helper/Company';
import { HeadlineSmall }              from '@/components/stateless/atomic/HeadlineSmall';
import ReactPlayer                    from 'react-player';
import UrlHelper                      from '@/helper/Url';
import CallToActionPressentationBlock from '@/components/stateless/composed/CallToActionPressentationBlock';
import { Spacer }                     from '@/components/stateless/atomic/Spacer';
import ShowIfCompanyIsMember          from '@/components/connected/ShowIfCompanyIsMember';
import ProfileCompletionBatch         from '@/components/connected/ProfileCompletionBatch';
import { TagCertificateList }         from '@/components/stateless/composed/TagCertificateList';
import styles                         from './styles.module.scss';

class Screen extends React.Component {
    constructor(props) {
        super(props);

        this.fetchCompany();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentCompanyId !== this.props.currentCompanyId) {
            this.fetchCompany();
        }
    }

    fetchCompany = () => {
        const id = this.props.currentCompanyId;

        this.props.setCurrentCompany({
            id,
        });
        this.props.fetchMachineByCompany({
            companyId: id,
        });
    };

    onEditCompanyClicked = () => {
        this.props.editOwnCompanyAndOpenOverlay();
    };

    onJoinClick = () => {
        this.props.history.push(Routes.signUp);
    };

    onClaimClick = () => {
        const { currentCompanyId, history, updateSignupField } = this.props;

        updateSignupField({
            field: 'company',
            value: currentCompanyId,
        });
        history.push(Routes.signUp);
    };

    renderJoinCompanyOrClaimableHeader = () => {
        const { isLoggedIn, claimable } = this.props;

        if (
            isLoggedIn
        ) {
            return null;
        }

        if (claimable) {
            return (
                <ClaimCompanyHeader
                    onClick={this.onClaimClick}
                />
            );
        }

        return (
            <JoinCompanyHeader
                onClick={this.onJoinClick}
            />
        );
    };

    render() {
        const {
            companyName,
            companyTypes,
            companyLogo,
            currentCompanyUrl,
            isOnOwnCompany,
        } = this.props;

        return (
            <div className={appStyles.defaultContainer}>
                <div
                    className={appStyles.defaultInnerContainer}
                    id={Ids.contentContainer}
                >
                    <CompanySideMenu
                        route={Routes.company}
                        {...this.props}
                    />
                    <div className={appStyles.defaultContentContainer}>
                        <AlertBoxManager />
                        {
                            isOnOwnCompany &&
                            <ProfileCompletionBatch />
                        }
                        {this.renderJoinCompanyOrClaimableHeader()}
                        <ContentHeaderStateless
                            leftContent={
                                <EntityLabel
                                    title={companyName}
                                    subtitle={Company.getCompanyTypesString(companyTypes)}
                                    verified={this.props.companyVerified}
                                    avatar={Api.getImagePath(companyLogo)}
                                    size={EntityLabelSize.default}
                                    iso31661Alpha2CountryCode={_.get(this.props, 'companyAddress.countryCode', null)}
                                    to={Route.buildRoute(Routes.company, currentCompanyUrl)}
                                />
                            }
                            rightContent={
                                this.renderEditOrFavoriteButton()
                            }
                            infoText={I18n.t(
                                'lastUpdate',
                                {
                                    updateString: moment(this.props.companyLastUpdate).fromNow(),
                                },
                            )}
                        />
                        <div className={styles.companyProfileContainer}>
                            <div className={styles.companyProfileContent}>
                                {this.renderDescription()}
                                {this.renderMessageCTABlock()}
                                {this.renderVideoAndPresentation()}
                                {this.renderAddressBox()}
                            </div>
                            {this.renderTags()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderAddressBox = () => {
        const country = _.get(this.props, 'companyAddress.country');

        if (country) {
            return (
                <AddressBox
                    city={_.get(this.props, 'companyAddress.city')}
                    companyName={this.props.companyName}
                    companyPhone={_.get(this.props, 'companyContact.phone')}
                    country={country}
                    geoLocation={_.get(this.props, 'companyAddress.geoLocation')}
                    houseNumber={_.get(this.props, 'companyAddress.houseNumber')}
                    postalCode={_.get(this.props, 'companyAddress.postalCode')}
                    state={_.get(this.props, 'companyAddress.state')}
                    street={_.get(this.props, 'companyAddress.street')}
                />
            );
        }

        return null;
    };

    renderDescription = () => {
        if (this.props.companyDescription) {
            return (
                <div className={styles.companyProfileDescription}>
                    <CompanyDescription
                        title={I18n.t(
                            'companyProfileDescriptionTitle',
                            {
                                companyName: this.props.companyName,
                            },
                        )}
                        text={String.cleanup(this.props.companyDescription)}
                    />
                </div>
            );
        }

        return null;
    };

    renderMessageCTABlock = () => {
        const { isLoggedIn, isOnOwnCompany } = this.props;

        if (
            isLoggedIn &&
            !isOnOwnCompany
        ) {
            const { companyName, currentCompanyId } = this.props;

            return (
                <div className={styles.companyProfileDescription}>
                    <CallToActionMessageBlock
                        company={companyName}
                        companyId={currentCompanyId}
                    />
                </div>
            );
        }

        return null;
    };

    renderVideo = (companyVideo) => {
        if (!companyVideo) {
            return null;
        }

        const videoTitle = _.get(companyVideo, 'title', I18n.t('video'));
        const videoPath  = _.get(companyVideo, 'path', null);

        return (
            <>
                <HeadlineSmall text={videoTitle} />
                <ReactPlayer
                    url={UrlHelper.getAbsoluteUrl(videoPath)}
                    controls={true}
                    width={'100%'}
                />
                <Spacer height={25} />
            </>
        );
    };

    renderVideoAndPresentation = () => {
        const { companyVideo, companyPresentation, currentCompanyId } = this.props;

        return (
            <ShowIfCompanyIsMember
                companyId={currentCompanyId}
            >
                {this.renderVideo(companyVideo)}
                <div className={styles.companyProfileDescription}>
                    <CallToActionPressentationBlock
                        presentation={companyPresentation}
                    />
                </div>
            </ShowIfCompanyIsMember>
        );
    };

    renderEditOrFavoriteButton = () => {
        const { currentCompanyId } = this.props;

        return (
            <ShowIfUserIsAdminOfCompany
                currentCompanyId={currentCompanyId}
                fallbackContent={
                    <FavoriseCompanyButton
                        glow={true}
                        company={currentCompanyId}
                    />
                }
            >
                <IconTextButton
                    onClick={this.onEditCompanyClicked}
                    key={'company-button-edit'}
                    iconType={IconType.pencil}
                    text={I18n.t('edit')}
                />
            </ShowIfUserIsAdminOfCompany>
        );
    };

    renderTags = () => {
        return (
            <div className={styles.companyProfileTagListContainer}>
                {this.renderCompetencesWithInterestTags()}
                {this.renderCertificatesDownloadTags()}
            </div>
        );
    };

    renderCompetencesWithInterestTags = () => {
        const competencesWithInterestTags = _.uniqWith(_.concat(
            this.props.companyCompetenceTags,
            this.props.companyInterestTags,
        ), _.isEqual);

        return (
            <div>
                <CollapsableTagList
                    closed={true}
                    title={I18n.t('competences')}
                >
                    <TagList tags={competencesWithInterestTags} />
                </CollapsableTagList>
            </div>
        );
    };

    renderCertificatesDownloadTags = () => {
        const { companyCertificatesTags, companyCertificateFiles } = this.props;

        return (
            <div>
                <HeadlineSmall text={I18n.t('certificates')} />
                <Spacer height={15} />
                <TagCertificateList
                    tags={companyCertificatesTags}
                    tagCertificates={companyCertificateFiles}
                />
            </div>
        );
    };

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Screen.propTypes = {
    claimable:                    PropTypes.bool,
    companyAddress:               PropTypes.object,
    companyCompetenceTags:        PropTypes.array,
    companyContact:               PropTypes.object,
    companyDescription:           PropTypes.string,
    companyHasMachines:           PropTypes.bool,
    companyInterestTags:          PropTypes.array,
    companyLastUpdate:            PropTypes.string,
    companyLogo:                  PropTypes.string,
    companyName:                  PropTypes.string,
    companyPresentation:          PropTypes.object,
    companySlug:                  PropTypes.string,
    companyTypes:                 PropTypes.array,
    companyVerified:              PropTypes.object,
    companyVideo:                 PropTypes.object,
    companyCertificatesTags:      PropTypes.array,
    companyCertificateFiles:      PropTypes.array,
    currentCompanyId:             PropTypes.string,
    currentCompanyUrl:            PropTypes.string,
    editOwnCompanyAndOpenOverlay: PropTypes.func,
    fetchCompany:                 PropTypes.func,
    fetchMachineByCompany:        PropTypes.func,
    history:                      PropTypes.object,
    isLoggedIn:                   PropTypes.bool,
    isOnOwnCompany:               PropTypes.bool,
    isOwnCompany:                 PropTypes.bool,
    joinCompany:                  PropTypes.func,
    joinRequests:                 PropTypes.array,
    sentJoinRequest:              PropTypes.bool,
    setCurrentCompany:            PropTypes.func,
    updateSignupField:            PropTypes.func,
};

Screen.defaultProps = {
    claimable:                    false,
    companyAddress:               {},
    companyCompetenceTags:        [],
    companyContact:               {},
    companyDescription:           null,
    companyHasMachines:           false,
    companyInterestTags:          [],
    companyLastUpdate:            null,
    companyLogo:                  null,
    companyName:                  null,
    companyPresentation:          null,
    companySlug:                  null,
    companyTypes:                 [],
    companyVerified:              VerifiedProps,
    companyVideo:                 null,
    companyCertificateFiles:      [],
    companyCertificatesTags:      [],
    currentCompanyId:             null,
    currentCompanyUrl:            null,
    editOwnCompanyAndOpenOverlay: _.noop,
    fetchCompany:                 _.noop,
    fetchMachineByCompany:        _.noop,
    history:                      {},
    isLoggedIn:                   false,
    isOnOwnCompany:               false,
    isOwnCompany:                 false,
    joinCompany:                  _.noop,
    joinRequests:                 [],
    sentJoinRequest:              false,
    setCurrentCompany:            _.noop,
    updateSignupField:            _.noop,
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...CompanyActions,
        ...MachineActions,
        ...UserActions,
        ...SignupActions,
    },
    dispatch,
);

const mapStateToProps = (state, ownProps) => {
    const companyUrl     = Navigation.getParameterFromUrl(ownProps, 'companyId');
    const companyId      = Navigation.getCompanyIdFromUrl(ownProps);
    const ownCompanyId   = _.get(state, 'user.company');
    const isOnOwnCompany = ownCompanyId === companyId;

    return {
        companyAddress:          State.getCompanyValue(state, companyId, 'address'),
        companyCompetenceTags:   State.getCompanyValue(state, companyId, 'competenceStrings', []),
        companyContact:          State.getCompanyValue(state, companyId, 'contactData'),
        companyDescription:      State.getCompanyValue(state, companyId, 'description'),
        companyHasMachines:      !!State.getMachinesByCompanyId(state, companyId).length,
        companyInterestTags:     State.getCompanyValue(state, companyId, 'interestStrings', []),
        companyLastUpdate:       State.getCompanyValue(state, companyId, 'updatedAt'),
        companyLogo:             State.getCompanyValue(state, companyId, 'logo.path', null),
        companyName:             State.getCompanyValue(state, companyId, 'name'),
        companyVideo:            State.getCompanyValue(state, companyId, 'video'),
        companyPresentation:     State.getCompanyValue(state, companyId, 'presentation'),
        companySlug:             State.getCompanyValue(state, companyId, 'slug'),
        companyTypes:            State.getCompanyValue(state, companyId, 'types', []),
        companyVerified:         State.getCompanyVerified(state, companyId),
        claimable:               State.getCompanyValue(state, companyId, 'claimable', false),
        companyCertificatesTags: State.getCompanyValue(state, companyId, 'factSheet.facts.certificates', []),
        companyCertificateFiles: State.getCompanyValue(state, companyId, 'factSheet.facts.certificateFiles', []),
        currentCompanyId:        companyId,
        currentCompanyUrl:       companyUrl,
        isLoggedIn:              !_.isEmpty(_.get(state, 'user.token', null)),
        isOnOwnCompany,
        sentJoinRequests:        _.includes(
            _.get(state, 'user.joinRequests', []),
            Hydra.getIriFromId('companies', companyId),
        ),
    };
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Screen);
