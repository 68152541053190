//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React      from 'react';
import _          from 'lodash';
import classNames from 'classnames';
import PropTypes  from '@/components/PropTypes';
import styles     from './styles.module.scss';

export class Component extends React.Component {
    render() {
        const { children, className, onClick } = this.props;

        return (
            <tr
                className={classNames(
                    styles.tableBodyRow,
                    {
                        [styles.tableBodyRowClickable]: onClick && onClick !== _.noop,
                    },
                    className,
                )}
                onClick={onClick}
            >
                {children}
            </tr>
        );
    }
}

export const TableBodyRow = Component;

Component.propTypes = {
    children:  PropTypes.children,
    className: PropTypes.string,
    onClick:   PropTypes.func,
};

Component.defaultProps = {
    children:  null,
    className: null,
    onClick:   _.noop,
};

export default Component;
