//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import AlertBoxManager              from '@/components/connected/AlertBoxManager';
import { ColorButton }              from '@/components/stateless/atomic/ColorButton';
import ColorButtonTheme             from '@/components/stateless/atomic/ColorButton/ColorButtonTheme';
import { CountryFlag }              from '@/components/stateless/atomic/CountryFlag';
import DateInput                    from '@/components/stateless/atomic/DateInput';
import { DropDownInput }            from '@/components/stateless/atomic/DropDownInput';
import { HeadlineMedium }           from '@/components/stateless/atomic/HeadlineMedium';
import { TextInput }                from '@/components/stateless/atomic/TextInput';
import { FormRow }                  from '@/components/stateless/composed/FormRow';
import CountryListHelper            from '@/helper/CountryListHelper';
import PropTypes                    from '@/components/PropTypes';
import MembershipListHelper         from '@/helper/MembershipListHelper';
import { AlertBoxActions }          from '@/store/actions/alertBox';
import { MembershipRequestActions } from '@/store/actions/membershipRequest';
import className                    from 'classnames';
import I18n                         from 'i18next';
import _                            from 'lodash';
import React                        from 'react';
import { bindActionCreators }       from 'redux';
import { compose }                  from 'redux';
import { connect }                  from 'react-redux';

import ComponentHelper from '@/helper/ComponentHelper';

import styles from './styles.module.scss';

const optionalFields = [
    'promotionCode',
    'preferredOnsiteVerificationDate',
];

class Screen extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState(props);
    }

    getInitialState = (props) => ({
        companyName:                     props.companyName,
        companyIri:                      props.companyIri,
        address:                         props.companyAddressString,
        country:                         CountryListHelper.getCurrentCompanyCountryOption(props.companyAddress),
        city:                            props.companyAddress?.city,
        state:                           props.companyAddress?.state,
        region:                          null,
        taxId:                           null,
        membership:                      MembershipListHelper.getCurrentMembershipCountryOption(props.membershipOptions, props.option),
        preferredOnsiteVerificationDate: null,
        promotionCode:                   null,
    });

    onMembershipSendClicked = () => {
        const isMissingData = Object.entries(this.state).some(([key, state]) => !state && !optionalFields.includes(key));

        if (!isMissingData) {
            this.props.requestMembership(this.state);
            this.setState(this.getInitialState(this.props));
        } else {
            this.props.showErrorAlert({
                text: I18n.t('missingMembershipData'),
            });
        }
    };

    clearAlerts = () => {
        this.props.clearAlerts();
    };

    onFieldChange = (field) => (event) => {
        this.setState({
            [field]: event.target.value,
        });
        this.clearAlerts();
    };

    onDateChange = (date) => {
        this.setState({
            preferredOnsiteVerificationDate: new Date(date),
        });
        this.clearAlerts();
    };

    onCountryChange = (country) => {
        this.setState({
            country,
        });
        this.clearAlerts();
    };

    onMembershipChange = (membership) => {
        this.setState({
            membership,
        });
        this.clearAlerts();
    };

    renderCompanyCountryOption = (name, option) => {
        return (
            <div className={styles.companyLanguage}>
                <CountryFlag
                    width={16}
                    iso31661Alpha2CountryCode={option.value}
                />
                {name}
            </div>
        );
    };

    renderPreferredOnSiteVerificationDate = () => {
        const selectedMembership    = _.find(this.props.membershipOptions, {
            iri: this.state.membership.value,
        });
        const hasOnSiteVerification = selectedMembership?.withOnSiteVerification;

        if (hasOnSiteVerification) {
            return (
                <FormRow label={I18n.t('preferredOnsiteVerificationDate')}>
                    <DateInput
                        value={this.state.preferredOnsiteVerificationDate}
                        onChange={this.onDateChange}
                    />
                </FormRow>
            );
        }

        return null;
    };

    render() {
        const { membershipOptions } = this.props;

        return (
            <div className={styles.membershipContainer}>
                <div className={styles.form}>
                    <div className={styles.inputs}>
                        <HeadlineMedium text={I18n.t('membershipOptions')} />
                        <div className={styles.row}>
                            <FormRow label={I18n.t('companyName')}>
                                <TextInput
                                    value={this.state.companyName}
                                    onChange={this.onFieldChange('companyName')}
                                    disabled={true}
                                />
                            </FormRow>
                            <FormRow label={I18n.t('address')}>
                                <TextInput
                                    value={this.state.address}
                                    onChange={this.onFieldChange('address')}
                                />
                            </FormRow>
                        </div>
                        <div className={styles.row}>
                            <FormRow label={I18n.t('country')}>
                                <DropDownInput
                                    options={CountryListHelper.getOptions()}
                                    defaultValue={this.state.country}
                                    renderValue={this.renderCompanyCountryOption}
                                    onChange={this.onCountryChange}
                                />
                            </FormRow>
                            <FormRow label={I18n.t('city')}>
                                <TextInput
                                    value={this.state.city}
                                    onChange={this.onFieldChange('city')}
                                />
                            </FormRow>
                        </div>
                        <div className={styles.row}>
                            <FormRow label={I18n.t('state')}>
                                <TextInput
                                    value={this.state.state}
                                    onChange={this.onFieldChange('state')}
                                />
                            </FormRow>
                            <FormRow label={I18n.t('region')}>
                                <TextInput
                                    value={this.state.region}
                                    onChange={this.onFieldChange('region')}
                                />
                            </FormRow>
                        </div>
                        <div className={className(styles.row, styles.rowHalf)}>
                            <FormRow label={I18n.t('taxId')}>
                                <TextInput
                                    value={this.state.taxId}
                                    onChange={this.onFieldChange('taxId')}
                                />
                            </FormRow>
                        </div>
                        <div className={styles.row}>
                            <FormRow label={I18n.t('membershipOptions')}>
                                <DropDownInput
                                    options={MembershipListHelper.getOptions(membershipOptions)}
                                    defaultValue={this.state.membership}
                                    onChange={this.onMembershipChange}
                                />
                            </FormRow>
                            {this.renderPreferredOnSiteVerificationDate()}
                        </div>
                        <div className={className(styles.row, styles.rowHalf)}>
                            <FormRow label={I18n.t('promotionCode')}>
                                <TextInput
                                    value={this.state.promotionCode}
                                    onChange={this.onFieldChange('promotionCode')}
                                />
                            </FormRow>
                        </div>
                    </div>
                    <AlertBoxManager />
                    <hr />
                    <ColorButton
                        theme={ColorButtonTheme.orange}
                        onClick={this.onMembershipSendClicked}
                        text={I18n.t('send')}
                    />
                </div>
            </div>
        );
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            nextProps,
            nextState,
        );
    }
}

Screen.propTypes = {
    clearAlerts:          PropTypes.func,
    companyAddress:       PropTypes.object,
    companyAddressString: PropTypes.object,
    companyIri:           PropTypes.string,
    companyName:          PropTypes.string,
    membershipOptions:    PropTypes.array,
    option:               PropTypes.string,
    requestMembership:    PropTypes.func,
    showErrorAlert:       PropTypes.func,
};

Screen.defaultProps = {
    clearAlerts:          _.noop,
    companyAddress:       null,
    companyAddressString: null,
    companyIri:           null,
    companyName:          null,
    membershipOptions:    [],
    option:               null,
    requestMembership:    _.noop,
    showErrorAlert:       _.noop,
};

Screen.renderAffectingProps = Object.keys(Screen.defaultProps);

Screen.renderAffectingStates = [
    'companyName',
    'address',
    'country',
    'city',
    'state',
    'region',
    'taxId',
    'membership',
    'preferredOnsiteVerificationDate',
    'promotionCode',
];

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...MembershipRequestActions,
        ...AlertBoxActions,
    },
    dispatch,
);

const mapStateToProps = (state) => {
    const companyId            = _.get(state, 'user.company');
    const company              = _.get(state, ['company', 'companies', companyId]);
    const companyIri           = _.get(company, 'iri');
    const companyName          = _.get(company, 'name');
    const companyAddress       = _.get(company, 'address');
    const companyAddressString = `${companyAddress?.street ?? ''} ${companyAddress?.houseNumber ?? ''}`;
    const membershipOptions    = _.get(state, 'membership.options', []);
    const option               = state.router?.location?.query?.option;

    return {
        companyIri,
        companyName,
        companyAddress,
        companyAddressString,
        membershipOptions,
        option,
    };
};

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(Screen);
